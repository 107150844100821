<template>
  <div class="content-list-view">
    <template v-for="item in contentList">
      <div
        v-show="item.visible"
        :key="item.id"
        :id="'content-list-' + item.id"
        :class="item.id == 2 ? 'drag-content-list drag-content-list-local' : 'drag-content-list'"
        :style="
          'order:' +
            item.order +
            ';z-index:' +
            item.zIndex +
            ';top:' +
            (item.mouseY - 20) +
            'px' +
            (item.mouseX ? `;left:${item.mouseX - 20}px` : ';right:130px') +
            `${item.isOpacity ? ';opacity:0.5' : ''}` +
            `${item.isSort ? ';border:1px dashed ' + color : ''}`
        "
      >
        <div
          @mousedown="
            e => {
              mousedown(e, item)
            }
          "
          class="drag-content-title"
        >
          <span class="drag-content-title-span">{{ item.title }}{{ item.id == 1 ? `（${diffsTotal}）` : '' }}</span>
          <div class="pushpin-icon" @mousemove="noDrag = true" @mouseout="noDrag = false">
            <template v-if="item.id == 3">
              <a-icon
                @click="showTextAreaChange"
                v-if="showTextArea == 'ON'"
                type="eye"
                :style="{ fontSize: '16px' }"
              />
              <a-icon @click="showTextAreaChange" v-else type="eye-invisible" :style="{ fontSize: '16px' }" />
            </template>
            <template v-if="item.id == 1">
              <a-icon @click="showDiffChange" v-if="showDiffs == 'ON'" type="eye" :style="{ fontSize: '16px' }" />
              <a-icon @click="showDiffChange" v-else type="eye-invisible" :style="{ fontSize: '16px' }" />
            </template>
            <a-icon
              @click="item.showContent = false"
              v-if="item.showContent"
              type="vertical-align-top"
              style="margin-left:6px;font-size:16px"
            />
            <a-icon
              @click="item.showContent = true"
              v-else-if="!item.showContent"
              type="vertical-align-bottom"
              style="margin-left:6px;font-size:16px"
            />
            <pushpin
              style="margin-left:6px;cursor: pointer;"
              @mouseover="item.pushpinColor = color"
              @mouseout="item.pushpinColor = '#4a4a4a'"
              theme="outline"
              size="16"
              @click="item.isPushpin = !item.isPushpin"
              :fill="item.isPushpin ? color : item.pushpinColor"
              :strokeWidth="3"
            />
            <a-icon
              v-if="!item.allHeight"
              @click="changeAllheight(item)"
              style="margin-left:6px;"
              type="column-height"
            />
            <a-icon v-else @click="changeAllheight(item)" style="margin-left:6px;" type="vertical-align-middle" />
            <a-icon style="margin-left:6px;" @click.stop="e => closeContentList(e, item)" type="close" />
          </div>
        </div>
        <div
          v-if="item.showContent"
          class="drag-content-show"
          :style="item.allHeight ? { height: bottomHeight - 80 + 'px' } : ''"
          @click="topItem(item)"
        >
          <div v-if="item.id == 2" class="local-show">
            <div class="local-radio">
              <div class="local-radio-top" ref="local-radio-top" @mousewheel="handleMouseWheel">
                <div>
                  <div>
                    <div
                      :class="option.localChecked ? 'local-radio-item local-radio-item-selected' : 'local-radio-item'"
                      v-for="(option, optionIndex) in allOption.filter(
                        option => option.title != '原稿' && option.title != '原稿JPG'
                      )"
                      :key="option.title"
                      @click="changeSelectOption(option)"
                    >
                      <div
                        :style="{
                          borderRight: optionIndex == allOption.length - 1 ? '' : '1px solid rgba(0,0,0,0.1)'
                        }"
                      >
                        {{ option.title }}{{ getLoacalTotal(option.title) ? `(${getLoacalTotal(option.title)})` : '' }}
                      </div>
                    </div>
                  </div>
                </div>
                <a-popover
                  v-if="isTranslate && changeAll"
                  v-model="translateVisible"
                  placement="bottomLeft"
                  :title="false"
                  trigger="click"
                  transitionName=""
                  :overlayStyle="{ zIndex: 9999 }"
                >
                  <div slot="content">
                    <a-select
                      showSearch
                      v-if="isTranslate && changeAll"
                      allowClear
                      showArrow
                      placeholder="请选择源语种"
                      v-model="sourceLang"
                      :dropdownStyle="{ zIndex: 9999 }"
                      style="width:150px;margin-right:5px"
                      option-filter-prop="label"
                    >
                      <a-select-option value="自动检测">
                        自动检测
                      </a-select-option>
                      <a-select-option value="中文">
                        中文
                      </a-select-option>
                      <a-select-option value="韩语">
                        한국어
                      </a-select-option>
                      <a-select-option value="日语">
                        日本語
                      </a-select-option>
                      <a-select-option value="英语">
                        ENGLISH
                      </a-select-option>
                    </a-select>
                    <a-icon type="arrow-right" />
                    <a-select
                      showSearch
                      allowClear
                      v-if="isTranslate && changeAll"
                      showArrow
                      placeholder="请选择"
                      :dropdownStyle="{ zIndex: 9999 }"
                      style="width:150px;margin-left:5px"
                      v-model="targetLang"
                      option-filter-prop="label"
                    >
                      <a-select-option value="中文">
                        中文
                      </a-select-option>
                      <a-select-option value="韩语">
                        한국어
                      </a-select-option>
                      <a-select-option value="日语">
                        日本語
                      </a-select-option>
                      <a-select-option value="英语">
                        ENGLISH
                      </a-select-option>
                    </a-select>
                    <a-button
                      type="primary"
                      :loading="translateLoading"
                      @click="translateAll"
                      style="margin-left:15px;"
                    >
                      翻译
                    </a-button>
                  </div>
                  <span
                    class="translate-icon"
                    :style="'right:25px'"
                    @mouseover="translateColor = color"
                    @mouseout="translateColor = '#4a4a4a'"
                  >
                    <translate theme="outline" size="21" :fill="translateColor" :strokeWidth="3" />
                  </span>
                </a-popover>
                <a-icon
                  class="translate-change-icon"
                  :style="'right:0'"
                  @click="changeTranslate"
                  v-if="checkPermission('fatchStraw:localList:change')"
                  type="swap"
                />
              </div>
              <div
                class="local-radio-bottom"
                v-if="selectedTitle != '原稿' && selectedTitle != '原稿JPG' && selectedTitle != '实时' && !isTranslate"
              >
                <!-- 陈立说的 筛选数据根据阶段绑定职位来 -->
                <div
                  v-if="contentToStages.length && taskDetail.departName.indexOf('原创部') == -1"
                  style="padding-bottom:12px;"
                >
                  <a-checkbox-group
                    v-model="checkedStages"
                    :options="
                      contentToStages.map(stage => {
                        return { label: stage.stageName || '', value: stage.stageName || '' }
                      })
                    "
                    @change="changeStages"
                  />
                </div>
                <div
                  v-if="checkPermission('fatchStraw:local:view') && taskDetail.departName.indexOf('原创部') == -1"
                  style="padding-bottom:12px;"
                >
                  <a-checkbox v-model="hasLocal" class="liuyan">
                    留言
                  </a-checkbox>
                </div>
                <a-radio-group v-model="radioValue" @change="radioChange">
                  <a-radio :value="0">
                    全部
                  </a-radio>
                  <a-radio :value="1" style="margin-left:5px">
                    已解决
                  </a-radio>
                  <a-radio :value="2" style="margin-left:5px">
                    未解决
                  </a-radio>
                </a-radio-group>
              </div>
              <div
                class="local-radio-bottom"
                v-else-if="
                  selectedTitle != '原稿' && selectedTitle != '原稿JPG' && selectedTitle != '实时' && isTranslate
                "
              >
                <div
                  v-if="contentToStages.length && taskDetail.departName.indexOf('原创部') == -1"
                  style="padding-bottom:12px;"
                >
                  <a-checkbox-group
                    v-model="checkedStages"
                    :options="
                      contentToStages.map(stage => {
                        return { label: stage.stageName, value: stage.stageName }
                      })
                    "
                    @change="changeStages"
                  />
                </div>
                <a-radio-group v-model="radioValue" @change="radioChange">
                  <a-radio :value="0">
                    全部
                  </a-radio>
                  <a-radio :value="1" style="margin-left:5px">
                    已校对
                  </a-radio>
                  <a-radio :value="2" style="margin-left:5px">
                    未校对
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
            <div
              v-if="!isTranslate"
              class="local-content"
              :style="
                item.allHeight
                  ? {
                      maxHeight: bottomHeight - 270 + 'px !important',
                      position: 'relative',
                      left: '-16px',
                      width: 'calc(100% + 14px)'
                    }
                  : 'max-height: 400px;position:relative;left:-16px;width:calc(100% + 14px);'
              "
            >
              <div v-if="selectedTitle != '原稿' && selectedTitle != '原稿JPG' && selectedTitle != '实时'">
                <div class="open-all">
                  <a
                    @click.stop="openAllLocal"
                    v-if="
                      reDefaultKey.length <
                        showOptions
                          .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                          .list.find(option => option.title == selectedTitle)
                          .manuscriptList.filter(
                            page =>
                              page.annotations &&
                              page.annotations.filter(
                                item =>
                                  !radioValue ||
                                  (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                  (radioValue == 1 && item.updateStatus == 1)
                              ).length
                          ).length
                    "
                    >全展开/收起</a
                  >
                  <a @click.stop="closeAllLocal" v-else>全展开/收起</a>
                </div>
                <a-collapse
                  v-if="
                    showOptions
                      .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                      .list.find(option => option.title == selectedTitle).manuscriptList &&
                      showOptions
                        .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                        .list.find(option => option.title == selectedTitle).manuscriptList.length
                  "
                  @change="collapseChange"
                  v-model="reDefaultKey"
                  :bordered="false"
                >
                  <template #expandIcon="props">
                    <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                  </template>
                  <a-collapse-panel
                    v-for="(page, pageIdx) in showOptions
                      .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                      .list.find(option => option.title == selectedTitle)
                      .manuscriptList.filter(
                        page =>
                          page.annotations &&
                          page.annotations.filter(
                            item =>
                              ((!radioValue ||
                                (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                (radioValue == 1 && item.updateStatus == 1)) &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                item.annotationKind != 1) ||
                              (item.annotationKind == 1 &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                hasLocal &&
                                (!radioValue ||
                                  (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                  (radioValue == 1 && item.updateStatus == 1)))
                          ).length
                      )"
                    :key="page.id + ''"
                    :style="customStyle"
                  >
                    <div slot="header" style="display:flex">
                      <a-tooltip transitionName="">
                        <template slot="title">
                          {{ page.fileName }}
                        </template>
                        <span
                          style="max-width:190px;display:inline-block;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;"
                          >{{ page.fileName }}</span
                        >
                      </a-tooltip>
                      <span style="margin-left:20px">{{
                        `共${
                          page.annotations.filter(
                            item =>
                              ((!radioValue ||
                                (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                (radioValue == 1 && item.updateStatus == 1)) &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                item.annotationKind != 1) ||
                              (item.annotationKind == 1 &&
                                hasLocal &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                (!radioValue ||
                                  (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                  (radioValue == 1 && item.updateStatus == 1)))
                          ).length
                        }处`
                      }}</span>
                    </div>
                    <!-- 陈立说的留言不筛选职位 -->
                    <div
                      :class="
                        selectLocalId == item.id
                          ? 'local-content-list-show local-content-list-show-selected'
                          : 'local-content-list-show'
                      "
                      @click="toLocal(page, item, pageIdx)"
                      v-for="(item, itemIndex) in page.annotations.filter(
                        item =>
                          ((!radioValue ||
                            (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                            (radioValue == 1 && item.updateStatus == 1)) &&
                            (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                            item.annotationKind != 1) ||
                          (item.annotationKind == 1 &&
                            (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                            hasLocal &&
                            (!radioValue ||
                              (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                              (radioValue == 1 && item.updateStatus == 1)))
                      )"
                      :ref="item.id + 'local'"
                      :key="item.id"
                    >
                      <div class="local-content-list-num">
                        <div>{{ itemIndex + 1 }}</div>
                      </div>
                      <div class="local-content-list">
                        <div
                          v-if="taskDetail.departName.indexOf('原创部') == -1"
                          style="display:flex;align-items:center;padding-top:5px;margin-bottom:5px"
                        >
                          <span style="font-size:14px;">
                            To:
                          </span>
                          <div style="margin-left:12px;" v-if="!item.edit">
                            {{ item.targetStage }}
                          </div>
                          <div style="margin-left:12px;" v-else>
                            <template v-if="item.annotationKind == 2 || item.annotationKind == 3">
                              <a-radio-group
                                v-model="item.targetStage"
                                v-if="toStagesAnnotation.length"
                                @change="
                                  toStageChange(
                                    item,
                                    page,
                                    pageIdx,
                                    showOptions
                                      .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                                      .list.find(option => option.title == selectedTitle),
                                    item.annotationKind
                                  )
                                "
                              >
                                <a-radio
                                  :value="stage.stageName"
                                  :disabled="!changeAll || isAutoSave"
                                  v-for="stage in toStagesAnnotation.filter(s => {
                                    return changeAll || s.stageName == targetStage
                                  })"
                                  :key="stage.stageName"
                                >
                                  {{ stage.stageName }}
                                </a-radio>
                              </a-radio-group>
                              <span v-else>{{ item.targetStage }}</span>
                            </template>
                            <a-radio-group
                              v-model="item.targetStage"
                              v-else
                              @change="
                                toStageChange(
                                  item,
                                  page,
                                  pageIdx,
                                  showOptions
                                    .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                                    .list.find(option => option.title == selectedTitle),
                                  item.annotationKind
                                )
                              "
                            >
                              <a-radio
                                :value="stage.stageName"
                                :disabled="!changeAll || isAutoSave"
                                v-for="stage in item.toStagesLeaveMessage.filter(s => {
                                  return changeAll || s.stageName == targetStage
                                })"
                                :key="stage.stageName"
                              >
                                {{ stage.stageName }}
                              </a-radio>
                            </a-radio-group>
                          </div>
                        </div>
                        <div class="local-content-list-user" v-if="item.initUser">
                          <div class="local-content-list-user-left" style="height:27px">
                            <img :src="item.initUser.avatar" />
                          </div>
                          <div class="local-content-list-user-right">
                            <div class="local-content-list-user-right-left">
                              <span class="local-content-list-user-right-left-top">
                                {{ item.initUser ? item.initUser.nickName : '' }}
                              </span>
                              <span style="margin-left:5px" class="local-content-list-user-right-left-bottom">
                                {{ item.initUser ? item.initUser.createTimeStr : '' }}
                              </span>
                            </div>
                            <div style="padding-top:5px;" class="local-content-list-user-right-right">
                              <span
                                @click.stop="
                                  checkPermission('fatchStraw:localStatus:edit') ? changeUpdateStatus(item) : () => {}
                                "
                                v-if="checkPermission('fatchStraw:localStatus:view')"
                                :class="
                                  item.updateStatus == 2 || !item.updateStatus
                                    ? 'change-sign'
                                    : 'change-sign change-sign-over'
                                "
                              >
                                <a-tooltip v-if="changeAll">
                                  <template slot="title">
                                    <span>{{
                                      item.updateStatus == 2 || !item.updateStatus ? '标记为“已解决”' : '标记为“未解决”'
                                    }}</span></template
                                  ><a-icon type="check-circle"
                                /></a-tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="local-content-list-user" v-else-if="item.commentData && item.commentData.length">
                          <div class="local-content-list-user-left" style="height:27px">
                            <img :src="item.commentData[0].avatar" />
                          </div>
                          <div class="local-content-list-user-right">
                            <div class="local-content-list-user-right-left">
                              <span class="local-content-list-user-right-left-top">
                                {{ item.commentData[0].userName }}
                              </span>
                              <span style="margin-left:5px;" class="local-content-list-user-right-left-bottom">
                                {{ item.commentData[0].createTimeStr }}
                              </span>
                            </div>
                            <div style="padding-top:5px;" class="local-content-list-user-right-right">
                              <span
                                @click.stop="
                                  checkPermission('fatchStraw:localStatus:edit') ? changeUpdateStatus(item) : () => {}
                                "
                                v-if="checkPermission('fatchStraw:localStatus:view')"
                                :class="
                                  item.updateStatus == 2 || !item.updateStatus
                                    ? 'change-sign'
                                    : 'change-sign change-sign-over'
                                "
                              >
                                <a-tooltip v-if="changeAll">
                                  <template slot="title">
                                    <span>{{
                                      item.updateStatus == 2 || !item.updateStatus ? '标记为“已解决”' : '标记为“未解决”'
                                    }}</span></template
                                  ><a-icon type="check-circle"
                                /></a-tooltip>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.injuryReason || item.edit || item.targetStage" class="local-content-list-error">
                          <!--                           <div style="display:flex;align-items:center;padding-top:5px" v-if="item.edit">
                            <span style="font-size:16px;">
                              To:
                            </span>
                            <div style="margin-left:12px;">
                              <a-radio-group @change="e => toStageChange(e, item)" v-model="targetStage" :default-value="item.targetStage">
                                <a-radio :value="stage.stageName" v-for="stage in item.toStagesAnnotation" :key="stage.stageName">
                                  {{ stage.stageName }}
                                </a-radio>
                              </a-radio-group>
                            </div>
                          </div> -->
                          <div class="local-content-list-error-top" style="padding-top:5px" v-if="item.injuryType">
                            <span style="font-weight:700;color:red;">错误类型</span>
                            <a-dropdown
                              v-if="
                                (changeAll &&
                                  (checkPermission('fatchStraw:localList:dele') ||
                                    (isLocalZhongShen && item.annotationKind == 1) ||
                                    (item.initUser && item.initUser.userId == userInfo.id) ||
                                    (item.targetStage == '终审' &&
                                      item.createStage == '监制' &&
                                      userInfo.positions.some(item => item.name == '终审')))) ||
                                  isAdmin ||
                                  (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                              "
                              :trigger="['click']"
                              transitionName=""
                            >
                              <a-icon type="more" @click.stop style="position:relative;top:5px" />
                              <a-menu slot="overlay">
                                <a-menu-item
                                  key="0"
                                  v-if="
                                    (item.initUser && item.initUser.userId == userInfo.id) ||
                                      isAdmin ||
                                      (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                                  "
                                  @click="editError(item)"
                                >
                                  编辑
                                </a-menu-item>
                                <a-menu-item
                                  key="1"
                                  v-if="
                                    checkPermission('fatchStraw:localList:dele') ||
                                      (isLocalZhongShen && item.annotationKind == 1) ||
                                      (item.initUser && item.initUser.userId == userInfo.id) ||
                                      (item.targetStage == '终审' &&
                                        item.createStage == '监制' &&
                                        userInfo.positions.some(item => item.name == '终审')) ||
                                      (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                                  "
                                  @click="deleError(item, page)"
                                >
                                  删除
                                </a-menu-item>
                              </a-menu>
                            </a-dropdown>
                          </div>
                          <div class="local-content-list-error-bottom" v-if="!item.edit">
                            {{
                              item.injuryType == 1
                                ? '软伤，'
                                : item.injuryType == 2
                                ? '硬伤，'
                                : item.injuryType == 4
                                ? '超级硬伤，'
                                : item.injuryType == 5
                                ? '超级软伤，'
                                : ''
                            }}{{ item.injuryReason || '' }}
                          </div>
                          <div class="local-content-list-error-bottom" v-else>
                            <div
                              class="local-item-action-radio"
                              v-if="
                                item.annotationKind &&
                                  item.annotationKind > 1 &&
                                  toStagesAnnotation.find(stage => stage.stageName == item.targetStage) &&
                                  toStagesAnnotation.find(stage => stage.stageName == item.targetStage).errorDict &&
                                  selectedTitle != '初稿'
                              "
                            >
                              <span
                                @click.stop="changeInjuryType(item, 0)"
                                :class="!item.injuryType ? 'error-span' : ''"
                                style="margin-left:3px"
                                >无<a-icon v-if="!item.injuryType" type="check-circle" theme="filled"
                              /></span>
                              <template v-if="toStagesAnnotation.find(stage => stage.stageName == item.targetStage)">
                                <span
                                  @click.stop="changeInjuryType(item, error.type, error.id)"
                                  v-for="error in toStagesAnnotation.find(stage => stage.stageName == item.targetStage)
                                    .errorDict"
                                  :key="error.id"
                                  :class="item.injuryType == error.type ? 'error-span' : ''"
                                  :style="{
                                    color:
                                      error.dictName.indexOf('硬') > -1 || error.dictName.indexOf('错翻') > -1
                                        ? 'red'
                                        : 'orange',
                                    marginLeft: '3px',
                                    width: error.dictName.length > 1 ? '35px' : ''
                                  }"
                                  >{{ error.dictName
                                  }}<a-icon v-if="item.injuryType == error.type" type="check-circle" theme="filled"
                                /></span>
                              </template>
                              <!--                               <span
                                v-if="targetStage != '监制' && taskDetail.stageName != '监制确认'"
                                @click.stop="changeInjuryType(item, 1)"
                                :class="item.injuryType == 1 ? 'error-span' : ''"
                                style="color:orange;margin-left:3px"
                                >软<a-icon v-if="item.injuryType == 1" type="check-circle" theme="filled"
                              /></span>
                              <span
                                v-if="
                                  targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'
                                "
                                @click.stop="changeInjuryType(item, 4)"
                                :class="item.injuryType == 4 ? 'error-span' : ''"
                                style="color:red;margin-left:3px;width:35px;"
                                >超硬<a-icon v-if="item.injuryType == 4" type="check-circle" theme="filled"
                              /></span>
                              <span
                                v-if="
                                  targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'
                                "
                                @click.stop="changeInjuryType(item, 5)"
                                :class="item.injuryType == 5 ? 'error-span' : ''"
                                style="color:orange;margin-left:3px;width:35px;"
                                >超软<a-icon v-if="item.injuryType == 5" type="check-circle" theme="filled"
                              /></span> -->
                            </div>
                            <div
                              class="local-item-action-radio"
                              v-else-if="
                                item.annotationKind &&
                                  item.annotationKind > 1 &&
                                  item.toStagesAnnotation &&
                                  item.toStagesAnnotation.find(stage => stage.stageName == item.targetStage) &&
                                  item.toStagesAnnotation.find(stage => stage.stageName == item.targetStage)
                                    .errorDict &&
                                  selectedTitle == '初稿'
                              "
                            >
                              <span
                                @click.stop="changeInjuryType(item, 0)"
                                :class="!item.injuryType ? 'error-span' : ''"
                                style="margin-left:3px"
                                >无<a-icon v-if="!item.injuryType" type="check-circle" theme="filled"
                              /></span>
                              <template
                                v-if="item.toStagesAnnotation.find(stage => stage.stageName == item.targetStage)"
                              >
                                <span
                                  @click.stop="changeInjuryType(item, error.type, error.id)"
                                  v-for="error in item.toStagesAnnotation.find(
                                    stage => stage.stageName == item.targetStage
                                  ).errorDict"
                                  :key="error.id"
                                  :class="item.injuryType == error.type ? 'error-span' : ''"
                                  :style="{
                                    color:
                                      error.dictName.indexOf('硬') > -1 || error.dictName.indexOf('错翻') > -1
                                        ? 'red'
                                        : 'orange',
                                    marginLeft: '3px',
                                    width: error.dictName.length > 1 ? '35px' : ''
                                  }"
                                  >{{ error.dictName
                                  }}<a-icon v-if="item.injuryType == error.type" type="check-circle" theme="filled"
                                /></span>
                              </template>
                              <!--                               <span
                                v-if="targetStage != '监制' && taskDetail.stageName != '监制确认'"
                                @click.stop="changeInjuryType(item, 1)"
                                :class="item.injuryType == 1 ? 'error-span' : ''"
                                style="color:orange;margin-left:3px"
                                >软<a-icon v-if="item.injuryType == 1" type="check-circle" theme="filled"
                              /></span>
                              <span
                                v-if="
                                  targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'
                                "
                                @click.stop="changeInjuryType(item, 4)"
                                :class="item.injuryType == 4 ? 'error-span' : ''"
                                style="color:red;margin-left:3px;width:35px;"
                                >超硬<a-icon v-if="item.injuryType == 4" type="check-circle" theme="filled"
                              /></span>
                              <span
                                v-if="
                                  targetStage != '监制' && targetStage != '校对' && taskDetail.stageName != '监制确认'
                                "
                                @click.stop="changeInjuryType(item, 5)"
                                :class="item.injuryType == 5 ? 'error-span' : ''"
                                style="color:orange;margin-left:3px;width:35px;"
                                >超软<a-icon v-if="item.injuryType == 5" type="check-circle" theme="filled"
                              /></span> -->
                            </div>
                            <div class="local-item-action-input" @click.stop>
                              <a-select
                                v-model="item.injuryReason"
                                v-if="item.injuryType && item.annotationKind && item.annotationKind > 1"
                                show-search
                                :placeholder="'请选择错误类型'"
                                style="width: 255px"
                              >
                                <a-select-option
                                  v-for="errorItem in dictList"
                                  :key="errorItem.id"
                                  :value="errorItem.itemText"
                                >
                                  {{ errorItem.itemText }}
                                </a-select-option>
                              </a-select>
                              <div style="padding-top:8px" v-if="item.commentData && item.commentData.length">
                                <j-editor
                                  ref="localContentInput"
                                  :placeholder="'ALT+回车换行'"
                                  v-model="localValue"
                                  :maxWidth="'50px'"
                                  :toolbar="[]"
                                ></j-editor>
                              </div>
                              <div class="local-item-action-input-button" @click.stop>
                                <a-button size="small" @click="cancelErrorEdit(item)">
                                  取消
                                </a-button>
                                <a-button
                                  style="margin-left:10px;"
                                  type="primary"
                                  size="small"
                                  @click="
                                    changeError(
                                      item,
                                      page,
                                      pageIdx,
                                      showOptions
                                        .find(showOption =>
                                          showOption.list.find(option => option.title == selectedTitle)
                                        )
                                        .list.find(option => option.title == selectedTitle)
                                    )
                                  "
                                >
                                  保存
                                </a-button>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              (!item.commentData || !item.commentData.length) &&
                                !item.edit &&
                                selectLocalId == item.id &&
                                item.injuryReason
                            "
                            style="padding-top:8px;padding-bottom:8px"
                          >
                            <j-editor
                              ref="localContentInput"
                              v-if="changeAll"
                              @postValue="
                                saveLoacalTwo(
                                  item,
                                  page,
                                  pageIdx,
                                  showOptions
                                    .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                                    .list.find(option => option.title == selectedTitle)
                                )
                              "
                              :placeholder="'ALT+回车换行，回车提交'"
                              v-model="item.localValue"
                              :maxWidth="'50px'"
                              :toolbar="[]"
                            ></j-editor>
                          </div>
                        </div>
                        <div
                          class="local-content-list-none"
                          v-if="
                            item.commentData && item.commentData.length && !item.commentData[0].content && !item.edit
                          "
                          :style="{ paddingBottom: item.commentData && item.commentData.length == 1 ? '10px' : '' }"
                        >
                          <div class="local-content-list-none-top">
                            <span style="color:rgba(0,0,0,0.45)">无说明</span>
                            <span v-if="!item.injuryReason">
                              <a-dropdown
                                v-if="
                                  (changeAll &&
                                    (checkPermission('fatchStraw:localList:dele') ||
                                      (isLocalZhongShen && item.annotationKind == 1) ||
                                      (item.initUser && userInfo.id == item.initUser.userId) ||
                                      (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages))) ||
                                    (item.targetStage == '终审' &&
                                      item.createStage == '监制' &&
                                      userInfo.positions.some(item => item.name == '终审'))
                                "
                                :trigger="['click']"
                                transitionName=""
                              >
                                <a-icon @click.stop type="more" />
                                <a-menu slot="overlay">
                                  <a-menu-item
                                    @click="editError(item)"
                                    v-if="
                                      item.commentData[0].userId == userInfo.id ||
                                        isAdmin ||
                                        (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                                    "
                                    key="0"
                                  >
                                    编辑
                                  </a-menu-item>
                                  <a-menu-item
                                    v-if="
                                      checkPermission('fatchStraw:localList:dele') ||
                                        (isLocalZhongShen && item.annotationKind == 1) ||
                                        (item.initUser && userInfo.id == item.initUser.userId) ||
                                        (item.id + '').indexOf('newLocal') > -1 ||
                                        (item.targetStage == '终审' &&
                                          item.createStage == '监制' &&
                                          userInfo.positions.some(item => item.name == '终审')) ||
                                        (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                                    "
                                    key="1"
                                    @click="deleError(item, page)"
                                  >
                                    删除
                                  </a-menu-item>
                                </a-menu>
                              </a-dropdown>
                            </span>
                          </div>
                          <div
                            class="local-content-list-none-bottom"
                            v-if="selectLocalId == item.id && item.commentData && item.commentData.length == 1"
                            style="padding-top:8px"
                          >
                            <div style="flex:1">
                              <j-editor
                                ref="localContentInput"
                                v-if="changeAll"
                                @postValue="
                                  saveLoacal(
                                    item,
                                    page,
                                    pageIdx,
                                    showOptions
                                      .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                                      .list.find(option => option.title == selectedTitle)
                                  )
                                "
                                :placeholder="'ALT+回车换行，回车提交'"
                                v-model="localValue"
                                :maxWidth="'50px'"
                                :toolbar="[]"
                              ></j-editor>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            item.commentData &&
                              ((item.commentData && item.commentData.length > 1) ||
                                (item.commentData && item.commentData[0] && item.commentData[0].content))
                          "
                          class="local-content-list-content"
                          :style="{ paddingBottom: '10px', paddingTop: !item.injuryType ? '3px' : '' }"
                        >
                          <div
                            class="local-content-list-content-title"
                            v-if="!item.edit && item.commentData && item.commentData[0] && item.commentData[0].content"
                          >
                            <!-- 陈立喊隐藏说明 -->
                            <!-- <span>
                              说明：
                            </span> -->
                            <span style="flex:1;display: flex;justify-content: space-between;padding-left:36px;">
                              <div
                                style="font-weight:400"
                                class="show-local-content"
                                @copy="textCopy"
                                v-html="item.commentData[0].content"
                              ></div>
                              <div style="min-width:16px">
                                <a-dropdown
                                  v-if="
                                    (changeAll &&
                                      !item.injuryType &&
                                      (checkPermission('fatchStraw:localList:dele') ||
                                        (isLocalZhongShen && item.annotationKind == 1) ||
                                        (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages) ||
                                        item.commentData[0].userId == userInfo.id)) ||
                                      (item.targetStage == '终审' &&
                                        item.createStage == '监制' &&
                                        userInfo.positions.some(item => item.name == '终审'))
                                  "
                                  :trigger="['click']"
                                  transitionName=""
                                >
                                  <a-icon type="more" @click.stop />
                                  <a-menu slot="overlay">
                                    <a-menu-item
                                      @click="editError(item)"
                                      v-if="
                                        item.commentData[0].userId == userInfo.id ||
                                          isAdmin ||
                                          (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                                      "
                                      key="1"
                                    >
                                      编辑
                                    </a-menu-item>
                                    <a-menu-item
                                      v-if="
                                        checkPermission('fatchStraw:localList:dele') ||
                                          (isLocalZhongShen && item.annotationKind == 1) ||
                                          (taskDetail.departName.indexOf('原创部') > -1 &&
                                            taskDetail.canRejectStages) ||
                                          item.commentData[0].userId == userInfo.id ||
                                          (item.targetStage == '终审' &&
                                            item.createStage == '监制' &&
                                            userInfo.positions.some(item => item.name == '终审'))
                                      "
                                      key="2"
                                      @click="deleError(item, page)"
                                    >
                                      删除
                                    </a-menu-item>
                                  </a-menu>
                                </a-dropdown>
                              </div>
                            </span>
                          </div>
                          <template
                            v-if="
                              item.commentData &&
                                item.commentData[0] &&
                                item.commentData.filter((an, anIdx) => anIdx).length
                            "
                          >
                            <div
                              v-for="an in item.commentData.filter((an, anIdx) => anIdx)"
                              :key="an.id"
                              style="margin-top:3px;"
                            >
                              <div class="local-content-list-content-top">
                                <div class="local-content-list-content-left">
                                  <img :src="an.avatar" />
                                </div>
                                <div class="local-content-list-content-right" style="margin-left:12px;">
                                  <div class="local-content-list-content-right-left">
                                    <div>
                                      <span>{{ an.userName || '' }}</span>
                                      <span style="margin-left:5px">{{ an.createTimeStr || '' }}</span>
                                    </div>
                                    <!-- <div>{{ an.createTimeStr || '' }}</div> -->
                                  </div>
                                  <div class="local-content-list-content-right-right" style="position:relative;top:4px">
                                    <a-dropdown
                                      v-if="
                                        changeAll &&
                                          (checkPermission('fatchStraw:localList:dele') ||
                                            (isLocalZhongShen && item.annotationKind == 1) ||
                                            (taskDetail.departName.indexOf('原创部') > -1 &&
                                              taskDetail.canRejectStages) ||
                                            an.userId == userInfo.id ||
                                            isAdmin ||
                                            (item.targetStage == '终审' &&
                                              item.createStage == '监制' &&
                                              userInfo.positions.some(item => item.name == '终审')))
                                      "
                                      :trigger="['click']"
                                      transitionName=""
                                    >
                                      <a-icon type="more" @click.stop />
                                      <a-menu slot="overlay">
                                        <a-menu-item
                                          key="0"
                                          v-if="
                                            an.userId == userInfo.id ||
                                              isAdmin ||
                                              (taskDetail.departName.indexOf('原创部') > -1 &&
                                                taskDetail.canRejectStages)
                                          "
                                          @click="anEdit(an)"
                                        >
                                          编辑
                                        </a-menu-item>
                                        <a-menu-item
                                          v-if="
                                            checkPermission('fatchStraw:localList:dele') ||
                                              (isLocalZhongShen && item.annotationKind == 1) ||
                                              (taskDetail.departName.indexOf('原创部') > -1 &&
                                                taskDetail.canRejectStages) ||
                                              an.userId == userInfo.id ||
                                              (item.targetStage == '终审' &&
                                                item.createStage == '监制' &&
                                                userInfo.positions.some(item => item.name == '终审'))
                                          "
                                          key="1"
                                          @click="anDele(item, an.id)"
                                        >
                                          删除
                                        </a-menu-item>
                                      </a-menu>
                                    </a-dropdown>
                                  </div>
                                </div>
                              </div>
                              <div class="local-content-list-content-bottom" style="padding-left:36px">
                                <!-- <div style="width:36px"></div> -->
                                <div
                                  v-if="!an.edit"
                                  class="show-local-content"
                                  v-html="an.content"
                                  @copy="textCopy"
                                  style="min-height:21px"
                                ></div>
                                <div style="padding-top:10px;padding-bottom:10px;" v-else>
                                  <div>
                                    <j-editor
                                      ref="localContentInput"
                                      v-if="changeAll"
                                      @postValue="
                                        editLoacal(
                                          item,
                                          page,
                                          pageIdx,
                                          showOptions
                                            .find(showOption =>
                                              showOption.list.find(option => option.title == selectedTitle)
                                            )
                                            .list.find(option => option.title == selectedTitle),
                                          an.id,
                                          an
                                        )
                                      "
                                      :placeholder="'ALT+回车换行，回车提交'"
                                      v-model="an.content"
                                      :maxWidth="'50px'"
                                      :toolbar="[]"
                                    ></j-editor>
                                  </div>
                                  <div class="local-item-action-input-button" @click.stop>
                                    <a-button size="small" @click="cancelErrorEdit(an)">
                                      取消
                                    </a-button>
                                    <a-button
                                      style="margin-left:10px;"
                                      type="primary"
                                      size="small"
                                      @click="
                                        editLoacal(
                                          item,
                                          page,
                                          pageIdx,
                                          showOptions
                                            .find(showOption =>
                                              showOption.list.find(option => option.title == selectedTitle)
                                            )
                                            .list.find(option => option.title == selectedTitle),
                                          an.id,
                                          an
                                        )
                                      "
                                    >
                                      保存
                                    </a-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                          <div
                            v-if="
                              selectLocalId == item.id &&
                                !item.edit &&
                                (!item.commentData ||
                                  !item.commentData.length ||
                                  (item.commentData && item.commentData.every(an => !an.edit)))
                            "
                            class="huifu"
                            style="padding-top:10px;"
                          >
                            <j-editor
                              ref="localContentInput"
                              v-if="changeAll"
                              @postValue="
                                saveLoacal(
                                  item,
                                  page,
                                  pageIdx,
                                  showOptions
                                    .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                                    .list.find(option => option.title == selectedTitle)
                                )
                              "
                              :placeholder="'ALT+回车换行，回车提交'"
                              v-model="localValue"
                              :maxWidth="'50px'"
                              :toolbar="[]"
                            ></j-editor>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </div>
            <div
              v-else
              class="local-content"
              :style="
                item.allHeight
                  ? {
                      maxHeight: bottomHeight - 270 + 'px !important',
                      position: 'relative',
                      left: '-16px',
                      width: 'calc(100% + 14px)'
                    }
                  : 'max-height: 400px;position:relative;left:-16px;width:calc(100% + 14px);'
              "
            >
              <div v-if="selectedTitle != '原稿' && selectedTitle != '原稿JPG' && selectedTitle != '实时'">
                <div class="open-all">
                  <a
                    @click.stop="openAllLocal"
                    v-if="
                      reDefaultKey.length <
                        showOptions
                          .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                          .list.find(option => option.title == selectedTitle)
                          .manuscriptList.filter(page => page.annotations && page.annotations.length).length
                    "
                    >全展开/收起</a
                  >
                  <a @click.stop="closeAllLocal" v-else>全展开/收起</a>
                </div>
                <a-collapse
                  v-if="
                    showOptions
                      .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                      .list.find(option => option.title == selectedTitle).manuscriptList &&
                      showOptions
                        .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                        .list.find(option => option.title == selectedTitle).manuscriptList.length
                  "
                  v-model="reDefaultKey"
                  :bordered="false"
                >
                  <template #expandIcon="props">
                    <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                  </template>
                  <a-collapse-panel
                    v-for="(page, pageIdx) in showOptions
                      .find(showOption => showOption.list.find(option => option.title == selectedTitle))
                      .list.find(option => option.title == selectedTitle)
                      .manuscriptList.filter(
                        page =>
                          page.annotations &&
                          page.annotations.filter(
                            item =>
                              (item.commentData &&
                                item.commentData[0] &&
                                item.commentData.filter(
                                  an =>
                                    !radioValue ||
                                    (radioValue == 2 && (an.machineStatus == 2 || !an.machineStatus)) ||
                                    (radioValue == 1 && an.machineStatus == 1)
                                ).length &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                item.annotationKind != 1) ||
                              (item.annotationKind == 1 &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                hasLocal &&
                                (!radioValue ||
                                  (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                  (radioValue == 1 && item.updateStatus == 1)))
                          ).length
                      )"
                    :key="page.id + ''"
                    :style="customStyle"
                  >
                    <div slot="header">
                      <span>{{ page.fileName }}</span>
                      <span style="margin-left:20px">{{
                        `共${
                          page.annotations.filter(
                            item =>
                              (item.commentData &&
                                item.commentData[0] &&
                                item.commentData.filter(
                                  an =>
                                    !radioValue ||
                                    (radioValue == 2 && (an.machineStatus == 2 || !an.machineStatus)) ||
                                    (radioValue == 1 && an.machineStatus == 1)
                                ).length &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                item.annotationKind != 1) ||
                              (item.annotationKind == 1 &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                hasLocal &&
                                (!radioValue ||
                                  (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                  (radioValue == 1 && item.updateStatus == 1)))
                          ).length
                        }处`
                      }}</span>
                    </div>
                    <div
                      :class="
                        selectLocalId == item.id
                          ? 'local-content-list-show local-content-list-show-selected'
                          : 'local-content-list-show'
                      "
                      @click="toLocal(page, item, pageIdx)"
                      v-for="(item, itemIndex) in page.annotations.filter(
                        item =>
                          item.commentData &&
                          item.commentData[0] &&
                          item.commentData.filter(
                            an =>
                              ((!radioValue ||
                                (radioValue == 2 && (an.machineStatus == 2 || !an.machineStatus)) ||
                                (radioValue == 1 && an.machineStatus == 1)) &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                item.annotationKind != 1) ||
                              (item.annotationKind == 1 &&
                                (checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                                hasLocal &&
                                (!radioValue ||
                                  (radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                                  (radioValue == 1 && item.updateStatus == 1)))
                          ).length
                      )"
                      :ref="item.id + 'local'"
                      :key="item.id"
                    >
                      <div class="local-content-list-num">
                        <div>{{ itemIndex + 1 }}</div>
                      </div>
                      <div class="local-content-list">
                        <div
                          v-if="
                            item.commentData &&
                              item.commentData[0] &&
                              item.commentData.filter(
                                an =>
                                  !radioValue ||
                                  (radioValue == 2 && (an.machineStatus == 2 || !an.machineStatus)) ||
                                  (radioValue == 1 && an.machineStatus == 1)
                              ).length
                          "
                          class="local-content-list-content"
                          style="padding-bottom:10px;padding-left:0px"
                        >
                          <div
                            v-for="an in item.commentData.filter(
                              an =>
                                !radioValue ||
                                (radioValue == 2 && (an.machineStatus == 2 || !an.machineStatus)) ||
                                (radioValue == 1 && an.machineStatus == 1)
                            )"
                            :key="an.id"
                            style="margin-top:3px;"
                          >
                            <div class="local-content-list-content-top">
                              <div class="local-content-list-content-left" style="align-items:start;">
                                <img :src="an.avatar" style="position: relative;top: 1px;" />
                              </div>
                              <div class="local-content-list-content-right">
                                <div class="local-content-list-content-right-left" style="display:block">
                                  <div style="line-height:25px">
                                    <span style="margin-left:11px">{{ an.userName || '' }}</span>
                                    <span style="margin-left:8px">{{ an.createTimeStr || '' }}</span>
                                  </div>
                                  <!-- <div>{{ an.createTimeStr || '' }}</div> -->
                                </div>
                                <div class="local-content-list-content-right-right">
                                  <a-dropdown v-if="changeAll" :trigger="['click']" transitionName="">
                                    <a-icon type="more" @click.stop />
                                    <a-menu slot="overlay">
                                      <a-menu-item key="0" @click="anTranslate(an, page, item)">
                                        翻译
                                      </a-menu-item>
                                    </a-menu>
                                  </a-dropdown>
                                </div>
                              </div>
                            </div>
                            <div class="local-content-list-content-bottom">
                              <!-- <div style="width:36px"></div> -->
                              <div
                                class="show-local-content"
                                v-html="an.content"
                                @copy="textCopy"
                                :id="page.id + '-' + item.id + '-' + an.id"
                                style="min-height:21px;padding-bottom:5px;"
                              ></div>
                              <div
                                style="padding-top:5px;padding-bottom:10px;border-top:1px solid rgba(0,0,0,0.1)"
                                v-if="an.machineTranslation || an.edit"
                              >
                                <div v-if="!an.edit" @click="translateEdit(an)">{{ an.machineTranslation }}</div>
                                <div v-else style="padding-top:5px;padding-bottom:5px">
                                  <a-textarea
                                    v-model="an.machineTranslation"
                                    placeholder="请输入"
                                    @blur="
                                      editTranslate(
                                        item,
                                        page,
                                        pageIdx,
                                        showOptions
                                          .find(showOption =>
                                            showOption.list.find(option => option.title == selectedTitle)
                                          )
                                          .list.find(option => option.title == selectedTitle),
                                        an.id,
                                        an
                                      )
                                    "
                                    :auto-size="{ minRows: 1, maxRows: 10 }"
                                  />
                                </div>
                                <div>
                                  <span
                                    @click.stop="
                                      checkPermission('fatchStraw:localStatus:edit')
                                        ? changeMachineStatus(
                                            item,
                                            page,
                                            pageIdx,
                                            showOptions
                                              .find(showOption =>
                                                showOption.list.find(option => option.title == selectedTitle)
                                              )
                                              .list.find(option => option.title == selectedTitle),
                                            an.id,
                                            an
                                          )
                                        : () => {}
                                    "
                                    v-if="checkPermission('fatchStraw:localStatus:view')"
                                    :class="
                                      an.machineStatus == 2 || !an.machineStatus
                                        ? 'change-sign'
                                        : 'change-sign change-sign-over'
                                    "
                                  >
                                    <a-tooltip v-if="changeAll">
                                      <template slot="title">
                                        <span>{{
                                          an.machineStatus == 2 || !an.machineStatus
                                            ? '标记为“已校对”'
                                            : '标记为“未校对”'
                                        }}</span></template
                                      ><span
                                        ><a-icon type="check-circle" /><span style="margin-left:5px">{{
                                          an.machineStatus == 2 || !an.machineStatus ? '未校对' : '已校对'
                                        }}</span></span
                                      ></a-tooltip
                                    >
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
            </div>
            <div
              class="local-content"
              v-if="selectedTitle == '实时'"
              style="position:relative;"
              :style="item.allHeight ? { maxHeight: bottomHeight - 170 + 'px !important' } : 'max-height: 400px;'"
            >
              <div style="padding: 12px 0;" v-if="leftAnnotationList.length">
                <a-checkbox-group
                  v-model="checkedToStages"
                  :options="[
                    { label: '翻译环节', value: '翻译环节' },
                    { label: '制作环节', value: '制作环节' }
                  ]"
                />
              </div>
              <div v-if="rightAnnotationList.length" style="top:57px;" class="open-all-annotation-list">
                <a
                  v-if="rightAnnotationList.some(page => page.annotationList.some(anItem => !anItem.selected))"
                  @click.stop="openAnnotationList"
                  >全展开/收起</a
                >
                <a v-else @click.stop="closeAnnotationList">全展开/收起</a>
              </div>
              <a-collapse
                style="position:relative;left:-16px;"
                v-if="rightAnnotationList.length"
                v-model="rightAnnotationOpenKey"
                :bordered="false"
              >
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <template v-for="(page, pageIdx) in rightAnnotationList">
                  <a-collapse-panel
                    v-if="
                      page.annotationList &&
                        page.annotationList.filter(annoItem =>
                          checkedToStages.some(toStage => toStage == annoItem.toStage)
                        ).length
                    "
                    :key="pageIdx + 'rightOldLocal'"
                    :style="customStyle"
                  >
                    <div slot="header">
                      <span>{{
                        `第${showOptions
                          .find(showOption => showOption.list.find(option => option.title == '实时'))
                          .list.find(option => option.title == '实时')
                          .manuscriptList.findIndex(p => p.id == page.pageId) + 1}页`
                      }}</span>
                      <span style="margin-left:20px">{{ `共${page.annotationList.length}处` }}</span>
                    </div>
                    <div
                      :class="
                        selectId === annoItem.id
                          ? 'local-content-list-show-old local-content-list-show-selected'
                          : 'local-content-list-show-old'
                      "
                      @click.stop="toLocalOld(annoItem, '实时')"
                      v-for="annoItem in page.annotationList.filter(annoItem =>
                        checkedToStages.some(toStage => toStage == annoItem.toStage)
                      )"
                      :key="annoItem.id + 'oldLocal'"
                    >
                      <div class="local-content-list-num-two">
                        <div>{{ annoItem.sequenceNum }}</div>
                      </div>
                      <div class="local-content-list-old" v-if="annoItem.commentData && annoItem.commentData.length">
                        <div class="local-content-item" v-for="(i, idx) in annoItem.commentData" :key="'anno' + idx">
                          <div style="padding: 5px 0 5px 0;">{{ annoItem.toStage }}</div>
                          <div class="local-content-item-top">
                            <div class="local-content-item-top-left">
                              <img :src="i.avatar" alt="" />
                            </div>
                            <div class="local-content-item-top-right">
                              <div class="local-content-item-top-right-user">
                                <span>{{ i.userName }}</span>
                                <span :class="selectId == annoItem.id ? 'action-span-select' : 'action-span'">
                                  <!--                                   <a-dropdown
                                    v-if="
                                      changeAll &&
                                        (i.userId == userInfo.id || checkPermission('fatchStraw:localList:dele'))
                                    "
                                    :trigger="['click']"
                                    transitionName=""
                                  >
                                    <a-icon @click.stop type="more" v-if="i.userId == userInfo.id" />
                                    <a-menu slot="overlay">
                                      <a-menu-item v-if="i.userId == userInfo.id" @click="editLocalContent(i)" key="0">
                                        编辑
                                      </a-menu-item>
                                      <a-menu-item
                                        v-if="i.userId == userInfo.id || checkPermission('fatchStraw:localList:dele')"
                                        key="1"
                                        @click="deletLocal(annoItem)"
                                      >
                                        删除
                                      </a-menu-item>
                                    </a-menu>
                                  </a-dropdown> -->
                                </span>
                              </div>
                              <div>
                                {{ i.createTimeStr }}
                              </div>
                            </div>
                          </div>
                          <div @click.stop="toLocalOld(annoItem, '实时')" class="local-content-item-bottom">
                            <span
                              v-if="!i.editble"
                              class="show-local-content"
                              @copy="textCopy"
                              v-html="i.content"
                            ></span>
                            <div v-else style="padding-right:5px;padding-top:3px;">
                              <a-textarea
                                @click.stop
                                v-if="changeAll"
                                v-model="i.content"
                                placeholder="ALT+回车换行，回车提交"
                                @keydown.enter="
                                  e => {
                                    localEnter(e, annoItem, i)
                                  }
                                "
                                :auto-size="{ minRows: 1, maxRows: 10 }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </template>
              </a-collapse>
              <div v-else style="padding-top:15px;padding-bottom:15px;">
                <a-empty />
              </div>
            </div>
            <div v-if="selectedTitle == '原稿'" style=" position: relative;">
              <div v-if="leftAnnotationList.length" class="open-all-annotation-list">
                <a
                  v-if="leftAnnotationList.some(page => page.annotationList.some(anItem => !anItem.selected))"
                  @click.stop="openAnnotationList"
                  >全展开/收起</a
                >
                <a v-else @click.stop="closeAnnotationList">全展开/收起</a>
              </div>
              <a-collapse v-if="leftAnnotationList.length" v-model="leftAnnotationOpenKey" :bordered="false">
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <template v-for="(page, pageIdx) in leftAnnotationList">
                  <a-collapse-panel
                    v-if="
                      page.annotationList &&
                        page.annotationList.filter(annoItem =>
                          checkedToStages.some(toStage => toStage == annoItem.toStage)
                        ).length
                    "
                    :key="pageIdx + 'oldLocal'"
                    :style="customStyle"
                  >
                    <div slot="header">
                      <span>{{
                        `第${showOptions
                          .find(showOption => showOption.list.find(option => option.title == '实时'))
                          .list.find(option => option.title == '原稿')
                          .manuscriptList.findIndex(p => p.id == page.pageId) + 1}页`
                      }}</span>
                      <span style="margin-left:20px">{{ `共${page.annotationList.length}处` }}</span>
                    </div>
                    <div
                      :class="
                        selectId === annoItem.id
                          ? 'local-content-list-show-old local-content-list-show-selected'
                          : 'local-content-list-show-old'
                      "
                      @click.stop="toLocalOld(annoItem, '原稿')"
                      v-for="annoItem in page.annotationList"
                      :key="annoItem.id + 'oldLocal'"
                    >
                      <div class="local-content-list-num-two">
                        <div>{{ annoItem.sequenceNum }}</div>
                      </div>
                      <div class="local-content-list-old" v-if="annoItem.commentData && annoItem.commentData.length">
                        <div class="local-content-item" v-for="(i, idx) in annoItem.commentData" :key="'anno' + idx">
                          <div class="local-content-item-top">
                            <div class="local-content-item-top-left">
                              <img :src="i.avatar" alt="" />
                            </div>
                            <div class="local-content-item-top-right">
                              <div class="local-content-item-top-right-user">
                                <span>{{ i.userName }}</span>
                                <span :class="selectId == annoItem.id ? 'action-span-select' : 'action-span'">
                                  <a-dropdown
                                    v-if="
                                      changeAll &&
                                        (i.userId == userInfo.id ||
                                          isAdmin ||
                                          checkPermission('fatchStraw:localList:dele') ||
                                          (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages))
                                    "
                                    :trigger="['click']"
                                    transitionName=""
                                  >
                                    <a-icon @click.stop type="more" v-if="i.userId == userInfo.id" />
                                    <a-menu slot="overlay">
                                      <a-menu-item
                                        v-if="
                                          i.userId == userInfo.id ||
                                            isAdmin ||
                                            (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                                        "
                                        @click="editLocalContent(i)"
                                        key="0"
                                      >
                                        编辑
                                      </a-menu-item>
                                      <a-menu-item
                                        v-if="
                                          i.userId == userInfo.id ||
                                            (isLocalZhongShen && item.annotationKind == 1) ||
                                            checkPermission('fatchStraw:localList:dele') ||
                                            (taskDetail.departName.indexOf('原创部') > -1 && taskDetail.canRejectStages)
                                        "
                                        key="1"
                                        @click="deletLocal(annoItem)"
                                      >
                                        删除
                                      </a-menu-item>
                                    </a-menu>
                                  </a-dropdown>
                                </span>
                              </div>
                              <div>
                                {{ i.createTimeStr }}
                              </div>
                            </div>
                          </div>
                          <div @click.stop="toLocalOld(annoItem, '原稿')" class="local-content-item-bottom">
                            <span v-if="!i.editble" @copy="textCopy" v-html="i.content"></span>
                            <div v-else style="padding-right:5px;padding-top:3px;">
                              <a-textarea
                                @click.stop
                                v-if="changeAll"
                                v-model="i.content"
                                placeholder="ALT+回车换行，回车提交"
                                @keydown.enter="
                                  e => {
                                    localEnter(e, annoItem, i)
                                  }
                                "
                                :auto-size="{ minRows: 1, maxRows: 10 }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </template>
              </a-collapse>
              <div v-else style="padding-top:15px;padding-bottom:15px;">
                <a-empty />
              </div>
            </div>
          </div>
          <div v-if="item.id == 1" class="diff-show">
            <div
              v-if="diffs.length"
              :style="
                item.allHeight
                  ? { maxHeight: bottomHeight - 130 + 'px !important', overflowY: 'auto', position: 'relative' }
                  : 'max-height: 400px;overflow-y: auto;position: relative;'
              "
            >
              <div class="open-all">
                <a @click.stop="openAllDiff" v-if="activeKey.length < diffs.filter(diff => diff.rectangles).length"
                  >全展开/收起</a
                >
                <a @click.stop="closeAllDiff" v-else>全展开/收起</a>
              </div>
              <a-collapse v-if="diffs.length > 0" v-model="activeKey" :bordered="false">
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <template v-for="(item, index) in diffs">
                  <a-collapse-panel
                    :style="customStyle"
                    :key="'diff-' + index"
                    v-if="item.rectangles != null && item.rectangles.length > 0"
                    ><!-- 换行格式 -->
                    <div slot="header" style="word-wrap: break-word;white-space: pre-line;max-width:200px"
                    >{{
                        item.fileName + ` 共${item.rectangles ? item.rectangles.length : 0}处` ||
                          `共${item.rectangles ? item.rectangles.length : 0}处`
                      }}
                    </div>
                    <template v-if="item.rectangles">
                      <p style="padding-left:24px;" v-for="(file, i) in item.rectangles" :key="'diff-item-' + i">
                        <a-icon
                          style="margin-right: 6px;padding-top:4px"
                          :type="!file.isShow ? 'eye' : 'eye-invisible'"
                          @click.stop="showDiff(file)"
                        />
                        <span
                          @click.self="goToDiff(item, file, index, i)"
                          :class="selectedDiffIdx == index + '-' + i ? 'diff-item diff-item-select' : 'diff-item'"
                          >第{{ i + 1 }}处</span
                        >
                      </p>
                    </template>
                  </a-collapse-panel>
                </template>
              </a-collapse>
            </div>
            <a-empty v-else />
          </div>
          <div
            class="text-list-show"
            v-if="item.id == 3"
            :style="
              item.allHeight
                ? { maxHeight: bottomHeight - 120 + 'px !important', overflowY: 'auto', position: 'relative' }
                : 'overflow-y: auto;position: relative;'
            "
          >
            <div class="text-list-top">
              <div>
                <a-input-search
                  v-model="textInputValue"
                  placeholder="请输入内容"
                  style="width: 200px"
                  @search="textSearch"
                />
              </div>
              <div>共{{ getTextAreaNum('showText') }}个文本，{{ getTextNum('showText') }}个字</div>
            </div>
            <div
              class="text-list-bottom"
              :style="item.allHeight ? { maxHeight: bottomHeight - 180 + 'px !important' } : 'max-height: 400px;'"
            >
              <div v-if="textList.length" class="open-all-annotation-list">
                <a v-if="textDefaultKey.length != textList.length" @click.stop="openTextList">全展开/收起</a>
                <a v-else @click.stop="closeTextList">全展开/收起</a>
              </div>
              <a-collapse
                v-if="textList.length"
                @change="textDefaultKeyChange"
                v-model="textDefaultKey"
                :bordered="false"
              >
                <template #expandIcon="props">
                  <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="(page, pageIdx) in textList" :key="page.pageId + ''" :style="customStyle">
                  <div slot="header">
                    <span>{{ `第${page.pageSequenceNum}页` }}</span>
                    <span style="margin-left:20px">{{
                      `共${page.textAreaCount}个文本，${page.translationWordCount}个字`
                    }}</span>
                  </div>
                  <div
                    :class="
                      selectTextId === item.id
                        ? 'local-content-list-show local-content-list-show-selected'
                        : 'local-content-list-show'
                    "
                    @click.stop="toText(page, item, page.pageSequenceNum - 1)"
                    v-for="item in page.textList"
                    :key="item.id"
                    :id="'textArea-list-item-' + item.id"
                  >
                    <div class="local-content-list-num" style="padding-top:1px">
                      <div>{{ item.textAreaSequenceNum }}</div>
                    </div>
                    <div class="local-content-list">
                      <div>
                        <!-- 换行格式 -->
                        <div class="space-text" v-if="item.translationText&&!item.imgUrl"
                        >{{
                            item.phoneticList.length
                              ? item.translationText.slice(0, 3) + item.phoneticList.map(p => p.text).join('')
                              : item.translationText
                          }}<a-icon
                            @click.stop
                            v-copy="
                              item.phoneticList.length
                                ? item.phoneticList.map(p => p.text).join('')
                                : item.translationText.slice(3, item.translationText.length)
                            "
                            @mouseup="copyMessage"
                            type="copy"
                          />
                        </div>
                         <div v-else-if="item.imgUrl" style="color:rgba(0,0,0,0.25)">
                            {{'图片附件'}}
                          </div>
                      </div>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Pushpin, Translate } from '@icon-park/vue'
import JEditor from './JEditor3'
import { getAction, deleteAction } from '@/api/manage'
import { checkPermission } from './../utils/hasPermission'
import Vue from 'vue'
Vue.directive('copy', {
  bind: (el, binding) => {
    el.$copy = () => {
      const textarea = document.createElement('textarea')
      textarea.value = binding.value
      document.body.appendChild(textarea)
      textarea.select()
      document.execCommand('Copy')
      document.body.removeChild(textarea)
    }
    el.addEventListener('click', el.$copy)
  },
  unbind: el => {
    el.removeEventListener('click', el.$copy)
  }
})
export default {
  props: {
    showTextArea: {
      type: String,
      default: 'ON'
    },
    showDiffs: {
      type: String,
      default: 'ON'
    },
    contentList: {
      type: Array,
      default: () => []
    },
    authList: {
      type: Array,
      default: () => []
    },
    textList: {
      type: Array,
      default: () => []
    },
    color: {
      type: String,
      default: '#000000'
    },
    diffTitle: {
      type: String,
      default: ''
    },
    departName: {
      type: String,
      default: ''
    },
    isWindows: {
      type: Boolean,
      default: false
    },
    selectedDiffIdx: {
      type: String,
      default: ''
    },
    bottomHeight: {
      type: Number,
      default: 0
    },
    diffsTotal: {
      type: Number,
      default: 0
    },
    selectLocalId: {
      type: [String, Number],
      default: -1
    },
    errorDictList: {
      type: Array,
      default: () => []
    },
    toStages: {
      type: Array,
      default: () => []
    },
    contentToStages: {
      type: Array,
      default: () => []
    },
    toStagesAnnotation: {
      type: Array,
      default: () => []
    },
    allOption: {
      type: Array,
      default: () => []
    },
    diffs: {
      type: Array,
      default: () => []
    },
    allStages: {
      type: Array,
      default: () => []
    },
    contentEdit: {
      type: Boolean,
      default: false
    },
    isAutoSave: {
      type: Boolean,
      default: false
    },
    rightAnnotationList: {
      type: Array,
      default: () => []
    },
    leftAnnotationList: {
      type: Array,
      default: () => []
    },
    showAnnotationList: {
      type: Array,
      default: () => []
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    taskDetail: {
      type: Object,
      default: () => ({})
    },
    changeAll: {
      type: Boolean,
      default: false
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    isLocalZhongShen: {
      type: Boolean,
      default: false
    },
    showOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Translate,
    Pushpin,
    JEditor
  },
  data() {
    return {
      translateVisible: false,
      translateLoading: false,
      noDrag: false,
      selectTextId: null,
      rightAnnotationOpenKey: [],
      textInputValue: '',
      leftAnnotationOpenKey: [],
      translateColor: '#4a4a4a',
      selectedTitle: '初稿',
      targetStage: '',
      isTranslate: false,
      hasLocal: true,
      sourceLang: '自动检测',
      targetLang: '中文',
      checkedToStages: ['制作环节'],
      activeKey: [],
      radioValue: 0,
      reDefaultKey: [],
      checkedStages: [],
      changeSelectedTitle: false,
      selectId: null,
      dictList: [],
      localValue: '',
      textDefaultKey: [],
      customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 0px;border: 0;overflow: hidden'
    }
  },
  watch: {
    localValue(newval) {
      this.localValue = newval
      if (this.$refs.localContentInput) {
        this.$nextTick(() => {
          this.$refs.localContentInput[0].EditorOnsize()
        })
      }
    },
    checkedStages() {
      this.$emit('changeCheckedStages', this.checkedStages)
    },
    radioValue() {
      this.$emit('changeRadioValue', this.radioValue)
    },
    hasLocal() {
      this.$emit('changeHasLocal', this.hasLocal)
    },
    reDefaultKey() {
      this.$emit('showImgHandleImgClick')
    },
    rightAnnotationOpenKey() {
      this.$emit('showImgHandleImgClick')
    },
    checkedToStages() {
      this.$emit('showImgHandleImgClick')
      this.$emit('changeCheckedToStages', this.checkedToStages)
    }
  },
  methods: {
    getLoacalTotal(title) {
      let num = 0
      if (title != '实时' && title != '原稿') {
        this.showOptions.forEach(showOption => {
          let addNum = 0
          if (showOption.list.find(option => option.title == title)) {
            showOption.list
              .find(option => option.title == title)
              .manuscriptList?.filter(
                page =>
                  page.annotations &&
                  page.annotations.filter(
                    item =>
                      item.commentData &&
                      item.commentData[0] &&
                      item.commentData.filter(
                        an =>
                          !this.radioValue ||
                          (this.radioValue == 2 && (an.machineStatus == 2 || !an.machineStatus)) ||
                          (this.radioValue == 1 && an.machineStatus == 1)
                      ).length &&
                      (this.checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                      item.annotationKind != 1
                  ).length
              )
              .forEach(page => {
                addNum += page.annotations.filter(
                  item =>
                    item.commentData &&
                    item.commentData[0] &&
                    item.commentData.filter(
                      an =>
                        !this.radioValue ||
                        (this.radioValue == 2 && (an.machineStatus == 2 || !an.machineStatus)) ||
                        (this.radioValue == 1 && an.machineStatus == 1)
                    ).length &&
                    (this.checkedStages.some(c => c == item.targetStage) || !item.targetStage) &&
                    item.annotationKind != 1
                ).length
              })
          }
          //陈立说的 不统计留言
          num += addNum || 0
        })
      } else if (title == '实时') {
        this.rightAnnotationList.forEach(page => {
          if (page.annotationList) {
            num += page.annotationList.length
          }
        })
      } else if (title == '原稿') {
        this.leftAnnotationList.forEach(page => {
          if (page.annotationList) {
            num += page.annotationList.length
          }
        })
      }
      return num
    },
    toText(page, item, pageIdx) {
      this.selectTextId = item.id
      this.$emit('changeSelectList', page, item, pageIdx)
    },
    toTextTwo(page, item, pageIdx) {
      this.selectTextId = item.id
      this.$nextTick(() => {
        let scrollDom = document.getElementsByClassName('text-list-bottom')[0]
        let moveDom = document.getElementById('textArea-list-item-' + item.id)
        if (scrollDom && moveDom) {
          scrollDom.scrollTop = moveDom.offsetTop
        }
      })
      /* this.$emit('changeSelectList', page, item, pageIdx) */
    },
    textDefaultKeyChange() {},
    openTextList() {
      this.textDefaultKey = this.textList.map(page => page.pageId)
    },
    closeTextList() {
      this.textDefaultKey = []
    },
    textSearch(e) {
      this.$emit('searchTextList', { type: 2, keyword: e })
    },
    getTextAreaNum(type) {
      let num = 0
      this.textList.forEach(page => {
        num = page.textAreaCount + num
      })

      return num
    },
    getTextNum(type) {
      let num = 0
      this.textList.forEach(page => {
        num = page.translationWordCount + num
      })
      return num
    },
    changeStages() {
      this.$forceUpdate()
    },
    toStageChange(local, item, itemIndex, option, annotationKind) {
      /*       if (local) {
        if (local.commentData && local.commentData.length) {
          this.$emit('saveLoacal', local, item, itemIndex, option, annotationKind, true)
        }
      } */
    },
    showDiff(file) {
      if (file.isShow) {
        file.isShow = false
      } else {
        file.isShow = true
      }
      this.$emit('showDiff')
      this.$emit('clearSelectedDiffIdxTwo')
      this.$forceUpdate()
    },
    EditorOnsize() {
      this.$nextTick(() => {
        if (this.$refs.localContentInput) {
          this.$refs.localContentInput[0].EditorOnsize()
        }
      })
    },
    getPositionForTextArea(dom) {
      //获取输入框内光标位置
      let CaretPos = {
        start: 0,
        end: 0
      }
      if (dom.selectionStart) {
        // Firefox support
        CaretPos.start = dom.selectionStart
      }
      if (dom.selectionEnd) {
        CaretPos.end = dom.selectionEnd
      }
      return CaretPos
    },
    localEnter(e, item, i) {
      //禁止回车的默认换行
      if (e.keyCode == 13 && e.altKey) {
        e.preventDefault()
        var textarea = e.target
        const position = this.getPositionForTextArea(textarea) //获取光标位置
        if (position.end - position.start == 0) {
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          i.content = textarea.value
        } else {
          const strAry = textarea.value.split('')
          for (let i = position.start; i < position.end; i++) {
            strAry[i] = ''
          }
          textarea.value = strAry.join('')
          textarea.value = textarea.value.slice(0, position.start) + '\r\n' + textarea.value.slice(position.start)
          i.content = textarea.value
        }
        textarea.selectionStart = position.start + 1
        textarea.selectionEnd = position.start + 1
        //增加换行符
      } else if (e.keyCode == 13) {
        e.preventDefault()
        let localValue = i.content
        if (!localValue) {
          this.$message.error('请输入批注内容')
          return
        }
        let type = item.around
        i.editble = 0
        this.$emit('saveOldLocal', { type, item, value: i.content })
        this.$forceUpdate()
      }
    },
    showTextAreaChange() {
      if (this.showTextArea == 'ON') {
        this.$emit('showTextAreaChange', 'OFF')
      } else {
        this.$emit('showTextAreaChange', 'ON')
      }
    },
    showDiffChange() {
      if (this.showDiffs == 'ON') {
        this.$emit('showDiffChange', 'OFF')
      } else {
        this.$emit('showDiffChange', 'ON')
      }
    },
    editLocalContent(i) {
      i.editble = 1
      this.$forceUpdate()
    },
    toLocalOld(item, title) {
      this.selectId = item.id
      this.$emit('toLocalOld', { item, title })
    },
    openAnnotationList() {
      this.leftAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 1
        })
      })
      this.rightAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 1
        })
      })
      this.rightAnnotationOpenKey = this.rightAnnotationList.map((item, index) => index + 'rightOldLocal')
      this.leftAnnotationOpenKey = this.leftAnnotationList.map((item, index) => index + 'oldLocal')
      this.$forceUpdate()
    },
    closeAnnotationList() {
      this.leftAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 0
        })
      })
      this.rightAnnotationList?.forEach(page => {
        page.annotationList?.forEach(item => {
          item.selected = 0
        })
      })
      this.rightAnnotationOpenKey = []
      this.leftAnnotationOpenKey = []
      this.$forceUpdate()
    },
    handleMouseWheel(event) {
      if (this.selectedPlatte) return
      const delta = event.deltaY || event.wheelDelta
      const direction = delta > 0 && this.isWindows ? 1 : -1
      this.$refs['local-radio-top'][0].scrollLeft += direction * 50
    },
    checkPermission(permission) {
      return checkPermission(permission, this.authList)
    },
    changeTranslate() {
      this.isTranslate = !this.isTranslate
    },
    anTranslate(an, page, item) {
      this.$emit('anTranslate', { an, page, item })
    },
    translateAll() {
      this.openAllLocal()
      this.$emit('translateAll')
    },
    radioChange() {
      this.$forceUpdate()
    },
    deletLocal(item) {
      let that = this
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          const res = await deleteAction('/annotation/delete/' + item.id)
          if (res.code == 200) {
            that.$message.success('删除成功')
            that.$emit('getLocalList')
          } else {
            that.$message.error(res.msg)
          }
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    copyMessage() {
      this.$message.success('复制成功')
    },
    getLocalList() {
      if (this.checkPermission('fatchStraw:local:view')) {
        this.hasLocal = true
      } else {
        this.hasLocal = false
      }
      if (!this.changeSelectedTitle) {
        this.showOptions.forEach((showOption, showOptionINdex) => {
          showOption.list.forEach((option, optionIndex) => {
            if (option.isLatest) {
              this.selectedTitle = option.title
              option.localChecked = true
            } else {
              option.localChecked = false
            }
          })
        })
        this.changeSelectedTitle = true
      }
      this.$nextTick(() => {
        if (this.$refs['local-radio-top'] && this.$refs['local-radio-top'][0]) {
          this.$refs['local-radio-top'][0].scrollLeft = 10000
        }
        this.showOptions
          .find(showOption => showOption.list.find(option => option.title == this.selectedTitle))
          .list.find(option => option.title == this.selectedTitle)
          .manuscriptList?.forEach(page => {
            page.annotations
              ?.filter(
                item =>
                  !this.radioValue ||
                  (this.radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                  (this.radioValue == 1 && item.updateStatus == 1)
              )
              .forEach(item => {
                if (this.$refs['localContentInput-' + item.id]) {
                  this.$refs['localContentInput-' + item.id][0].EditorOnsize()
                }
              })
          })
      })
    },
    collapseChange() {
      this.$nextTick(() => {
        this.showOptions
          .find(showOption => showOption.list.find(option => option.title == this.selectedTitle))
          .list.find(option => option.title == this.selectedTitle)
          .manuscriptList?.forEach(page => {
            page.annotations
              ?.filter(
                item =>
                  !this.radioValue ||
                  (this.radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                  (this.radioValue == 1 && item.updateStatus == 1)
              )
              .forEach(item => {
                if (this.$refs['localContentInput-' + item.id] && this.$refs['localContentInput-' + item.id][0]) {
                  this.$refs['localContentInput-' + item.id][0].EditorOnsize()
                }
              })
          })
      })
    },
    changeUpdateStatus(item) {
      if (!this.changeAll) {
        this.$message.error('当前阶段无法操作')
        return
      }
      this.$emit('changeUpdateStatus', item)
    },
    changeMachineStatus(local, item, itemIndex, option, editId, an) {
      if (!this.changeAll) {
        this.$message.error('当前阶段无法操作')
        return
      }
      this.$emit('changeMachineStatus', local, item, itemIndex, option, editId, an)
    },
    cancelErrorEdit(item) {
      item.edit = 0
      item.targetStage = item.saveTargetStage || ''
      this.$emit('changeContentEdit', false)
      this.localValue = ''
      this.$emit('cancelErrorEdit')
      this.$forceUpdate()
    },
    textCopy(e) {
      const text = e.target.parentNode.innerText
      const textareaEle = document.createElement('textarea')
      document.body.appendChild(textareaEle)
      // 2. 将需要复制的文本传入输入框, 并调用 select 方法, 选中输入框中文本
      textareaEle.value = text.replaceAll('\n\n', '\n')
      textareaEle.select()
      textareaEle.readOnly = 'readOnly'
      // 3. 调用复制选中文本的方法
      document.execCommand('copy')
      // 4. 销毁输入框
      document.body.removeChild(textareaEle)
    },
    deleError(local, page) {
      const doms = Array.from(document.getElementsByClassName('local-content-list-content'))
      let imgs = null
      if (doms && doms.length) {
        let imgDoms = []
        doms.forEach(dom => {
          imgDoms = [...imgDoms, ...Array.from(dom.getElementsByTagName('IMG'))]
        })
        if (imgDoms.length) {
          imgs = imgDoms.map(e => e.src)
        }
      }
      this.$confirm({
        content: `确认删除？`,
        zIndex: 9999,
        onOk: () => {
          this.$emit('deleError', local, page)
          this.$emit('closeLocal', imgs)
        }
      })
    },
    changeError(local, item, itemIndex, option) {
      if (!this.localValue && local.commentData && local.commentData.length) {
        return this.$message.error('说明内容不能为空')
      }
      if (local.injuryType && !local.injuryReason) {
        return this.$message.error('错误类型不能为空')
      }
      /*       if (local && local.saveTargetStage) {
        local.targetStage = local.saveTargetStage
      } */
      if (local.commentData && local.commentData.length) {
        local.commentData = [
          {
            content: this.localValue,
            id: local.commentData[0].id,
            userId: this.userInfo.id,
            username: this.userInfo.nickName,
            avatar: this.userInfo.userFile.avatar
          }
        ]
      }
      this.$emit('changeError', local, item, itemIndex, option)
    },
    async changeInjuryType(item, type, id) {
      if (item.injuryType != type) {
        item.injuryReason = undefined
      }
      item.injuryType = type

      /*       let code = !item.injuryType
        ? ''
        : item.injuryType == 1
        ? 'hy-cgrs'
        : item.injuryType == 2
        ? 'hy-cgys'
        : item.injuryType == 4
        ? 'hy-cgcjsy'
        : item.injuryType == 5
        ? 'hy-cgcjrs'
        : ''
      if (this.departName.indexOf('日语部') > -1) {
        if (item.targetStage != '校对') {
          code = !item.injuryType
            ? ''
            : item.injuryType == 1
            ? 'ry-cgrs'
            : item.injuryType == 2
            ? 'ry-cgys'
            : item.injuryType == 4
            ? 'ry-cgcjys'
            : item.injuryType == 5
            ? 'ry-cgcjrs'
            : ''
        } else {
          code = !item.injuryType ? '' : item.injuryType == 1 ? 'ry-rs' : item.injuryType == 2 ? 'ry-ys' : ''
        }
        if (item.targetStage == '监制') {
          code = 'ry-cgxt-zs'
        }
      } else {
        if (item.targetStage == '校对') {
          code = !item.injuryType ? '' : item.injuryType == 1 ? 'hy-rs' : item.injuryType == 2 ? 'hy-ys' : ''
        }
      } */
      if (id) {
        const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
        if (res.code == 200) {
          this.dictList = res.data.records
          this.$forceUpdate()
          if (!this.dictList.length) return this.$message.error('无错误类型数据')
        }
      } else {
        this.dictList = []
      }
    },
    anDele(local, deleId) {
      let that = this
      const doms = Array.from(document.getElementsByClassName('local-content-list-content'))
      let imgs = null
      if (doms && doms.length) {
        let imgDoms = []
        doms.forEach(dom => {
          imgDoms = [...imgDoms, ...Array.from(dom.getElementsByTagName('IMG'))]
        })
        if (imgDoms.length) {
          imgs = imgDoms.map(e => e.src)
        }
      }
      this.$confirm({
        title: '确认删除?',
        okText: '确认',
        cancelText: '取消',
        zIndex: 9999,
        async onOk() {
          that.$emit('deleContentLoacal', local, deleId)
          that.$emit('closeLocal', imgs)
        },
        onCancel() {
          console.log('Cancel')
        }
      })
    },
    saveLoacal(local, item, itemIndex, option) {
      this.$emit('saveContentLoacal', local, item, itemIndex, option)
    },
    saveLoacalTwo(local, item, itemIndex, option) {
      this.localValue = local.localValue
      this.$emit('saveContentLoacal', local, item, itemIndex, option)
    },
    editLoacal(local, item, itemIndex, option, editId, an) {
      if (an.content) this.localValue = an.content
      this.$emit('saveContentLoacal', local, item, itemIndex, option, editId)
    },
    editTranslate(local, item, itemIndex, option, editId, an) {
      this.$emit('saveContentLoacalTranslate', local, item, itemIndex, option, editId, an)
    },
    editError(item) {
      item.edit = 1
      this.$emit('changeContentEdit', true)
      this.targetStage = item.targetStage
      item.saveTargetStage = item.targetStage
      if (item.commentData && item.commentData.length) {
        let timeOut = setTimeout(() => {
          if (this.$refs.localContentInput && this.$refs.localContentInput[0]) {
            this.$refs.localContentInput[0].EditorOnsize()
            this.localValue = item.commentData[0].content
            clearTimeout(timeOut)
          }
        }, 500)
      }
      let id = null
      if (
        this.toStagesAnnotation.find(stage => stage.stageName == item.targetStage) &&
        this.toStagesAnnotation.find(stage => stage.stageName == item.targetStage).errorDict
      ) {
        if (item.injuryType) {
          let data = this.toStagesAnnotation
            .find(stage => stage.stageName == item.targetStage)
            .errorDict.find(error => error.type == item.injuryType)
          if (data) {
            id = data.id
          }
        }
      }
      this.changeInjuryType(item, item.injuryType || 0, id)
      this.$forceUpdate()
    },
    translateEdit(an) {
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          option.manuscriptList?.forEach(page => {
            page.annotations?.forEach(an => {
              an.commentData?.forEach(a => {
                a.edit = 0
              })
            })
          })
        })
      })
      an.edit = 1
      this.$forceUpdate()
    },
    anEdit(an) {
      an.edit = 1
      this.$forceUpdate()
      let timeOut = setTimeout(() => {
        if (this.$refs.localContentInput && this.$refs.localContentInput[0]) {
          this.$refs.localContentInput[0].EditorOnsize()
          this.localValue = an.content
          clearTimeout(timeOut)
        }
      }, 500)
    },
    editorOnsize() {
      if (this.$refs.localContentInput && this.$refs.localContentInput[0]) {
        this.$refs.localContentInput[0].EditorOnsize()
      }
    },
    openAllDiff() {
      this.activeKey = []
      this.diffs.forEach((diff, index) => {
        this.activeKey.push('diff-' + index)
      })
    },
    closeAllDiff() {
      this.activeKey = []
    },
    closeAllLocal() {
      this.reDefaultKey = []
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          if (option.localChecked) {
            option.manuscriptList?.forEach(page => {
              page.annotations?.forEach(an => {
                an.selected = 0
              })
            })
          }
        })
      })
    },
    openAllLocal() {
      this.reDefaultKey = []
      this.showOptions
        .find(showOption => showOption.list.find(option => option.title == this.selectedTitle))
        .list.find(option => option.title == this.selectedTitle)
        .manuscriptList.filter(
          page =>
            page.annotations &&
            page.annotations.filter(
              item =>
                !this.radioValue ||
                (this.radioValue == 2 && (item.updateStatus == 2 || !item.updateStatus)) ||
                (this.radioValue == 1 && item.updateStatus == 1)
            ).length
        )
        .forEach(page => {
          this.reDefaultKey.push(page.id + '')
        })
      this.showOptions.forEach(showOption => {
        showOption.list?.forEach(option => {
          if (option.localChecked) {
            option.manuscriptList?.forEach(page => {
              page.annotations?.forEach(an => {
                an.selected = 1
              })
            })
          }
        })
      })
    },
    goToDiff(item, file, index, i) {
      this.$emit('changeSelectDiff', { item, file, index, i })
    },
    async toLocal(page, item, pageIdx) {
      this.$emit('toLocal', { page, item, pageIdx, selectedTitle: this.selectedTitle })
      if (!item.edit) {
        let timeOut = setTimeout(() => {
          if (this.$refs.localContentInput && this.$refs.localContentInput[0]) {
            this.localValue = ''
            this.$refs.localContentInput[0].EditorOnsize()
            clearTimeout(timeOut)
          }
        }, 300)
      }

      let code = !item.injuryType
        ? ''
        : item.injuryType == 1
        ? 'hy-cgrs'
        : item.injuryType == 2
        ? 'hy-cgys'
        : item.injuryType == 4
        ? 'hy-cgcjsy'
        : item.injuryType == 5
        ? 'hy-cgcjrs'
        : ''
      if (this.departName.indexOf('日语部') > -1) {
        if (item.targetStage != '校对') {
          code = !item.injuryType
            ? ''
            : item.injuryType == 1
            ? 'ry-cgrs'
            : item.injuryType == 2
            ? 'ry-cgys'
            : item.injuryType == 4
            ? 'ry-cgcjys'
            : item.injuryType == 5
            ? 'ry-cgcjrs'
            : ''
        } else {
          code = !item.injuryType ? '' : item.injuryType == 1 ? 'ry-rs' : item.injuryType == 2 ? 'ry-ys' : ''
        }
        if (item.targetStage == '监制') {
          code = 'ry-cgxt-zs'
        }
      } else {
        if (item.targetStage == '校对') {
          code = !item.injuryType ? '' : item.injuryType == 1 ? 'hy-rs' : item.injuryType == 2 ? 'hy-ys' : ''
        }
      }
      if (!code) return
      let id = this.errorDictList.find(item => item.dictCode == code).id
      const res = await getAction('/sys/dictItem/list', { pageNo: 1, pageSize: 50, dictId: id })
      if (res.code == 200) {
        this.dictList = res.data.records.filter(r => r.status)
      }
    },
    changeSelectOption(option) {
      this.showOptions.forEach(showOption => {
        if (showOption.list.some(o => o.title == option.title)) {
          showOption.list.forEach(o => {
            if (o.title == option.title) {
              o.checked = true
              o.localChecked = true
              this.selectedTitle = option.title
            } else {
              o.checked = false
              o.localChecked = false
            }
          })
        }
      })
      this.allOption.forEach(o => {
        if (o.title == option.title) {
          o.localChecked = true
        } else {
          o.localChecked = false
        }
      })
      /*       const timeOut = setTimeout(() => {
        this.$emit('getShowImgListPostions')
        this.$emit('getPageListPostions')
        clearTimeout(timeOut)
      }, 300) */
      this.$emit('changeOption', option.value)
      /* this.$emit('pageOptionChange',option.title) */
    },
    mousedown(e, item) {
      if (this.noDrag || item.isPushpin) return
      if (e.target.className == 'drag-content-title') {
        this.$emit('changeContentDarg', e, item)
      } else if (e.target.className == 'drag-content-title-span') {
        this.$emit('changeContentDarg', { target: e.target.parentNode }, item)
      }
    },
    changeAllheight(item) {
      item.allHeight = !item.allHeight
    },
    closeContentList(e, item) {
      this.$emit('closeContentDarg', e, item)
    },
    topItem(item) {
      const contentList = JSON.parse(JSON.stringify(this.contentList))
      if (
        contentList.sort((a, b) => {
          return b.zIndex - a.zIndex
        })[0] == 999
      ) {
        item.zIndex++
      } else {
        item.zIndex =
          contentList.sort((a, b) => {
            return b.zIndex - a.zIndex
          })[0].zIndex + 1
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .liuyan span:last-child {
  padding-left: 4px !important;
}
/deep/ .show-local-content {
  p {
    white-space: pre-line;
    word-wrap: break-word;
  }
  max-width: 240px;
  img {
    max-width: 100px !important;
  }
}
.open-all-annotation-list {
  position: absolute;
  line-height: 22px;
  right: 16px;
  top: 12px;
  z-index: 1;
}
/deep/ .diff-show .ant-collapse-content-box {
  padding-bottom: 0px !important;
}
.diff-show {
  > div {
    position: relative;
    .open-all {
      position: absolute;
      right: 0;
      top: 12px;
      z-index: 1;
    }
  }
}
.space-text {
  white-space: pre-line;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}
/deep/ .diff-item {
  cursor: pointer;
  position: relative;
  left: -5px;
  padding-left: 5px;
  &:hover {
    color: var(--theme-color) !important;
    background-color: #f7f7f7 !important;
    padding: 5px;
  }
}
.diff-item-select {
  color: var(--theme-color) !important;
  background-color: #f7f7f7 !important;
  padding: 5px;
}
.local-content-list-none {
  padding-top: 4px;
  padding-bottom: 0;
  padding-left: 37px;
  .local-content-list-none-top {
    display: flex;
    justify-content: space-between;
    height: 22px;
  }
  .local-content-list-none-bottom {
    padding-top: 10px;
    display: flex;
    align-items: center;
    .anticon-more {
      display: inline-block !important;
    }
  }
}
.local-content-list-num {
  position: absolute;
  left: -8px;
  width: 50px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  > div {
    background-color: black;
    margin-left: 15px;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 10px;
    text-align: center;
  }
}
.local-content {
  overflow-y: auto;
  position: relative;
  .open-all {
    position: absolute;
    right: 3px;
    top: 12px;
    z-index: 1;
  }
}
.diff-show {
  padding: 12px 24px;
}
.text-list-show {
  padding: 12px 24px;
  .text-list-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .text-list-bottom {
    overflow-y: auto !important;
    max-height: 400px !important;
    position: relative;
    .local-content-list-show {
      line-height: 24px;
      display: flex;
      cursor: pointer;
      margin-bottom: 5px;
      padding-right: 5px;
      margin-top: 5px;
      padding-top: 5px;
      margin-left: 30px;
      .anticon-more {
        display: none;
        transition: 0.3s all;
        &:hover {
          color: var(--theme-color);
          .anticon-copy {
            display: inline-block;
          }
        }
      }
      .local-content-list-num {
        position: absolute;
        left: -8px;
        width: 50px;
        display: flex;
        justify-content: center;
        padding-top: 5px;
        > div {
          background-color: black;
          margin-left: 15px;
          color: #fff;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 12px;
          border-radius: 10px;
          text-align: center;
        }
      }
      .local-content-list {
        max-width: 315px;
        padding-left: 5px;
        flex: 1;

        .local-content-list-user {
          display: flex;
          .local-content-list-user-left {
            width: 36px;
            height: 36px;
            display: flex;
            img {
              width: 22px;
              height: 22px;
              border-radius: 50%;
              position: relative;
              top: 4px;
            }
          }
          .local-content-list-user-right {
            display: flex;
            flex: 1;
            justify-content: space-between;
            .local-content-list-user-right-left {
              color: rgba(0, 0, 0, 0.55);
              font-size: 12px;
              padding-top: 6px;
            }
          }
        }
        .local-content-list-error {
          padding-left: 36px;
          .local-content-list-error-top {
            display: flex;
            justify-content: space-between;
            padding: 2px 0;
          }
          .local-content-list-error-bottom {
            padding: 2px 0;
            color: rgba(0, 0, 0, 0.55);
            .local-item-action-radio {
              font-size: 14px;
              .error-span {
                background-color: #fff;
                position: relative;
                .anticon-check-circle {
                  position: absolute;
                  top: -2px;
                  right: -4px;
                  font-size: 10px;
                  color: var(--theme-color);
                }
              }
              > span {
                display: inline-block !important;
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                cursor: pointer;
                &:hover {
                  background-color: #fff;
                }
              }
            }
            .local-item-action-input {
              padding-top: 10px;
            }
          }
        }
        .local-content-list-content {
          /* padding-left: 36px; */
          .local-content-list-content-title {
            font-weight: 700;
            padding: 2px 0;
            display: flex;
            justify-content: space-between;
          }
          .local-content-list-content-top {
            display: flex;
            .local-content-list-content-left {
              width: 25px;
              height: 25px;
              display: flex;
              align-items: center;
              img {
                width: 22px;
                height: 22px;
                border-radius: 50%;
              }
            }
            .local-content-list-content-right {
              flex: 1;
              display: flex;
              justify-content: space-between;
              .local-content-list-content-right-left {
                color: rgba(0, 0, 0, 0.55);
                font-size: 12px;
                display: flex;
                align-items: center;
              }
            }
          }
          .local-content-list-content-bottom {
            /*  display: flex; */
            padding-left: 25px;
          }
        }
      }
      &:hover {
        background-color: #f7f7f7 !important;
        .anticon-more {
          display: inline-block;
        }
      }
    }
    .local-content-list-show-selected {
      background-color: #f7f7f7 !important;
      .anticon-copy {
        display: inline-block;
      }
    }
    .local-content-list-show {
      &:hover {
        .anticon-copy {
          display: inline-block;
        }
      }
    }
  }
}
.change-sign-over {
  color: var(--theme-color);
}
.change-sign:hover {
  cursor: pointer;
  transition: all 0.3s;
  color: var(--theme-color);
}
.local-radio-top::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
.local-radio-top::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
  height: 3px;
}
.local-radio-top:-webkit-scrollbar-track {
  border-radius: 0;
  background-color: #fff;
}
.local-radio-top::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.local-radio-top::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.local-show {
  padding: 12px 24px;
  .anticon-more {
    font-size: 16px;
  }
  .local-radio {
    width: 100%;
    position: relative;
    .local-radio-top {
      overflow-x: auto;
      overflow-y: hidden;

      width: 86%;
      .translate-icon {
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 7px;
      }
      .translate-change-icon {
        position: absolute;
        cursor: pointer;
        right: 30px;
        font-size: 16px;
        top: 8px;
        cursor: pointer;
        &:hover {
          color: var(--theme-color);
        }
      }
      > div {
        display: flex;
        position: relative;
        width: max-content;
        > div {
          display: flex;
          .local-radio-item {
            height: 35px;
            padding-left: 5px;
            padding-right: 5px;
            display: flex;
            align-items: center;
            text-align: center;
            background-color: #f2f3f5;
            justify-content: center;
            transition: 0.3s all;
            &:hover {
              color: var(--theme-color);
            }
            > div {
              /* width: 95%; */
              padding-left: 5px;
              padding-right: 5px;
              text-align: center;
              height: 25px;
              line-height: 25px;
              border-radius: 4px;
              cursor: pointer;
            }
          }
          .local-radio-item-selected {
            color: var(--theme-color);
            > div {
              /* width: 95%; */
              padding-left: 5px;
              padding-right: 5px;
              text-align: center;
              height: 25px;
              line-height: 25px;
              border-radius: 4px;
              cursor: pointer;
              background-color: #fff;
            }
          }
        }
      }
    }
    .local-radio-bottom {
      padding: 12px 0px;
    }
  }
  .local-content-list {
    max-width: 315px;
    padding-left: 5px;
    flex: 1;

    .local-content-list-user {
      display: flex;
      .local-content-list-user-left {
        width: 36px;
        height: 36px;
        display: flex;
        img {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          position: relative;
          top: 4px;
        }
      }
      .local-content-list-user-right {
        display: flex;
        flex: 1;
        justify-content: space-between;
        .local-content-list-user-right-left {
          color: rgba(0, 0, 0, 0.55);
          font-size: 12px;
          padding-top: 6px;
        }
      }
    }
    .local-content-list-error {
      padding-left: 36px;
      .local-content-list-error-top {
        display: flex;
        justify-content: space-between;
        padding: 2px 0;
      }
      .local-content-list-error-bottom {
        padding: 2px 0;
        color: rgba(0, 0, 0, 0.55);
        .local-item-action-radio {
          font-size: 14px;
          .error-span {
            background-color: #fff;
            position: relative;
            .anticon-check-circle {
              position: absolute;
              top: -2px;
              right: -4px;
              font-size: 10px;
              color: var(--theme-color);
            }
          }
          > span {
            display: inline-block !important;
            width: 25px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            cursor: pointer;
            &:hover {
              background-color: #fff;
            }
          }
        }
        .local-item-action-input {
          padding-top: 10px;
        }
      }
    }
    .local-content-list-content {
      /* padding-left: 36px; */
      .local-content-list-content-title {
        font-weight: 700;
        padding: 2px 0;
        display: flex;
        justify-content: space-between;
      }
      .local-content-list-content-top {
        display: flex;
        .local-content-list-content-left {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          img {
            width: 22px;
            height: 22px;
            border-radius: 50%;
          }
        }
        .local-content-list-content-right {
          flex: 1;
          display: flex;
          justify-content: space-between;
          .local-content-list-content-right-left {
            color: rgba(0, 0, 0, 0.55);
            font-size: 12px;
            display: flex;
            align-items: center;
          }
        }
      }
      .local-content-list-content-bottom {
        /*  display: flex; */
        padding-left: 25px;
      }
    }
  }
  .local-content-list-show {
    display: flex;
    cursor: pointer;
    margin-bottom: 5px;
    padding-right: 5px;
    margin-top: 5px;
    padding-top: 5px;
    margin-left: 30px;
    .anticon-more {
      display: none;
      transition: 0.3s all;
      &:hover {
        color: var(--theme-color);
      }
    }
    &:hover {
      background-color: #f7f7f7 !important;
      .anticon-copy {
        display: inline-block;
      }
      .anticon-more {
        display: inline-block;
      }
    }
  }
  .local-content-list-show-selected {
    background-color: #f7f7f7 !important;
    .anticon-copy {
      display: inline-block;
    }
  }
}
.local-item-action-input-button {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: end;
  .ant-btn {
    font-size: 12px !important;
  }
}
.drag-content-list {
  width: 400px;
  min-height: 36px;
  box-sizing: border-box;
  position: absolute;
  box-shadow: 2px 1px 6px 2px rgba(0, 0, 0, 0.2);
  background: #fff;
  z-index: 999;
  border-radius: 5px;
  height: max-content;
  .drag-content-title {
    font-size: 14px;
    background-color: #f2f2f2;
    padding: 2px 12px 2px 12px;
    line-height: 32px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon-redo,
    .anticon-vertical-align-middle,
    .anticon-column-height,
    .anticon-close,
    .anticon-vertical-align-top,
    .anticon-vertical-align-bottom {
      cursor: pointer;
      &:hover {
        color: var(--theme-color);
      }
    }
  }
}
.drag-content-list-local {
  width: 400px; //陈立喊改的样式
}
::-webkit-scrollbar-button {
  height: 0;
}
::-webkit-scrollbar {
  border-radius: 0;
  position: relative;
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  background: #fff;
}
::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
.local-content-list-show-old {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 5px;
  &:hover {
    background-color: #f7f7f7 !important;
  }
  .local-content-list-num-two {
    width: 50px;
    display: flex;
    justify-content: center;
    padding-top: 12px;
    padding-bottom: 40px;
    > div {
      background-color: black;
      margin-left: 15px;
      color: #fff;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      border-radius: 50%;
      text-align: center;
    }
  }
  .local-content-list-old {
    max-width: 315px;
    padding: 5px;
    padding-right: 15px;
    flex: 1;
    .local-content-item {
      &:hover {
        .action-span {
          display: inline-block !important;
        }
      }
      .local-content-item-top {
        height: 36px;
        display: flex;
        .local-content-item-top-left {
          width: 20px;
          height: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
        .local-content-item-top-right {
          font-size: 12px;
          margin-left: 5px;
          width: 100%;
          > div {
            line-height: 18px;
          }
          .action-span {
            display: none;
            transition: all 0.3s;
            &:hover {
              color: var(--theme-color);
            }
          }
          .anticon-more {
            font-size: 16px;
          }
          .action-span-select {
            display: inline-block;
          }
          .local-content-item-top-right-user {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      .local-content-item-bottom {
        min-height: 35px;
        font-size: 14px;
        line-height: 25px;
        padding-left: 25px;
        color: black;
        > span {
          white-space: pre-line;
          display: inline-block;
          width: 100%;
          word-wrap: break-word;
        }
        .ant-input {
          border: none;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
    .revisition-content {
      padding-bottom: 5px;
      color: black;
      > div {
        > span {
          line-height: 20px;
        }
      }
      .ocr-show-item-action-top {
        padding-top: 5px;
        .ocr-show-item-action-radio {
          font-size: 14px;
          padding-left: 6px;
          .error-span {
            background-color: #fff;
            position: relative;
            .anticon-check-circle {
              position: absolute;
              top: -2px;
              right: -4px;
              font-size: 10px;
              color: var(--theme-color);
            }
          }
          > span {
            display: inline-block !important;
            width: 25px;
            height: 25px;
            text-align: center;
            cursor: pointer;
            &:hover {
              background-color: #fff;
            }
          }
        }
        .ocr-show-item-action-input {
          padding-top: 10px;
          > .ocr-show-item-action-input-button {
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            justify-content: end;
            .ant-btn {
              font-size: 12px !important;
            }
          }
        }
        /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
      }
      .revisition-content-error {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
          .action-span {
            display: inline-block !important;
          }
        }
        .action-span {
          display: none;
          transition: all 0.3s;
          &:hover {
            color: var(--theme-color) !important;
          }
        }
        .anticon-more {
          font-size: 16px;
        }
        .action-span-select {
          display: inline-block;
          &:hover {
            color: var(--theme-color) !important;
          }
        }
      }
    }
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }
}
.anticon-more {
  font-size: 16px;
  &:hover {
    color: var(--theme-color) !important;
  }
}
.anticon-copy {
  cursor: pointer;
  margin-left: 3px;
  transition: 0.3s color;
  display: none;
  &:hover {
    color: var(--theme-color);
  }
}
.tinymce-editor {
  max-width: 255px !important;
  overflow: hidden !important;
}
.huifu .tinymce-editor {
  max-width: 290px !important;
}
.local-content-list-show-selected .anticon-more {
  display: inline-block !important;
}
</style>
