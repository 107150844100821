var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-list-view" },
    [
      _vm._l(_vm.contentList, function (item) {
        return [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: item.visible,
                  expression: "item.visible",
                },
              ],
              key: item.id,
              class:
                item.id == 2
                  ? "drag-content-list drag-content-list-local"
                  : "drag-content-list",
              style:
                "order:" +
                item.order +
                ";z-index:" +
                item.zIndex +
                ";top:" +
                (item.mouseY - 20) +
                "px" +
                (item.mouseX ? `;left:${item.mouseX - 20}px` : ";right:130px") +
                `${item.isOpacity ? ";opacity:0.5" : ""}` +
                `${item.isSort ? ";border:1px dashed " + _vm.color : ""}`,
              attrs: { id: "content-list-" + item.id },
            },
            [
              _c(
                "div",
                {
                  staticClass: "drag-content-title",
                  on: {
                    mousedown: (e) => {
                      _vm.mousedown(e, item)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "drag-content-title-span" }, [
                    _vm._v(
                      _vm._s(item.title) +
                        _vm._s(item.id == 1 ? `（${_vm.diffsTotal}）` : "")
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "pushpin-icon",
                      on: {
                        mousemove: function ($event) {
                          _vm.noDrag = true
                        },
                        mouseout: function ($event) {
                          _vm.noDrag = false
                        },
                      },
                    },
                    [
                      item.id == 3
                        ? [
                            _vm.showTextArea == "ON"
                              ? _c("a-icon", {
                                  style: { fontSize: "16px" },
                                  attrs: { type: "eye" },
                                  on: { click: _vm.showTextAreaChange },
                                })
                              : _c("a-icon", {
                                  style: { fontSize: "16px" },
                                  attrs: { type: "eye-invisible" },
                                  on: { click: _vm.showTextAreaChange },
                                }),
                          ]
                        : _vm._e(),
                      item.id == 1
                        ? [
                            _vm.showDiffs == "ON"
                              ? _c("a-icon", {
                                  style: { fontSize: "16px" },
                                  attrs: { type: "eye" },
                                  on: { click: _vm.showDiffChange },
                                })
                              : _c("a-icon", {
                                  style: { fontSize: "16px" },
                                  attrs: { type: "eye-invisible" },
                                  on: { click: _vm.showDiffChange },
                                }),
                          ]
                        : _vm._e(),
                      item.showContent
                        ? _c("a-icon", {
                            staticStyle: {
                              "margin-left": "6px",
                              "font-size": "16px",
                            },
                            attrs: { type: "vertical-align-top" },
                            on: {
                              click: function ($event) {
                                item.showContent = false
                              },
                            },
                          })
                        : !item.showContent
                        ? _c("a-icon", {
                            staticStyle: {
                              "margin-left": "6px",
                              "font-size": "16px",
                            },
                            attrs: { type: "vertical-align-bottom" },
                            on: {
                              click: function ($event) {
                                item.showContent = true
                              },
                            },
                          })
                        : _vm._e(),
                      _c("pushpin", {
                        staticStyle: {
                          "margin-left": "6px",
                          cursor: "pointer",
                        },
                        attrs: {
                          theme: "outline",
                          size: "16",
                          fill: item.isPushpin ? _vm.color : item.pushpinColor,
                          strokeWidth: 3,
                        },
                        on: {
                          mouseover: function ($event) {
                            item.pushpinColor = _vm.color
                          },
                          mouseout: function ($event) {
                            item.pushpinColor = "#4a4a4a"
                          },
                          click: function ($event) {
                            item.isPushpin = !item.isPushpin
                          },
                        },
                      }),
                      !item.allHeight
                        ? _c("a-icon", {
                            staticStyle: { "margin-left": "6px" },
                            attrs: { type: "column-height" },
                            on: {
                              click: function ($event) {
                                return _vm.changeAllheight(item)
                              },
                            },
                          })
                        : _c("a-icon", {
                            staticStyle: { "margin-left": "6px" },
                            attrs: { type: "vertical-align-middle" },
                            on: {
                              click: function ($event) {
                                return _vm.changeAllheight(item)
                              },
                            },
                          }),
                      _c("a-icon", {
                        staticStyle: { "margin-left": "6px" },
                        attrs: { type: "close" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return ((e) => _vm.closeContentList(e, item)).apply(
                              null,
                              arguments
                            )
                          },
                        },
                      }),
                    ],
                    2
                  ),
                ]
              ),
              item.showContent
                ? _c(
                    "div",
                    {
                      staticClass: "drag-content-show",
                      style: item.allHeight
                        ? { height: _vm.bottomHeight - 80 + "px" }
                        : "",
                      on: {
                        click: function ($event) {
                          return _vm.topItem(item)
                        },
                      },
                    },
                    [
                      item.id == 2
                        ? _c("div", { staticClass: "local-show" }, [
                            _c("div", { staticClass: "local-radio" }, [
                              _c(
                                "div",
                                {
                                  ref: "local-radio-top",
                                  refInFor: true,
                                  staticClass: "local-radio-top",
                                  on: { mousewheel: _vm.handleMouseWheel },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      _vm._l(
                                        _vm.allOption.filter(
                                          (option) =>
                                            option.title != "原稿" &&
                                            option.title != "原稿JPG"
                                        ),
                                        function (option, optionIndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: option.title,
                                              class: option.localChecked
                                                ? "local-radio-item local-radio-item-selected"
                                                : "local-radio-item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.changeSelectOption(
                                                    option
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  style: {
                                                    borderRight:
                                                      optionIndex ==
                                                      _vm.allOption.length - 1
                                                        ? ""
                                                        : "1px solid rgba(0,0,0,0.1)",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(option.title) +
                                                      _vm._s(
                                                        _vm.getLoacalTotal(
                                                          option.title
                                                        )
                                                          ? `(${_vm.getLoacalTotal(
                                                              option.title
                                                            )})`
                                                          : ""
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _vm.isTranslate && _vm.changeAll
                                    ? _c(
                                        "a-popover",
                                        {
                                          attrs: {
                                            placement: "bottomLeft",
                                            title: false,
                                            trigger: "click",
                                            transitionName: "",
                                            overlayStyle: { zIndex: 9999 },
                                          },
                                          model: {
                                            value: _vm.translateVisible,
                                            callback: function ($$v) {
                                              _vm.translateVisible = $$v
                                            },
                                            expression: "translateVisible",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              attrs: { slot: "content" },
                                              slot: "content",
                                            },
                                            [
                                              _vm.isTranslate && _vm.changeAll
                                                ? _c(
                                                    "a-select",
                                                    {
                                                      staticStyle: {
                                                        width: "150px",
                                                        "margin-right": "5px",
                                                      },
                                                      attrs: {
                                                        showSearch: "",
                                                        allowClear: "",
                                                        showArrow: "",
                                                        placeholder:
                                                          "请选择源语种",
                                                        dropdownStyle: {
                                                          zIndex: 9999,
                                                        },
                                                        "option-filter-prop":
                                                          "label",
                                                      },
                                                      model: {
                                                        value: _vm.sourceLang,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.sourceLang = $$v
                                                        },
                                                        expression:
                                                          "sourceLang",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "自动检测",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      自动检测\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "中文",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      中文\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "韩语",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      한국어\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "日语",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      日本語\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "英语",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      ENGLISH\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("a-icon", {
                                                attrs: { type: "arrow-right" },
                                              }),
                                              _vm.isTranslate && _vm.changeAll
                                                ? _c(
                                                    "a-select",
                                                    {
                                                      staticStyle: {
                                                        width: "150px",
                                                        "margin-left": "5px",
                                                      },
                                                      attrs: {
                                                        showSearch: "",
                                                        allowClear: "",
                                                        showArrow: "",
                                                        placeholder: "请选择",
                                                        dropdownStyle: {
                                                          zIndex: 9999,
                                                        },
                                                        "option-filter-prop":
                                                          "label",
                                                      },
                                                      model: {
                                                        value: _vm.targetLang,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.targetLang = $$v
                                                        },
                                                        expression:
                                                          "targetLang",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "中文",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      中文\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "韩语",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      한국어\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "日语",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      日本語\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "a-select-option",
                                                        {
                                                          attrs: {
                                                            value: "英语",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      ENGLISH\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "a-button",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "15px",
                                                  },
                                                  attrs: {
                                                    type: "primary",
                                                    loading:
                                                      _vm.translateLoading,
                                                  },
                                                  on: {
                                                    click: _vm.translateAll,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    翻译\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "translate-icon",
                                              style: "right:25px",
                                              on: {
                                                mouseover: function ($event) {
                                                  _vm.translateColor = _vm.color
                                                },
                                                mouseout: function ($event) {
                                                  _vm.translateColor = "#4a4a4a"
                                                },
                                              },
                                            },
                                            [
                                              _c("translate", {
                                                attrs: {
                                                  theme: "outline",
                                                  size: "21",
                                                  fill: _vm.translateColor,
                                                  strokeWidth: 3,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.checkPermission(
                                    "fatchStraw:localList:change"
                                  )
                                    ? _c("a-icon", {
                                        staticClass: "translate-change-icon",
                                        style: "right:0",
                                        attrs: { type: "swap" },
                                        on: { click: _vm.changeTranslate },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.selectedTitle != "原稿" &&
                              _vm.selectedTitle != "原稿JPG" &&
                              _vm.selectedTitle != "实时" &&
                              !_vm.isTranslate
                                ? _c(
                                    "div",
                                    { staticClass: "local-radio-bottom" },
                                    [
                                      _vm.contentToStages.length &&
                                      _vm.taskDetail.departName.indexOf(
                                        "原创部"
                                      ) == -1
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-bottom": "12px",
                                              },
                                            },
                                            [
                                              _c("a-checkbox-group", {
                                                attrs: {
                                                  options:
                                                    _vm.contentToStages.map(
                                                      (stage) => {
                                                        return {
                                                          label:
                                                            stage.stageName ||
                                                            "",
                                                          value:
                                                            stage.stageName ||
                                                            "",
                                                        }
                                                      }
                                                    ),
                                                },
                                                on: {
                                                  change: _vm.changeStages,
                                                },
                                                model: {
                                                  value: _vm.checkedStages,
                                                  callback: function ($$v) {
                                                    _vm.checkedStages = $$v
                                                  },
                                                  expression: "checkedStages",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.checkPermission(
                                        "fatchStraw:local:view"
                                      ) &&
                                      _vm.taskDetail.departName.indexOf(
                                        "原创部"
                                      ) == -1
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-bottom": "12px",
                                              },
                                            },
                                            [
                                              _c(
                                                "a-checkbox",
                                                {
                                                  staticClass: "liuyan",
                                                  model: {
                                                    value: _vm.hasLocal,
                                                    callback: function ($$v) {
                                                      _vm.hasLocal = $$v
                                                    },
                                                    expression: "hasLocal",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  留言\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-radio-group",
                                        {
                                          on: { change: _vm.radioChange },
                                          model: {
                                            value: _vm.radioValue,
                                            callback: function ($$v) {
                                              _vm.radioValue = $$v
                                            },
                                            expression: "radioValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 0 } },
                                            [
                                              _vm._v(
                                                "\n                  全部\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: { value: 1 },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  已解决\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: { value: 2 },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  未解决\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm.selectedTitle != "原稿" &&
                                  _vm.selectedTitle != "原稿JPG" &&
                                  _vm.selectedTitle != "实时" &&
                                  _vm.isTranslate
                                ? _c(
                                    "div",
                                    { staticClass: "local-radio-bottom" },
                                    [
                                      _vm.contentToStages.length &&
                                      _vm.taskDetail.departName.indexOf(
                                        "原创部"
                                      ) == -1
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-bottom": "12px",
                                              },
                                            },
                                            [
                                              _c("a-checkbox-group", {
                                                attrs: {
                                                  options:
                                                    _vm.contentToStages.map(
                                                      (stage) => {
                                                        return {
                                                          label:
                                                            stage.stageName,
                                                          value:
                                                            stage.stageName,
                                                        }
                                                      }
                                                    ),
                                                },
                                                on: {
                                                  change: _vm.changeStages,
                                                },
                                                model: {
                                                  value: _vm.checkedStages,
                                                  callback: function ($$v) {
                                                    _vm.checkedStages = $$v
                                                  },
                                                  expression: "checkedStages",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "a-radio-group",
                                        {
                                          on: { change: _vm.radioChange },
                                          model: {
                                            value: _vm.radioValue,
                                            callback: function ($$v) {
                                              _vm.radioValue = $$v
                                            },
                                            expression: "radioValue",
                                          },
                                        },
                                        [
                                          _c(
                                            "a-radio",
                                            { attrs: { value: 0 } },
                                            [
                                              _vm._v(
                                                "\n                  全部\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: { value: 1 },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  已校对\n                "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "a-radio",
                                            {
                                              staticStyle: {
                                                "margin-left": "5px",
                                              },
                                              attrs: { value: 2 },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  未校对\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            !_vm.isTranslate
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "local-content",
                                    style: item.allHeight
                                      ? {
                                          maxHeight:
                                            _vm.bottomHeight -
                                            270 +
                                            "px !important",
                                          position: "relative",
                                          left: "-16px",
                                          width: "calc(100% + 14px)",
                                        }
                                      : "max-height: 400px;position:relative;left:-16px;width:calc(100% + 14px);",
                                  },
                                  [
                                    _vm.selectedTitle != "原稿" &&
                                    _vm.selectedTitle != "原稿JPG" &&
                                    _vm.selectedTitle != "实时"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "open-all" },
                                              [
                                                _vm.reDefaultKey.length <
                                                _vm.showOptions
                                                  .find((showOption) =>
                                                    showOption.list.find(
                                                      (option) =>
                                                        option.title ==
                                                        _vm.selectedTitle
                                                    )
                                                  )
                                                  .list.find(
                                                    (option) =>
                                                      option.title ==
                                                      _vm.selectedTitle
                                                  )
                                                  .manuscriptList.filter(
                                                    (page) =>
                                                      page.annotations &&
                                                      page.annotations.filter(
                                                        (item) =>
                                                          !_vm.radioValue ||
                                                          (_vm.radioValue ==
                                                            2 &&
                                                            (item.updateStatus ==
                                                              2 ||
                                                              !item.updateStatus)) ||
                                                          (_vm.radioValue ==
                                                            1 &&
                                                            item.updateStatus ==
                                                              1)
                                                      ).length
                                                  ).length
                                                  ? _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.openAllLocal.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("全展开/收起")]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.closeAllLocal.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("全展开/收起")]
                                                    ),
                                              ]
                                            ),
                                            _vm.showOptions
                                              .find((showOption) =>
                                                showOption.list.find(
                                                  (option) =>
                                                    option.title ==
                                                    _vm.selectedTitle
                                                )
                                              )
                                              .list.find(
                                                (option) =>
                                                  option.title ==
                                                  _vm.selectedTitle
                                              ).manuscriptList &&
                                            _vm.showOptions
                                              .find((showOption) =>
                                                showOption.list.find(
                                                  (option) =>
                                                    option.title ==
                                                    _vm.selectedTitle
                                                )
                                              )
                                              .list.find(
                                                (option) =>
                                                  option.title ==
                                                  _vm.selectedTitle
                                              ).manuscriptList.length
                                              ? _c(
                                                  "a-collapse",
                                                  {
                                                    attrs: { bordered: false },
                                                    on: {
                                                      change:
                                                        _vm.collapseChange,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "expandIcon",
                                                          fn: function (props) {
                                                            return [
                                                              _c("a-icon", {
                                                                attrs: {
                                                                  type: "caret-right",
                                                                  rotate:
                                                                    props.isActive
                                                                      ? 90
                                                                      : 0,
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: _vm.reDefaultKey,
                                                      callback: function ($$v) {
                                                        _vm.reDefaultKey = $$v
                                                      },
                                                      expression:
                                                        "reDefaultKey",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.showOptions
                                                      .find((showOption) =>
                                                        showOption.list.find(
                                                          (option) =>
                                                            option.title ==
                                                            _vm.selectedTitle
                                                        )
                                                      )
                                                      .list.find(
                                                        (option) =>
                                                          option.title ==
                                                          _vm.selectedTitle
                                                      )
                                                      .manuscriptList.filter(
                                                        (page) =>
                                                          page.annotations &&
                                                          page.annotations.filter(
                                                            (item) =>
                                                              ((!_vm.radioValue ||
                                                                (_vm.radioValue ==
                                                                  2 &&
                                                                  (item.updateStatus ==
                                                                    2 ||
                                                                    !item.updateStatus)) ||
                                                                (_vm.radioValue ==
                                                                  1 &&
                                                                  item.updateStatus ==
                                                                    1)) &&
                                                                (_vm.checkedStages.some(
                                                                  (c) =>
                                                                    c ==
                                                                    item.targetStage
                                                                ) ||
                                                                  !item.targetStage) &&
                                                                item.annotationKind !=
                                                                  1) ||
                                                              (item.annotationKind ==
                                                                1 &&
                                                                (_vm.checkedStages.some(
                                                                  (c) =>
                                                                    c ==
                                                                    item.targetStage
                                                                ) ||
                                                                  !item.targetStage) &&
                                                                _vm.hasLocal &&
                                                                (!_vm.radioValue ||
                                                                  (_vm.radioValue ==
                                                                    2 &&
                                                                    (item.updateStatus ==
                                                                      2 ||
                                                                      !item.updateStatus)) ||
                                                                  (_vm.radioValue ==
                                                                    1 &&
                                                                    item.updateStatus ==
                                                                      1)))
                                                          ).length
                                                      ),
                                                    function (page, pageIdx) {
                                                      return _c(
                                                        "a-collapse-panel",
                                                        {
                                                          key: page.id + "",
                                                          style:
                                                            _vm.customStyle,
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                              },
                                                              attrs: {
                                                                slot: "header",
                                                              },
                                                              slot: "header",
                                                            },
                                                            [
                                                              _c(
                                                                "a-tooltip",
                                                                {
                                                                  attrs: {
                                                                    transitionName:
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "template",
                                                                    {
                                                                      slot: "title",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            page.fileName
                                                                          ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "max-width":
                                                                            "190px",
                                                                          display:
                                                                            "inline-block",
                                                                          overflow:
                                                                            "hidden",
                                                                          "white-space":
                                                                            "nowrap",
                                                                          "text-overflow":
                                                                            "ellipsis",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          page.fileName
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      `共${
                                                                        page.annotations.filter(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            ((!_vm.radioValue ||
                                                                              (_vm.radioValue ==
                                                                                2 &&
                                                                                (item.updateStatus ==
                                                                                  2 ||
                                                                                  !item.updateStatus)) ||
                                                                              (_vm.radioValue ==
                                                                                1 &&
                                                                                item.updateStatus ==
                                                                                  1)) &&
                                                                              (_vm.checkedStages.some(
                                                                                (
                                                                                  c
                                                                                ) =>
                                                                                  c ==
                                                                                  item.targetStage
                                                                              ) ||
                                                                                !item.targetStage) &&
                                                                              item.annotationKind !=
                                                                                1) ||
                                                                            (item.annotationKind ==
                                                                              1 &&
                                                                              _vm.hasLocal &&
                                                                              (_vm.checkedStages.some(
                                                                                (
                                                                                  c
                                                                                ) =>
                                                                                  c ==
                                                                                  item.targetStage
                                                                              ) ||
                                                                                !item.targetStage) &&
                                                                              (!_vm.radioValue ||
                                                                                (_vm.radioValue ==
                                                                                  2 &&
                                                                                  (item.updateStatus ==
                                                                                    2 ||
                                                                                    !item.updateStatus)) ||
                                                                                (_vm.radioValue ==
                                                                                  1 &&
                                                                                  item.updateStatus ==
                                                                                    1)))
                                                                        ).length
                                                                      }处`
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            page.annotations.filter(
                                                              (item) =>
                                                                ((!_vm.radioValue ||
                                                                  (_vm.radioValue ==
                                                                    2 &&
                                                                    (item.updateStatus ==
                                                                      2 ||
                                                                      !item.updateStatus)) ||
                                                                  (_vm.radioValue ==
                                                                    1 &&
                                                                    item.updateStatus ==
                                                                      1)) &&
                                                                  (_vm.checkedStages.some(
                                                                    (c) =>
                                                                      c ==
                                                                      item.targetStage
                                                                  ) ||
                                                                    !item.targetStage) &&
                                                                  item.annotationKind !=
                                                                    1) ||
                                                                (item.annotationKind ==
                                                                  1 &&
                                                                  (_vm.checkedStages.some(
                                                                    (c) =>
                                                                      c ==
                                                                      item.targetStage
                                                                  ) ||
                                                                    !item.targetStage) &&
                                                                  _vm.hasLocal &&
                                                                  (!_vm.radioValue ||
                                                                    (_vm.radioValue ==
                                                                      2 &&
                                                                      (item.updateStatus ==
                                                                        2 ||
                                                                        !item.updateStatus)) ||
                                                                    (_vm.radioValue ==
                                                                      1 &&
                                                                      item.updateStatus ==
                                                                        1)))
                                                            ),
                                                            function (
                                                              item,
                                                              itemIndex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: item.id,
                                                                  ref:
                                                                    item.id +
                                                                    "local",
                                                                  refInFor: true,
                                                                  class:
                                                                    _vm.selectLocalId ==
                                                                    item.id
                                                                      ? "local-content-list-show local-content-list-show-selected"
                                                                      : "local-content-list-show",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toLocal(
                                                                          page,
                                                                          item,
                                                                          pageIdx
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-list-num",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              itemIndex +
                                                                                1
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-list",
                                                                    },
                                                                    [
                                                                      _vm.taskDetail.departName.indexOf(
                                                                        "原创部"
                                                                      ) == -1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "flex",
                                                                                  "align-items":
                                                                                    "center",
                                                                                  "padding-top":
                                                                                    "5px",
                                                                                  "margin-bottom":
                                                                                    "5px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "font-size":
                                                                                        "14px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                          To:\n                        "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              !item.edit
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                          " +
                                                                                          _vm._s(
                                                                                            item.targetStage
                                                                                          ) +
                                                                                          "\n                        "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "12px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      item.annotationKind ==
                                                                                        2 ||
                                                                                      item.annotationKind ==
                                                                                        3
                                                                                        ? [
                                                                                            _vm
                                                                                              .toStagesAnnotation
                                                                                              .length
                                                                                              ? _c(
                                                                                                  "a-radio-group",
                                                                                                  {
                                                                                                    on: {
                                                                                                      change:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          _vm.toStageChange(
                                                                                                            item,
                                                                                                            page,
                                                                                                            pageIdx,
                                                                                                            _vm.showOptions
                                                                                                              .find(
                                                                                                                (
                                                                                                                  showOption
                                                                                                                ) =>
                                                                                                                  showOption.list.find(
                                                                                                                    (
                                                                                                                      option
                                                                                                                    ) =>
                                                                                                                      option.title ==
                                                                                                                      _vm.selectedTitle
                                                                                                                  )
                                                                                                              )
                                                                                                              .list.find(
                                                                                                                (
                                                                                                                  option
                                                                                                                ) =>
                                                                                                                  option.title ==
                                                                                                                  _vm.selectedTitle
                                                                                                              ),
                                                                                                            item.annotationKind
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                    model:
                                                                                                      {
                                                                                                        value:
                                                                                                          item.targetStage,
                                                                                                        callback:
                                                                                                          function (
                                                                                                            $$v
                                                                                                          ) {
                                                                                                            _vm.$set(
                                                                                                              item,
                                                                                                              "targetStage",
                                                                                                              $$v
                                                                                                            )
                                                                                                          },
                                                                                                        expression:
                                                                                                          "item.targetStage",
                                                                                                      },
                                                                                                  },
                                                                                                  _vm._l(
                                                                                                    _vm.toStagesAnnotation.filter(
                                                                                                      (
                                                                                                        s
                                                                                                      ) => {
                                                                                                        return (
                                                                                                          _vm.changeAll ||
                                                                                                          s.stageName ==
                                                                                                            _vm.targetStage
                                                                                                        )
                                                                                                      }
                                                                                                    ),
                                                                                                    function (
                                                                                                      stage
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "a-radio",
                                                                                                        {
                                                                                                          key: stage.stageName,
                                                                                                          attrs:
                                                                                                            {
                                                                                                              value:
                                                                                                                stage.stageName,
                                                                                                              disabled:
                                                                                                                !_vm.changeAll ||
                                                                                                                _vm.isAutoSave,
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                " +
                                                                                                              _vm._s(
                                                                                                                stage.stageName
                                                                                                              ) +
                                                                                                              "\n                              "
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    }
                                                                                                  ),
                                                                                                  1
                                                                                                )
                                                                                              : _c(
                                                                                                  "span",
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      _vm._s(
                                                                                                        item.targetStage
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                          ]
                                                                                        : _c(
                                                                                            "a-radio-group",
                                                                                            {
                                                                                              on: {
                                                                                                change:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.toStageChange(
                                                                                                      item,
                                                                                                      page,
                                                                                                      pageIdx,
                                                                                                      _vm.showOptions
                                                                                                        .find(
                                                                                                          (
                                                                                                            showOption
                                                                                                          ) =>
                                                                                                            showOption.list.find(
                                                                                                              (
                                                                                                                option
                                                                                                              ) =>
                                                                                                                option.title ==
                                                                                                                _vm.selectedTitle
                                                                                                            )
                                                                                                        )
                                                                                                        .list.find(
                                                                                                          (
                                                                                                            option
                                                                                                          ) =>
                                                                                                            option.title ==
                                                                                                            _vm.selectedTitle
                                                                                                        ),
                                                                                                      item.annotationKind
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    item.targetStage,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        item,
                                                                                                        "targetStage",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "item.targetStage",
                                                                                                },
                                                                                            },
                                                                                            _vm._l(
                                                                                              item.toStagesLeaveMessage.filter(
                                                                                                (
                                                                                                  s
                                                                                                ) => {
                                                                                                  return (
                                                                                                    _vm.changeAll ||
                                                                                                    s.stageName ==
                                                                                                      _vm.targetStage
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              function (
                                                                                                stage
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "a-radio",
                                                                                                  {
                                                                                                    key: stage.stageName,
                                                                                                    attrs:
                                                                                                      {
                                                                                                        value:
                                                                                                          stage.stageName,
                                                                                                        disabled:
                                                                                                          !_vm.changeAll ||
                                                                                                          _vm.isAutoSave,
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "\n                              " +
                                                                                                        _vm._s(
                                                                                                          stage.stageName
                                                                                                        ) +
                                                                                                        "\n                            "
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            1
                                                                                          ),
                                                                                    ],
                                                                                    2
                                                                                  ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.initUser
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-list-user",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-list-user-left",
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "27px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src: item
                                                                                            .initUser
                                                                                            .avatar,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-list-user-right",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-user-right-left",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-list-user-right-left-top",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                              " +
                                                                                              _vm._s(
                                                                                                item.initUser
                                                                                                  ? item
                                                                                                      .initUser
                                                                                                      .nickName
                                                                                                  : ""
                                                                                              ) +
                                                                                              "\n                            "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-list-user-right-left-bottom",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-left":
                                                                                                "5px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                              " +
                                                                                              _vm._s(
                                                                                                item.initUser
                                                                                                  ? item
                                                                                                      .initUser
                                                                                                      .createTimeStr
                                                                                                  : ""
                                                                                              ) +
                                                                                              "\n                            "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-user-right-right",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-top":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm.checkPermission(
                                                                                        "fatchStraw:localStatus:view"
                                                                                      )
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                item.updateStatus ==
                                                                                                  2 ||
                                                                                                !item.updateStatus
                                                                                                  ? "change-sign"
                                                                                                  : "change-sign change-sign-over",
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    _vm.checkPermission(
                                                                                                      "fatchStraw:localStatus:edit"
                                                                                                    )
                                                                                                      ? _vm.changeUpdateStatus(
                                                                                                          item
                                                                                                        )
                                                                                                      : () => {}
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _vm.changeAll
                                                                                                ? _c(
                                                                                                    "a-tooltip",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "template",
                                                                                                        {
                                                                                                          slot: "title",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  item.updateStatus ==
                                                                                                                    2 ||
                                                                                                                    !item.updateStatus
                                                                                                                    ? "标记为“已解决”"
                                                                                                                    : "标记为“未解决”"
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "a-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              type: "check-circle",
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : item.commentData &&
                                                                          item
                                                                            .commentData
                                                                            .length
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-list-user",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-list-user-left",
                                                                                  staticStyle:
                                                                                    {
                                                                                      height:
                                                                                        "27px",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src: item
                                                                                            .commentData[0]
                                                                                            .avatar,
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-list-user-right",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-user-right-left",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-list-user-right-left-top",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                              " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .commentData[0]
                                                                                                  .userName
                                                                                              ) +
                                                                                              "\n                            "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-content-list-user-right-left-bottom",
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-left":
                                                                                                "5px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                              " +
                                                                                              _vm._s(
                                                                                                item
                                                                                                  .commentData[0]
                                                                                                  .createTimeStr
                                                                                              ) +
                                                                                              "\n                            "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-user-right-right",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-top":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm.checkPermission(
                                                                                        "fatchStraw:localStatus:view"
                                                                                      )
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              class:
                                                                                                item.updateStatus ==
                                                                                                  2 ||
                                                                                                !item.updateStatus
                                                                                                  ? "change-sign"
                                                                                                  : "change-sign change-sign-over",
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                    _vm.checkPermission(
                                                                                                      "fatchStraw:localStatus:edit"
                                                                                                    )
                                                                                                      ? _vm.changeUpdateStatus(
                                                                                                          item
                                                                                                        )
                                                                                                      : () => {}
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _vm.changeAll
                                                                                                ? _c(
                                                                                                    "a-tooltip",
                                                                                                    [
                                                                                                      _c(
                                                                                                        "template",
                                                                                                        {
                                                                                                          slot: "title",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  item.updateStatus ==
                                                                                                                    2 ||
                                                                                                                    !item.updateStatus
                                                                                                                    ? "标记为“已解决”"
                                                                                                                    : "标记为“未解决”"
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "a-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              type: "check-circle",
                                                                                                            },
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    2
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.injuryReason ||
                                                                      item.edit ||
                                                                      item.targetStage
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-list-error",
                                                                            },
                                                                            [
                                                                              item.injuryType
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-error-top",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-top":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              "font-weight":
                                                                                                "700",
                                                                                              color:
                                                                                                "red",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "错误类型"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      (_vm.changeAll &&
                                                                                        (_vm.checkPermission(
                                                                                          "fatchStraw:localList:dele"
                                                                                        ) ||
                                                                                          (_vm.isLocalZhongShen &&
                                                                                            item.annotationKind ==
                                                                                              1) ||
                                                                                          (item.initUser &&
                                                                                            item
                                                                                              .initUser
                                                                                              .userId ==
                                                                                              _vm
                                                                                                .userInfo
                                                                                                .id) ||
                                                                                          (item.targetStage ==
                                                                                            "终审" &&
                                                                                            item.createStage ==
                                                                                              "监制" &&
                                                                                            _vm.userInfo.positions.some(
                                                                                              (
                                                                                                item
                                                                                              ) =>
                                                                                                item.name ==
                                                                                                "终审"
                                                                                            )))) ||
                                                                                      _vm.isAdmin ||
                                                                                      (_vm.taskDetail.departName.indexOf(
                                                                                        "原创部"
                                                                                      ) >
                                                                                        -1 &&
                                                                                        _vm
                                                                                          .taskDetail
                                                                                          .canRejectStages)
                                                                                        ? _c(
                                                                                            "a-dropdown",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  trigger:
                                                                                                    [
                                                                                                      "click",
                                                                                                    ],
                                                                                                  transitionName:
                                                                                                    "",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "a-icon",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      position:
                                                                                                        "relative",
                                                                                                      top: "5px",
                                                                                                    },
                                                                                                  attrs:
                                                                                                    {
                                                                                                      type: "more",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        $event.stopPropagation()
                                                                                                      },
                                                                                                  },
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "a-menu",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      slot: "overlay",
                                                                                                    },
                                                                                                  slot: "overlay",
                                                                                                },
                                                                                                [
                                                                                                  (item.initUser &&
                                                                                                    item
                                                                                                      .initUser
                                                                                                      .userId ==
                                                                                                      _vm
                                                                                                        .userInfo
                                                                                                        .id) ||
                                                                                                  _vm.isAdmin ||
                                                                                                  (_vm.taskDetail.departName.indexOf(
                                                                                                    "原创部"
                                                                                                  ) >
                                                                                                    -1 &&
                                                                                                    _vm
                                                                                                      .taskDetail
                                                                                                      .canRejectStages)
                                                                                                    ? _c(
                                                                                                        "a-menu-item",
                                                                                                        {
                                                                                                          key: "0",
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.editError(
                                                                                                                  item
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                编辑\n                              "
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  _vm.checkPermission(
                                                                                                    "fatchStraw:localList:dele"
                                                                                                  ) ||
                                                                                                  (_vm.isLocalZhongShen &&
                                                                                                    item.annotationKind ==
                                                                                                      1) ||
                                                                                                  (item.initUser &&
                                                                                                    item
                                                                                                      .initUser
                                                                                                      .userId ==
                                                                                                      _vm
                                                                                                        .userInfo
                                                                                                        .id) ||
                                                                                                  (item.targetStage ==
                                                                                                    "终审" &&
                                                                                                    item.createStage ==
                                                                                                      "监制" &&
                                                                                                    _vm.userInfo.positions.some(
                                                                                                      (
                                                                                                        item
                                                                                                      ) =>
                                                                                                        item.name ==
                                                                                                        "终审"
                                                                                                    )) ||
                                                                                                  (_vm.taskDetail.departName.indexOf(
                                                                                                    "原创部"
                                                                                                  ) >
                                                                                                    -1 &&
                                                                                                    _vm
                                                                                                      .taskDetail
                                                                                                      .canRejectStages)
                                                                                                    ? _c(
                                                                                                        "a-menu-item",
                                                                                                        {
                                                                                                          key: "1",
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.deleError(
                                                                                                                  item,
                                                                                                                  page
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "\n                                删除\n                              "
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                              !item.edit
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-error-bottom",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                          " +
                                                                                          _vm._s(
                                                                                            item.injuryType ==
                                                                                              1
                                                                                              ? "软伤，"
                                                                                              : item.injuryType ==
                                                                                                2
                                                                                              ? "硬伤，"
                                                                                              : item.injuryType ==
                                                                                                4
                                                                                              ? "超级硬伤，"
                                                                                              : item.injuryType ==
                                                                                                5
                                                                                              ? "超级软伤，"
                                                                                              : ""
                                                                                          ) +
                                                                                          _vm._s(
                                                                                            item.injuryReason ||
                                                                                              ""
                                                                                          ) +
                                                                                          "\n                        "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-error-bottom",
                                                                                    },
                                                                                    [
                                                                                      item.annotationKind &&
                                                                                      item.annotationKind >
                                                                                        1 &&
                                                                                      _vm.toStagesAnnotation.find(
                                                                                        (
                                                                                          stage
                                                                                        ) =>
                                                                                          stage.stageName ==
                                                                                          item.targetStage
                                                                                      ) &&
                                                                                      _vm.toStagesAnnotation.find(
                                                                                        (
                                                                                          stage
                                                                                        ) =>
                                                                                          stage.stageName ==
                                                                                          item.targetStage
                                                                                      )
                                                                                        .errorDict &&
                                                                                      _vm.selectedTitle !=
                                                                                        "初稿"
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-item-action-radio",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  class:
                                                                                                    !item.injuryType
                                                                                                      ? "error-span"
                                                                                                      : "",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "margin-left":
                                                                                                        "3px",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        $event.stopPropagation()
                                                                                                        return _vm.changeInjuryType(
                                                                                                          item,
                                                                                                          0
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "无"
                                                                                                  ),
                                                                                                  !item.injuryType
                                                                                                    ? _c(
                                                                                                        "a-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              type: "check-circle",
                                                                                                              theme:
                                                                                                                "filled",
                                                                                                            },
                                                                                                        }
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm.toStagesAnnotation.find(
                                                                                                (
                                                                                                  stage
                                                                                                ) =>
                                                                                                  stage.stageName ==
                                                                                                  item.targetStage
                                                                                              )
                                                                                                ? _vm._l(
                                                                                                    _vm.toStagesAnnotation.find(
                                                                                                      (
                                                                                                        stage
                                                                                                      ) =>
                                                                                                        stage.stageName ==
                                                                                                        item.targetStage
                                                                                                    )
                                                                                                      .errorDict,
                                                                                                    function (
                                                                                                      error
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          key: error.id,
                                                                                                          class:
                                                                                                            item.injuryType ==
                                                                                                            error.type
                                                                                                              ? "error-span"
                                                                                                              : "",
                                                                                                          style:
                                                                                                            {
                                                                                                              color:
                                                                                                                error.dictName.indexOf(
                                                                                                                  "硬"
                                                                                                                ) >
                                                                                                                  -1 ||
                                                                                                                error.dictName.indexOf(
                                                                                                                  "错翻"
                                                                                                                ) >
                                                                                                                  -1
                                                                                                                  ? "red"
                                                                                                                  : "orange",
                                                                                                              marginLeft:
                                                                                                                "3px",
                                                                                                              width:
                                                                                                                error
                                                                                                                  .dictName
                                                                                                                  .length >
                                                                                                                1
                                                                                                                  ? "35px"
                                                                                                                  : "",
                                                                                                            },
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                return _vm.changeInjuryType(
                                                                                                                  item,
                                                                                                                  error.type,
                                                                                                                  error.id
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              error.dictName
                                                                                                            )
                                                                                                          ),
                                                                                                          item.injuryType ==
                                                                                                          error.type
                                                                                                            ? _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      type: "check-circle",
                                                                                                                      theme:
                                                                                                                        "filled",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        : item.annotationKind &&
                                                                                          item.annotationKind >
                                                                                            1 &&
                                                                                          item.toStagesAnnotation &&
                                                                                          item.toStagesAnnotation.find(
                                                                                            (
                                                                                              stage
                                                                                            ) =>
                                                                                              stage.stageName ==
                                                                                              item.targetStage
                                                                                          ) &&
                                                                                          item.toStagesAnnotation.find(
                                                                                            (
                                                                                              stage
                                                                                            ) =>
                                                                                              stage.stageName ==
                                                                                              item.targetStage
                                                                                          )
                                                                                            .errorDict &&
                                                                                          _vm.selectedTitle ==
                                                                                            "初稿"
                                                                                        ? _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-item-action-radio",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  class:
                                                                                                    !item.injuryType
                                                                                                      ? "error-span"
                                                                                                      : "",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "margin-left":
                                                                                                        "3px",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        $event.stopPropagation()
                                                                                                        return _vm.changeInjuryType(
                                                                                                          item,
                                                                                                          0
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "无"
                                                                                                  ),
                                                                                                  !item.injuryType
                                                                                                    ? _c(
                                                                                                        "a-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              type: "check-circle",
                                                                                                              theme:
                                                                                                                "filled",
                                                                                                            },
                                                                                                        }
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              item.toStagesAnnotation.find(
                                                                                                (
                                                                                                  stage
                                                                                                ) =>
                                                                                                  stage.stageName ==
                                                                                                  item.targetStage
                                                                                              )
                                                                                                ? _vm._l(
                                                                                                    item.toStagesAnnotation.find(
                                                                                                      (
                                                                                                        stage
                                                                                                      ) =>
                                                                                                        stage.stageName ==
                                                                                                        item.targetStage
                                                                                                    )
                                                                                                      .errorDict,
                                                                                                    function (
                                                                                                      error
                                                                                                    ) {
                                                                                                      return _c(
                                                                                                        "span",
                                                                                                        {
                                                                                                          key: error.id,
                                                                                                          class:
                                                                                                            item.injuryType ==
                                                                                                            error.type
                                                                                                              ? "error-span"
                                                                                                              : "",
                                                                                                          style:
                                                                                                            {
                                                                                                              color:
                                                                                                                error.dictName.indexOf(
                                                                                                                  "硬"
                                                                                                                ) >
                                                                                                                  -1 ||
                                                                                                                error.dictName.indexOf(
                                                                                                                  "错翻"
                                                                                                                ) >
                                                                                                                  -1
                                                                                                                  ? "red"
                                                                                                                  : "orange",
                                                                                                              marginLeft:
                                                                                                                "3px",
                                                                                                              width:
                                                                                                                error
                                                                                                                  .dictName
                                                                                                                  .length >
                                                                                                                1
                                                                                                                  ? "35px"
                                                                                                                  : "",
                                                                                                            },
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                                return _vm.changeInjuryType(
                                                                                                                  item,
                                                                                                                  error.type,
                                                                                                                  error.id
                                                                                                                )
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              error.dictName
                                                                                                            )
                                                                                                          ),
                                                                                                          item.injuryType ==
                                                                                                          error.type
                                                                                                            ? _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      type: "check-circle",
                                                                                                                      theme:
                                                                                                                        "filled",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      )
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            2
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "local-item-action-input",
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          item.injuryType &&
                                                                                          item.annotationKind &&
                                                                                          item.annotationKind >
                                                                                            1
                                                                                            ? _c(
                                                                                                "a-select",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      width:
                                                                                                        "255px",
                                                                                                    },
                                                                                                  attrs:
                                                                                                    {
                                                                                                      "show-search":
                                                                                                        "",
                                                                                                      placeholder:
                                                                                                        "请选择错误类型",
                                                                                                    },
                                                                                                  model:
                                                                                                    {
                                                                                                      value:
                                                                                                        item.injuryReason,
                                                                                                      callback:
                                                                                                        function (
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.$set(
                                                                                                            item,
                                                                                                            "injuryReason",
                                                                                                            $$v
                                                                                                          )
                                                                                                        },
                                                                                                      expression:
                                                                                                        "item.injuryReason",
                                                                                                    },
                                                                                                },
                                                                                                _vm._l(
                                                                                                  _vm.dictList,
                                                                                                  function (
                                                                                                    errorItem
                                                                                                  ) {
                                                                                                    return _c(
                                                                                                      "a-select-option",
                                                                                                      {
                                                                                                        key: errorItem.id,
                                                                                                        attrs:
                                                                                                          {
                                                                                                            value:
                                                                                                              errorItem.itemText,
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "\n                                " +
                                                                                                            _vm._s(
                                                                                                              errorItem.itemText
                                                                                                            ) +
                                                                                                            "\n                              "
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  }
                                                                                                ),
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          item.commentData &&
                                                                                          item
                                                                                            .commentData
                                                                                            .length
                                                                                            ? _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "padding-top":
                                                                                                        "8px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "j-editor",
                                                                                                    {
                                                                                                      ref: "localContentInput",
                                                                                                      refInFor: true,
                                                                                                      attrs:
                                                                                                        {
                                                                                                          placeholder:
                                                                                                            "ALT+回车换行",
                                                                                                          maxWidth:
                                                                                                            "50px",
                                                                                                          toolbar:
                                                                                                            [],
                                                                                                        },
                                                                                                      model:
                                                                                                        {
                                                                                                          value:
                                                                                                            _vm.localValue,
                                                                                                          callback:
                                                                                                            function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.localValue =
                                                                                                                $$v
                                                                                                            },
                                                                                                          expression:
                                                                                                            "localValue",
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-item-action-input-button",
                                                                                              on: {
                                                                                                click:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    $event.stopPropagation()
                                                                                                  },
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "a-button",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      size: "small",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.cancelErrorEdit(
                                                                                                          item
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                取消\n                              "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "a-button",
                                                                                                {
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "margin-left":
                                                                                                        "10px",
                                                                                                    },
                                                                                                  attrs:
                                                                                                    {
                                                                                                      type: "primary",
                                                                                                      size: "small",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        _vm.changeError(
                                                                                                          item,
                                                                                                          page,
                                                                                                          pageIdx,
                                                                                                          _vm.showOptions
                                                                                                            .find(
                                                                                                              (
                                                                                                                showOption
                                                                                                              ) =>
                                                                                                                showOption.list.find(
                                                                                                                  (
                                                                                                                    option
                                                                                                                  ) =>
                                                                                                                    option.title ==
                                                                                                                    _vm.selectedTitle
                                                                                                                )
                                                                                                            )
                                                                                                            .list.find(
                                                                                                              (
                                                                                                                option
                                                                                                              ) =>
                                                                                                                option.title ==
                                                                                                                _vm.selectedTitle
                                                                                                            )
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "\n                                保存\n                              "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                              (!item.commentData ||
                                                                                !item
                                                                                  .commentData
                                                                                  .length) &&
                                                                              !item.edit &&
                                                                              _vm.selectLocalId ==
                                                                                item.id &&
                                                                              item.injuryReason
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-top":
                                                                                            "8px",
                                                                                          "padding-bottom":
                                                                                            "8px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm.changeAll
                                                                                        ? _c(
                                                                                            "j-editor",
                                                                                            {
                                                                                              ref: "localContentInput",
                                                                                              refInFor: true,
                                                                                              attrs:
                                                                                                {
                                                                                                  placeholder:
                                                                                                    "ALT+回车换行，回车提交",
                                                                                                  maxWidth:
                                                                                                    "50px",
                                                                                                  toolbar:
                                                                                                    [],
                                                                                                },
                                                                                              on: {
                                                                                                postValue:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.saveLoacalTwo(
                                                                                                      item,
                                                                                                      page,
                                                                                                      pageIdx,
                                                                                                      _vm.showOptions
                                                                                                        .find(
                                                                                                          (
                                                                                                            showOption
                                                                                                          ) =>
                                                                                                            showOption.list.find(
                                                                                                              (
                                                                                                                option
                                                                                                              ) =>
                                                                                                                option.title ==
                                                                                                                _vm.selectedTitle
                                                                                                            )
                                                                                                        )
                                                                                                        .list.find(
                                                                                                          (
                                                                                                            option
                                                                                                          ) =>
                                                                                                            option.title ==
                                                                                                            _vm.selectedTitle
                                                                                                        )
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    item.localValue,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        item,
                                                                                                        "localValue",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "item.localValue",
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.commentData &&
                                                                      item
                                                                        .commentData
                                                                        .length &&
                                                                      !item
                                                                        .commentData[0]
                                                                        .content &&
                                                                      !item.edit
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-list-none",
                                                                              style:
                                                                                {
                                                                                  paddingBottom:
                                                                                    item.commentData &&
                                                                                    item
                                                                                      .commentData
                                                                                      .length ==
                                                                                      1
                                                                                      ? "10px"
                                                                                      : "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "local-content-list-none-top",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          color:
                                                                                            "rgba(0,0,0,0.45)",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "无说明"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  !item.injuryReason
                                                                                    ? _c(
                                                                                        "span",
                                                                                        [
                                                                                          (_vm.changeAll &&
                                                                                            (_vm.checkPermission(
                                                                                              "fatchStraw:localList:dele"
                                                                                            ) ||
                                                                                              (_vm.isLocalZhongShen &&
                                                                                                item.annotationKind ==
                                                                                                  1) ||
                                                                                              (item.initUser &&
                                                                                                _vm
                                                                                                  .userInfo
                                                                                                  .id ==
                                                                                                  item
                                                                                                    .initUser
                                                                                                    .userId) ||
                                                                                              (_vm.taskDetail.departName.indexOf(
                                                                                                "原创部"
                                                                                              ) >
                                                                                                -1 &&
                                                                                                _vm
                                                                                                  .taskDetail
                                                                                                  .canRejectStages))) ||
                                                                                          (item.targetStage ==
                                                                                            "终审" &&
                                                                                            item.createStage ==
                                                                                              "监制" &&
                                                                                            _vm.userInfo.positions.some(
                                                                                              (
                                                                                                item
                                                                                              ) =>
                                                                                                item.name ==
                                                                                                "终审"
                                                                                            ))
                                                                                            ? _c(
                                                                                                "a-dropdown",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      trigger:
                                                                                                        [
                                                                                                          "click",
                                                                                                        ],
                                                                                                      transitionName:
                                                                                                        "",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "a-icon",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          type: "more",
                                                                                                        },
                                                                                                      on: {
                                                                                                        click:
                                                                                                          function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                          },
                                                                                                      },
                                                                                                    }
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "a-menu",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          slot: "overlay",
                                                                                                        },
                                                                                                      slot: "overlay",
                                                                                                    },
                                                                                                    [
                                                                                                      item
                                                                                                        .commentData[0]
                                                                                                        .userId ==
                                                                                                        _vm
                                                                                                          .userInfo
                                                                                                          .id ||
                                                                                                      _vm.isAdmin ||
                                                                                                      (_vm.taskDetail.departName.indexOf(
                                                                                                        "原创部"
                                                                                                      ) >
                                                                                                        -1 &&
                                                                                                        _vm
                                                                                                          .taskDetail
                                                                                                          .canRejectStages)
                                                                                                        ? _c(
                                                                                                            "a-menu-item",
                                                                                                            {
                                                                                                              key: "0",
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.editError(
                                                                                                                      item
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                  编辑\n                                "
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                      _vm.checkPermission(
                                                                                                        "fatchStraw:localList:dele"
                                                                                                      ) ||
                                                                                                      (_vm.isLocalZhongShen &&
                                                                                                        item.annotationKind ==
                                                                                                          1) ||
                                                                                                      (item.initUser &&
                                                                                                        _vm
                                                                                                          .userInfo
                                                                                                          .id ==
                                                                                                          item
                                                                                                            .initUser
                                                                                                            .userId) ||
                                                                                                      (
                                                                                                        item.id +
                                                                                                        ""
                                                                                                      ).indexOf(
                                                                                                        "newLocal"
                                                                                                      ) >
                                                                                                        -1 ||
                                                                                                      (item.targetStage ==
                                                                                                        "终审" &&
                                                                                                        item.createStage ==
                                                                                                          "监制" &&
                                                                                                        _vm.userInfo.positions.some(
                                                                                                          (
                                                                                                            item
                                                                                                          ) =>
                                                                                                            item.name ==
                                                                                                            "终审"
                                                                                                        )) ||
                                                                                                      (_vm.taskDetail.departName.indexOf(
                                                                                                        "原创部"
                                                                                                      ) >
                                                                                                        -1 &&
                                                                                                        _vm
                                                                                                          .taskDetail
                                                                                                          .canRejectStages)
                                                                                                        ? _c(
                                                                                                            "a-menu-item",
                                                                                                            {
                                                                                                              key: "1",
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.deleError(
                                                                                                                      item,
                                                                                                                      page
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                  删除\n                                "
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              ),
                                                                              _vm.selectLocalId ==
                                                                                item.id &&
                                                                              item.commentData &&
                                                                              item
                                                                                .commentData
                                                                                .length ==
                                                                                1
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-none-bottom",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-top":
                                                                                            "8px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              flex: "1",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm.changeAll
                                                                                            ? _c(
                                                                                                "j-editor",
                                                                                                {
                                                                                                  ref: "localContentInput",
                                                                                                  refInFor: true,
                                                                                                  attrs:
                                                                                                    {
                                                                                                      placeholder:
                                                                                                        "ALT+回车换行，回车提交",
                                                                                                      maxWidth:
                                                                                                        "50px",
                                                                                                      toolbar:
                                                                                                        [],
                                                                                                    },
                                                                                                  on: {
                                                                                                    postValue:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        _vm.saveLoacal(
                                                                                                          item,
                                                                                                          page,
                                                                                                          pageIdx,
                                                                                                          _vm.showOptions
                                                                                                            .find(
                                                                                                              (
                                                                                                                showOption
                                                                                                              ) =>
                                                                                                                showOption.list.find(
                                                                                                                  (
                                                                                                                    option
                                                                                                                  ) =>
                                                                                                                    option.title ==
                                                                                                                    _vm.selectedTitle
                                                                                                                )
                                                                                                            )
                                                                                                            .list.find(
                                                                                                              (
                                                                                                                option
                                                                                                              ) =>
                                                                                                                option.title ==
                                                                                                                _vm.selectedTitle
                                                                                                            )
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                  model:
                                                                                                    {
                                                                                                      value:
                                                                                                        _vm.localValue,
                                                                                                      callback:
                                                                                                        function (
                                                                                                          $$v
                                                                                                        ) {
                                                                                                          _vm.localValue =
                                                                                                            $$v
                                                                                                        },
                                                                                                      expression:
                                                                                                        "localValue",
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      item.commentData &&
                                                                      ((item.commentData &&
                                                                        item
                                                                          .commentData
                                                                          .length >
                                                                          1) ||
                                                                        (item.commentData &&
                                                                          item
                                                                            .commentData[0] &&
                                                                          item
                                                                            .commentData[0]
                                                                            .content))
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-list-content",
                                                                              style:
                                                                                {
                                                                                  paddingBottom:
                                                                                    "10px",
                                                                                  paddingTop:
                                                                                    !item.injuryType
                                                                                      ? "3px"
                                                                                      : "",
                                                                                },
                                                                            },
                                                                            [
                                                                              !item.edit &&
                                                                              item.commentData &&
                                                                              item
                                                                                .commentData[0] &&
                                                                              item
                                                                                .commentData[0]
                                                                                .content
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "local-content-list-content-title",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              flex: "1",
                                                                                              display:
                                                                                                "flex",
                                                                                              "justify-content":
                                                                                                "space-between",
                                                                                              "padding-left":
                                                                                                "36px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "show-local-content",
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "font-weight":
                                                                                                    "400",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  innerHTML:
                                                                                                    _vm._s(
                                                                                                      item
                                                                                                        .commentData[0]
                                                                                                        .content
                                                                                                    ),
                                                                                                },
                                                                                              on: {
                                                                                                copy: _vm.textCopy,
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "min-width":
                                                                                                    "16px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              (_vm.changeAll &&
                                                                                                !item.injuryType &&
                                                                                                (_vm.checkPermission(
                                                                                                  "fatchStraw:localList:dele"
                                                                                                ) ||
                                                                                                  (_vm.isLocalZhongShen &&
                                                                                                    item.annotationKind ==
                                                                                                      1) ||
                                                                                                  (_vm.taskDetail.departName.indexOf(
                                                                                                    "原创部"
                                                                                                  ) >
                                                                                                    -1 &&
                                                                                                    _vm
                                                                                                      .taskDetail
                                                                                                      .canRejectStages) ||
                                                                                                  item
                                                                                                    .commentData[0]
                                                                                                    .userId ==
                                                                                                    _vm
                                                                                                      .userInfo
                                                                                                      .id)) ||
                                                                                              (item.targetStage ==
                                                                                                "终审" &&
                                                                                                item.createStage ==
                                                                                                  "监制" &&
                                                                                                _vm.userInfo.positions.some(
                                                                                                  (
                                                                                                    item
                                                                                                  ) =>
                                                                                                    item.name ==
                                                                                                    "终审"
                                                                                                ))
                                                                                                ? _c(
                                                                                                    "a-dropdown",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          trigger:
                                                                                                            [
                                                                                                              "click",
                                                                                                            ],
                                                                                                          transitionName:
                                                                                                            "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "a-icon",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              type: "more",
                                                                                                            },
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                              },
                                                                                                          },
                                                                                                        }
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "a-menu",
                                                                                                        {
                                                                                                          attrs:
                                                                                                            {
                                                                                                              slot: "overlay",
                                                                                                            },
                                                                                                          slot: "overlay",
                                                                                                        },
                                                                                                        [
                                                                                                          item
                                                                                                            .commentData[0]
                                                                                                            .userId ==
                                                                                                            _vm
                                                                                                              .userInfo
                                                                                                              .id ||
                                                                                                          _vm.isAdmin ||
                                                                                                          (_vm.taskDetail.departName.indexOf(
                                                                                                            "原创部"
                                                                                                          ) >
                                                                                                            -1 &&
                                                                                                            _vm
                                                                                                              .taskDetail
                                                                                                              .canRejectStages)
                                                                                                            ? _c(
                                                                                                                "a-menu-item",
                                                                                                                {
                                                                                                                  key: "1",
                                                                                                                  on: {
                                                                                                                    click:
                                                                                                                      function (
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.editError(
                                                                                                                          item
                                                                                                                        )
                                                                                                                      },
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                    编辑\n                                  "
                                                                                                                  ),
                                                                                                                ]
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                          _vm.checkPermission(
                                                                                                            "fatchStraw:localList:dele"
                                                                                                          ) ||
                                                                                                          (_vm.isLocalZhongShen &&
                                                                                                            item.annotationKind ==
                                                                                                              1) ||
                                                                                                          (_vm.taskDetail.departName.indexOf(
                                                                                                            "原创部"
                                                                                                          ) >
                                                                                                            -1 &&
                                                                                                            _vm
                                                                                                              .taskDetail
                                                                                                              .canRejectStages) ||
                                                                                                          item
                                                                                                            .commentData[0]
                                                                                                            .userId ==
                                                                                                            _vm
                                                                                                              .userInfo
                                                                                                              .id ||
                                                                                                          (item.targetStage ==
                                                                                                            "终审" &&
                                                                                                            item.createStage ==
                                                                                                              "监制" &&
                                                                                                            _vm.userInfo.positions.some(
                                                                                                              (
                                                                                                                item
                                                                                                              ) =>
                                                                                                                item.name ==
                                                                                                                "终审"
                                                                                                            ))
                                                                                                            ? _c(
                                                                                                                "a-menu-item",
                                                                                                                {
                                                                                                                  key: "2",
                                                                                                                  on: {
                                                                                                                    click:
                                                                                                                      function (
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.deleError(
                                                                                                                          item,
                                                                                                                          page
                                                                                                                        )
                                                                                                                      },
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "\n                                    删除\n                                  "
                                                                                                                  ),
                                                                                                                ]
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              item.commentData &&
                                                                              item
                                                                                .commentData[0] &&
                                                                              item.commentData.filter(
                                                                                (
                                                                                  an,
                                                                                  anIdx
                                                                                ) =>
                                                                                  anIdx
                                                                              )
                                                                                .length
                                                                                ? _vm._l(
                                                                                    item.commentData.filter(
                                                                                      (
                                                                                        an,
                                                                                        anIdx
                                                                                      ) =>
                                                                                        anIdx
                                                                                    ),
                                                                                    function (
                                                                                      an
                                                                                    ) {
                                                                                      return _c(
                                                                                        "div",
                                                                                        {
                                                                                          key: an.id,
                                                                                          staticStyle:
                                                                                            {
                                                                                              "margin-top":
                                                                                                "3px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-list-content-top",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "local-content-list-content-left",
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "img",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          src: an.avatar,
                                                                                                        },
                                                                                                    }
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "local-content-list-content-right",
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "margin-left":
                                                                                                        "12px",
                                                                                                    },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "local-content-list-content-right-left",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  an.userName ||
                                                                                                                    ""
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            {
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "margin-left":
                                                                                                                    "5px",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  an.createTimeStr ||
                                                                                                                    ""
                                                                                                                )
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "local-content-list-content-right-right",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          position:
                                                                                                            "relative",
                                                                                                          top: "4px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm.changeAll &&
                                                                                                      (_vm.checkPermission(
                                                                                                        "fatchStraw:localList:dele"
                                                                                                      ) ||
                                                                                                        (_vm.isLocalZhongShen &&
                                                                                                          item.annotationKind ==
                                                                                                            1) ||
                                                                                                        (_vm.taskDetail.departName.indexOf(
                                                                                                          "原创部"
                                                                                                        ) >
                                                                                                          -1 &&
                                                                                                          _vm
                                                                                                            .taskDetail
                                                                                                            .canRejectStages) ||
                                                                                                        an.userId ==
                                                                                                          _vm
                                                                                                            .userInfo
                                                                                                            .id ||
                                                                                                        _vm.isAdmin ||
                                                                                                        (item.targetStage ==
                                                                                                          "终审" &&
                                                                                                          item.createStage ==
                                                                                                            "监制" &&
                                                                                                          _vm.userInfo.positions.some(
                                                                                                            (
                                                                                                              item
                                                                                                            ) =>
                                                                                                              item.name ==
                                                                                                              "终审"
                                                                                                          )))
                                                                                                        ? _c(
                                                                                                            "a-dropdown",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  trigger:
                                                                                                                    [
                                                                                                                      "click",
                                                                                                                    ],
                                                                                                                  transitionName:
                                                                                                                    "",
                                                                                                                },
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "a-icon",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      type: "more",
                                                                                                                    },
                                                                                                                  on: {
                                                                                                                    click:
                                                                                                                      function (
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                      },
                                                                                                                  },
                                                                                                                }
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "a-menu",
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      slot: "overlay",
                                                                                                                    },
                                                                                                                  slot: "overlay",
                                                                                                                },
                                                                                                                [
                                                                                                                  an.userId ==
                                                                                                                    _vm
                                                                                                                      .userInfo
                                                                                                                      .id ||
                                                                                                                  _vm.isAdmin ||
                                                                                                                  (_vm.taskDetail.departName.indexOf(
                                                                                                                    "原创部"
                                                                                                                  ) >
                                                                                                                    -1 &&
                                                                                                                    _vm
                                                                                                                      .taskDetail
                                                                                                                      .canRejectStages)
                                                                                                                    ? _c(
                                                                                                                        "a-menu-item",
                                                                                                                        {
                                                                                                                          key: "0",
                                                                                                                          on: {
                                                                                                                            click:
                                                                                                                              function (
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                return _vm.anEdit(
                                                                                                                                  an
                                                                                                                                )
                                                                                                                              },
                                                                                                                          },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "\n                                        编辑\n                                      "
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                  _vm.checkPermission(
                                                                                                                    "fatchStraw:localList:dele"
                                                                                                                  ) ||
                                                                                                                  (_vm.isLocalZhongShen &&
                                                                                                                    item.annotationKind ==
                                                                                                                      1) ||
                                                                                                                  (_vm.taskDetail.departName.indexOf(
                                                                                                                    "原创部"
                                                                                                                  ) >
                                                                                                                    -1 &&
                                                                                                                    _vm
                                                                                                                      .taskDetail
                                                                                                                      .canRejectStages) ||
                                                                                                                  an.userId ==
                                                                                                                    _vm
                                                                                                                      .userInfo
                                                                                                                      .id ||
                                                                                                                  (item.targetStage ==
                                                                                                                    "终审" &&
                                                                                                                    item.createStage ==
                                                                                                                      "监制" &&
                                                                                                                    _vm.userInfo.positions.some(
                                                                                                                      (
                                                                                                                        item
                                                                                                                      ) =>
                                                                                                                        item.name ==
                                                                                                                        "终审"
                                                                                                                    ))
                                                                                                                    ? _c(
                                                                                                                        "a-menu-item",
                                                                                                                        {
                                                                                                                          key: "1",
                                                                                                                          on: {
                                                                                                                            click:
                                                                                                                              function (
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                return _vm.anDele(
                                                                                                                                  item,
                                                                                                                                  an.id
                                                                                                                                )
                                                                                                                              },
                                                                                                                          },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "\n                                        删除\n                                      "
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "local-content-list-content-bottom",
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "padding-left":
                                                                                                    "36px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              !an.edit
                                                                                                ? _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "show-local-content",
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "min-height":
                                                                                                            "21px",
                                                                                                        },
                                                                                                      domProps:
                                                                                                        {
                                                                                                          innerHTML:
                                                                                                            _vm._s(
                                                                                                              an.content
                                                                                                            ),
                                                                                                        },
                                                                                                      on: {
                                                                                                        copy: _vm.textCopy,
                                                                                                      },
                                                                                                    }
                                                                                                  )
                                                                                                : _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticStyle:
                                                                                                        {
                                                                                                          "padding-top":
                                                                                                            "10px",
                                                                                                          "padding-bottom":
                                                                                                            "10px",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        [
                                                                                                          _vm.changeAll
                                                                                                            ? _c(
                                                                                                                "j-editor",
                                                                                                                {
                                                                                                                  ref: "localContentInput",
                                                                                                                  refInFor: true,
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      placeholder:
                                                                                                                        "ALT+回车换行，回车提交",
                                                                                                                      maxWidth:
                                                                                                                        "50px",
                                                                                                                      toolbar:
                                                                                                                        [],
                                                                                                                    },
                                                                                                                  on: {
                                                                                                                    postValue:
                                                                                                                      function (
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        _vm.editLoacal(
                                                                                                                          item,
                                                                                                                          page,
                                                                                                                          pageIdx,
                                                                                                                          _vm.showOptions
                                                                                                                            .find(
                                                                                                                              (
                                                                                                                                showOption
                                                                                                                              ) =>
                                                                                                                                showOption.list.find(
                                                                                                                                  (
                                                                                                                                    option
                                                                                                                                  ) =>
                                                                                                                                    option.title ==
                                                                                                                                    _vm.selectedTitle
                                                                                                                                )
                                                                                                                            )
                                                                                                                            .list.find(
                                                                                                                              (
                                                                                                                                option
                                                                                                                              ) =>
                                                                                                                                option.title ==
                                                                                                                                _vm.selectedTitle
                                                                                                                            ),
                                                                                                                          an.id,
                                                                                                                          an
                                                                                                                        )
                                                                                                                      },
                                                                                                                  },
                                                                                                                  model:
                                                                                                                    {
                                                                                                                      value:
                                                                                                                        an.content,
                                                                                                                      callback:
                                                                                                                        function (
                                                                                                                          $$v
                                                                                                                        ) {
                                                                                                                          _vm.$set(
                                                                                                                            an,
                                                                                                                            "content",
                                                                                                                            $$v
                                                                                                                          )
                                                                                                                        },
                                                                                                                      expression:
                                                                                                                        "an.content",
                                                                                                                    },
                                                                                                                }
                                                                                                              )
                                                                                                            : _vm._e(),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "local-item-action-input-button",
                                                                                                          on: {
                                                                                                            click:
                                                                                                              function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                $event.stopPropagation()
                                                                                                              },
                                                                                                          },
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "a-button",
                                                                                                            {
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  size: "small",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    return _vm.cancelErrorEdit(
                                                                                                                      an
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                    取消\n                                  "
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "a-button",
                                                                                                            {
                                                                                                              staticStyle:
                                                                                                                {
                                                                                                                  "margin-left":
                                                                                                                    "10px",
                                                                                                                },
                                                                                                              attrs:
                                                                                                                {
                                                                                                                  type: "primary",
                                                                                                                  size: "small",
                                                                                                                },
                                                                                                              on: {
                                                                                                                click:
                                                                                                                  function (
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    _vm.editLoacal(
                                                                                                                      item,
                                                                                                                      page,
                                                                                                                      pageIdx,
                                                                                                                      _vm.showOptions
                                                                                                                        .find(
                                                                                                                          (
                                                                                                                            showOption
                                                                                                                          ) =>
                                                                                                                            showOption.list.find(
                                                                                                                              (
                                                                                                                                option
                                                                                                                              ) =>
                                                                                                                                option.title ==
                                                                                                                                _vm.selectedTitle
                                                                                                                            )
                                                                                                                        )
                                                                                                                        .list.find(
                                                                                                                          (
                                                                                                                            option
                                                                                                                          ) =>
                                                                                                                            option.title ==
                                                                                                                            _vm.selectedTitle
                                                                                                                        ),
                                                                                                                      an.id,
                                                                                                                      an
                                                                                                                    )
                                                                                                                  },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "\n                                    保存\n                                  "
                                                                                                              ),
                                                                                                            ]
                                                                                                          ),
                                                                                                        ],
                                                                                                        1
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    }
                                                                                  )
                                                                                : _vm._e(),
                                                                              _vm.selectLocalId ==
                                                                                item.id &&
                                                                              !item.edit &&
                                                                              (!item.commentData ||
                                                                                !item
                                                                                  .commentData
                                                                                  .length ||
                                                                                (item.commentData &&
                                                                                  item.commentData.every(
                                                                                    (
                                                                                      an
                                                                                    ) =>
                                                                                      !an.edit
                                                                                  )))
                                                                                ? _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "huifu",
                                                                                      staticStyle:
                                                                                        {
                                                                                          "padding-top":
                                                                                            "10px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm.changeAll
                                                                                        ? _c(
                                                                                            "j-editor",
                                                                                            {
                                                                                              ref: "localContentInput",
                                                                                              refInFor: true,
                                                                                              attrs:
                                                                                                {
                                                                                                  placeholder:
                                                                                                    "ALT+回车换行，回车提交",
                                                                                                  maxWidth:
                                                                                                    "50px",
                                                                                                  toolbar:
                                                                                                    [],
                                                                                                },
                                                                                              on: {
                                                                                                postValue:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.saveLoacal(
                                                                                                      item,
                                                                                                      page,
                                                                                                      pageIdx,
                                                                                                      _vm.showOptions
                                                                                                        .find(
                                                                                                          (
                                                                                                            showOption
                                                                                                          ) =>
                                                                                                            showOption.list.find(
                                                                                                              (
                                                                                                                option
                                                                                                              ) =>
                                                                                                                option.title ==
                                                                                                                _vm.selectedTitle
                                                                                                            )
                                                                                                        )
                                                                                                        .list.find(
                                                                                                          (
                                                                                                            option
                                                                                                          ) =>
                                                                                                            option.title ==
                                                                                                            _vm.selectedTitle
                                                                                                        )
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    _vm.localValue,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.localValue =
                                                                                                        $$v
                                                                                                    },
                                                                                                  expression:
                                                                                                    "localValue",
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            2
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass: "local-content",
                                    style: item.allHeight
                                      ? {
                                          maxHeight:
                                            _vm.bottomHeight -
                                            270 +
                                            "px !important",
                                          position: "relative",
                                          left: "-16px",
                                          width: "calc(100% + 14px)",
                                        }
                                      : "max-height: 400px;position:relative;left:-16px;width:calc(100% + 14px);",
                                  },
                                  [
                                    _vm.selectedTitle != "原稿" &&
                                    _vm.selectedTitle != "原稿JPG" &&
                                    _vm.selectedTitle != "实时"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "open-all" },
                                              [
                                                _vm.reDefaultKey.length <
                                                _vm.showOptions
                                                  .find((showOption) =>
                                                    showOption.list.find(
                                                      (option) =>
                                                        option.title ==
                                                        _vm.selectedTitle
                                                    )
                                                  )
                                                  .list.find(
                                                    (option) =>
                                                      option.title ==
                                                      _vm.selectedTitle
                                                  )
                                                  .manuscriptList.filter(
                                                    (page) =>
                                                      page.annotations &&
                                                      page.annotations.length
                                                  ).length
                                                  ? _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.openAllLocal.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("全展开/收起")]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.closeAllLocal.apply(
                                                              null,
                                                              arguments
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("全展开/收起")]
                                                    ),
                                              ]
                                            ),
                                            _vm.showOptions
                                              .find((showOption) =>
                                                showOption.list.find(
                                                  (option) =>
                                                    option.title ==
                                                    _vm.selectedTitle
                                                )
                                              )
                                              .list.find(
                                                (option) =>
                                                  option.title ==
                                                  _vm.selectedTitle
                                              ).manuscriptList &&
                                            _vm.showOptions
                                              .find((showOption) =>
                                                showOption.list.find(
                                                  (option) =>
                                                    option.title ==
                                                    _vm.selectedTitle
                                                )
                                              )
                                              .list.find(
                                                (option) =>
                                                  option.title ==
                                                  _vm.selectedTitle
                                              ).manuscriptList.length
                                              ? _c(
                                                  "a-collapse",
                                                  {
                                                    attrs: { bordered: false },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "expandIcon",
                                                          fn: function (props) {
                                                            return [
                                                              _c("a-icon", {
                                                                attrs: {
                                                                  type: "caret-right",
                                                                  rotate:
                                                                    props.isActive
                                                                      ? 90
                                                                      : 0,
                                                                },
                                                              }),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: _vm.reDefaultKey,
                                                      callback: function ($$v) {
                                                        _vm.reDefaultKey = $$v
                                                      },
                                                      expression:
                                                        "reDefaultKey",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.showOptions
                                                      .find((showOption) =>
                                                        showOption.list.find(
                                                          (option) =>
                                                            option.title ==
                                                            _vm.selectedTitle
                                                        )
                                                      )
                                                      .list.find(
                                                        (option) =>
                                                          option.title ==
                                                          _vm.selectedTitle
                                                      )
                                                      .manuscriptList.filter(
                                                        (page) =>
                                                          page.annotations &&
                                                          page.annotations.filter(
                                                            (item) =>
                                                              (item.commentData &&
                                                                item
                                                                  .commentData[0] &&
                                                                item.commentData.filter(
                                                                  (an) =>
                                                                    !_vm.radioValue ||
                                                                    (_vm.radioValue ==
                                                                      2 &&
                                                                      (an.machineStatus ==
                                                                        2 ||
                                                                        !an.machineStatus)) ||
                                                                    (_vm.radioValue ==
                                                                      1 &&
                                                                      an.machineStatus ==
                                                                        1)
                                                                ).length &&
                                                                (_vm.checkedStages.some(
                                                                  (c) =>
                                                                    c ==
                                                                    item.targetStage
                                                                ) ||
                                                                  !item.targetStage) &&
                                                                item.annotationKind !=
                                                                  1) ||
                                                              (item.annotationKind ==
                                                                1 &&
                                                                (_vm.checkedStages.some(
                                                                  (c) =>
                                                                    c ==
                                                                    item.targetStage
                                                                ) ||
                                                                  !item.targetStage) &&
                                                                _vm.hasLocal &&
                                                                (!_vm.radioValue ||
                                                                  (_vm.radioValue ==
                                                                    2 &&
                                                                    (item.updateStatus ==
                                                                      2 ||
                                                                      !item.updateStatus)) ||
                                                                  (_vm.radioValue ==
                                                                    1 &&
                                                                    item.updateStatus ==
                                                                      1)))
                                                          ).length
                                                      ),
                                                    function (page, pageIdx) {
                                                      return _c(
                                                        "a-collapse-panel",
                                                        {
                                                          key: page.id + "",
                                                          style:
                                                            _vm.customStyle,
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "header",
                                                              },
                                                              slot: "header",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    page.fileName
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      `共${
                                                                        page.annotations.filter(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            (item.commentData &&
                                                                              item
                                                                                .commentData[0] &&
                                                                              item.commentData.filter(
                                                                                (
                                                                                  an
                                                                                ) =>
                                                                                  !_vm.radioValue ||
                                                                                  (_vm.radioValue ==
                                                                                    2 &&
                                                                                    (an.machineStatus ==
                                                                                      2 ||
                                                                                      !an.machineStatus)) ||
                                                                                  (_vm.radioValue ==
                                                                                    1 &&
                                                                                    an.machineStatus ==
                                                                                      1)
                                                                              )
                                                                                .length &&
                                                                              (_vm.checkedStages.some(
                                                                                (
                                                                                  c
                                                                                ) =>
                                                                                  c ==
                                                                                  item.targetStage
                                                                              ) ||
                                                                                !item.targetStage) &&
                                                                              item.annotationKind !=
                                                                                1) ||
                                                                            (item.annotationKind ==
                                                                              1 &&
                                                                              (_vm.checkedStages.some(
                                                                                (
                                                                                  c
                                                                                ) =>
                                                                                  c ==
                                                                                  item.targetStage
                                                                              ) ||
                                                                                !item.targetStage) &&
                                                                              _vm.hasLocal &&
                                                                              (!_vm.radioValue ||
                                                                                (_vm.radioValue ==
                                                                                  2 &&
                                                                                  (item.updateStatus ==
                                                                                    2 ||
                                                                                    !item.updateStatus)) ||
                                                                                (_vm.radioValue ==
                                                                                  1 &&
                                                                                  item.updateStatus ==
                                                                                    1)))
                                                                        ).length
                                                                      }处`
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            page.annotations.filter(
                                                              (item) =>
                                                                item.commentData &&
                                                                item
                                                                  .commentData[0] &&
                                                                item.commentData.filter(
                                                                  (an) =>
                                                                    ((!_vm.radioValue ||
                                                                      (_vm.radioValue ==
                                                                        2 &&
                                                                        (an.machineStatus ==
                                                                          2 ||
                                                                          !an.machineStatus)) ||
                                                                      (_vm.radioValue ==
                                                                        1 &&
                                                                        an.machineStatus ==
                                                                          1)) &&
                                                                      (_vm.checkedStages.some(
                                                                        (c) =>
                                                                          c ==
                                                                          item.targetStage
                                                                      ) ||
                                                                        !item.targetStage) &&
                                                                      item.annotationKind !=
                                                                        1) ||
                                                                    (item.annotationKind ==
                                                                      1 &&
                                                                      (_vm.checkedStages.some(
                                                                        (c) =>
                                                                          c ==
                                                                          item.targetStage
                                                                      ) ||
                                                                        !item.targetStage) &&
                                                                      _vm.hasLocal &&
                                                                      (!_vm.radioValue ||
                                                                        (_vm.radioValue ==
                                                                          2 &&
                                                                          (item.updateStatus ==
                                                                            2 ||
                                                                            !item.updateStatus)) ||
                                                                        (_vm.radioValue ==
                                                                          1 &&
                                                                          item.updateStatus ==
                                                                            1)))
                                                                ).length
                                                            ),
                                                            function (
                                                              item,
                                                              itemIndex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: item.id,
                                                                  ref:
                                                                    item.id +
                                                                    "local",
                                                                  refInFor: true,
                                                                  class:
                                                                    _vm.selectLocalId ==
                                                                    item.id
                                                                      ? "local-content-list-show local-content-list-show-selected"
                                                                      : "local-content-list-show",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.toLocal(
                                                                          page,
                                                                          item,
                                                                          pageIdx
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-list-num",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              itemIndex +
                                                                                1
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-list",
                                                                    },
                                                                    [
                                                                      item.commentData &&
                                                                      item
                                                                        .commentData[0] &&
                                                                      item.commentData.filter(
                                                                        (an) =>
                                                                          !_vm.radioValue ||
                                                                          (_vm.radioValue ==
                                                                            2 &&
                                                                            (an.machineStatus ==
                                                                              2 ||
                                                                              !an.machineStatus)) ||
                                                                          (_vm.radioValue ==
                                                                            1 &&
                                                                            an.machineStatus ==
                                                                              1)
                                                                      ).length
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "local-content-list-content",
                                                                              staticStyle:
                                                                                {
                                                                                  "padding-bottom":
                                                                                    "10px",
                                                                                  "padding-left":
                                                                                    "0px",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              item.commentData.filter(
                                                                                (
                                                                                  an
                                                                                ) =>
                                                                                  !_vm.radioValue ||
                                                                                  (_vm.radioValue ==
                                                                                    2 &&
                                                                                    (an.machineStatus ==
                                                                                      2 ||
                                                                                      !an.machineStatus)) ||
                                                                                  (_vm.radioValue ==
                                                                                    1 &&
                                                                                    an.machineStatus ==
                                                                                      1)
                                                                              ),
                                                                              function (
                                                                                an
                                                                              ) {
                                                                                return _c(
                                                                                  "div",
                                                                                  {
                                                                                    key: an.id,
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-top":
                                                                                          "3px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-list-content-top",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-list-content-left",
                                                                                            staticStyle:
                                                                                              {
                                                                                                "align-items":
                                                                                                  "start",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "img",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    position:
                                                                                                      "relative",
                                                                                                    top: "1px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    src: an.avatar,
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-list-content-right",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "local-content-list-content-right-left",
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    display:
                                                                                                      "block",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "line-height":
                                                                                                          "25px",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticStyle:
                                                                                                          {
                                                                                                            "margin-left":
                                                                                                              "11px",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            an.userName ||
                                                                                                              ""
                                                                                                          )
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      {
                                                                                                        staticStyle:
                                                                                                          {
                                                                                                            "margin-left":
                                                                                                              "8px",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            an.createTimeStr ||
                                                                                                              ""
                                                                                                          )
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "local-content-list-content-right-right",
                                                                                              },
                                                                                              [
                                                                                                _vm.changeAll
                                                                                                  ? _c(
                                                                                                      "a-dropdown",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            trigger:
                                                                                                              [
                                                                                                                "click",
                                                                                                              ],
                                                                                                            transitionName:
                                                                                                              "",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "a-icon",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                type: "more",
                                                                                                              },
                                                                                                            on: {
                                                                                                              click:
                                                                                                                function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  $event.stopPropagation()
                                                                                                                },
                                                                                                            },
                                                                                                          }
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "a-menu",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                slot: "overlay",
                                                                                                              },
                                                                                                            slot: "overlay",
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "a-menu-item",
                                                                                                              {
                                                                                                                key: "0",
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.anTranslate(
                                                                                                                        an,
                                                                                                                        page,
                                                                                                                        item
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "\n                                      翻译\n                                    "
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-list-content-bottom",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "show-local-content",
                                                                                            staticStyle:
                                                                                              {
                                                                                                "min-height":
                                                                                                  "21px",
                                                                                                "padding-bottom":
                                                                                                  "5px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                id:
                                                                                                  page.id +
                                                                                                  "-" +
                                                                                                  item.id +
                                                                                                  "-" +
                                                                                                  an.id,
                                                                                              },
                                                                                            domProps:
                                                                                              {
                                                                                                innerHTML:
                                                                                                  _vm._s(
                                                                                                    an.content
                                                                                                  ),
                                                                                              },
                                                                                            on: {
                                                                                              copy: _vm.textCopy,
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                        an.machineTranslation ||
                                                                                        an.edit
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "padding-top":
                                                                                                      "5px",
                                                                                                    "padding-bottom":
                                                                                                      "10px",
                                                                                                    "border-top":
                                                                                                      "1px solid rgba(0,0,0,0.1)",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                !an.edit
                                                                                                  ? _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.translateEdit(
                                                                                                                an
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            an.machineTranslation
                                                                                                          )
                                                                                                        ),
                                                                                                      ]
                                                                                                    )
                                                                                                  : _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticStyle:
                                                                                                          {
                                                                                                            "padding-top":
                                                                                                              "5px",
                                                                                                            "padding-bottom":
                                                                                                              "5px",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "a-textarea",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                placeholder:
                                                                                                                  "请输入",
                                                                                                                "auto-size":
                                                                                                                  {
                                                                                                                    minRows: 1,
                                                                                                                    maxRows: 10,
                                                                                                                  },
                                                                                                              },
                                                                                                            on: {
                                                                                                              blur: function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                _vm.editTranslate(
                                                                                                                  item,
                                                                                                                  page,
                                                                                                                  pageIdx,
                                                                                                                  _vm.showOptions
                                                                                                                    .find(
                                                                                                                      (
                                                                                                                        showOption
                                                                                                                      ) =>
                                                                                                                        showOption.list.find(
                                                                                                                          (
                                                                                                                            option
                                                                                                                          ) =>
                                                                                                                            option.title ==
                                                                                                                            _vm.selectedTitle
                                                                                                                        )
                                                                                                                    )
                                                                                                                    .list.find(
                                                                                                                      (
                                                                                                                        option
                                                                                                                      ) =>
                                                                                                                        option.title ==
                                                                                                                        _vm.selectedTitle
                                                                                                                    ),
                                                                                                                  an.id,
                                                                                                                  an
                                                                                                                )
                                                                                                              },
                                                                                                            },
                                                                                                            model:
                                                                                                              {
                                                                                                                value:
                                                                                                                  an.machineTranslation,
                                                                                                                callback:
                                                                                                                  function (
                                                                                                                    $$v
                                                                                                                  ) {
                                                                                                                    _vm.$set(
                                                                                                                      an,
                                                                                                                      "machineTranslation",
                                                                                                                      $$v
                                                                                                                    )
                                                                                                                  },
                                                                                                                expression:
                                                                                                                  "an.machineTranslation",
                                                                                                              },
                                                                                                          }
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _vm.checkPermission(
                                                                                                      "fatchStraw:localStatus:view"
                                                                                                    )
                                                                                                      ? _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            class:
                                                                                                              an.machineStatus ==
                                                                                                                2 ||
                                                                                                              !an.machineStatus
                                                                                                                ? "change-sign"
                                                                                                                : "change-sign change-sign-over",
                                                                                                            on: {
                                                                                                              click:
                                                                                                                function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  $event.stopPropagation()
                                                                                                                  _vm.checkPermission(
                                                                                                                    "fatchStraw:localStatus:edit"
                                                                                                                  )
                                                                                                                    ? _vm.changeMachineStatus(
                                                                                                                        item,
                                                                                                                        page,
                                                                                                                        pageIdx,
                                                                                                                        _vm.showOptions
                                                                                                                          .find(
                                                                                                                            (
                                                                                                                              showOption
                                                                                                                            ) =>
                                                                                                                              showOption.list.find(
                                                                                                                                (
                                                                                                                                  option
                                                                                                                                ) =>
                                                                                                                                  option.title ==
                                                                                                                                  _vm.selectedTitle
                                                                                                                              )
                                                                                                                          )
                                                                                                                          .list.find(
                                                                                                                            (
                                                                                                                              option
                                                                                                                            ) =>
                                                                                                                              option.title ==
                                                                                                                              _vm.selectedTitle
                                                                                                                          ),
                                                                                                                        an.id,
                                                                                                                        an
                                                                                                                      )
                                                                                                                    : () => {}
                                                                                                                },
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _vm.changeAll
                                                                                                              ? _c(
                                                                                                                  "a-tooltip",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "template",
                                                                                                                      {
                                                                                                                        slot: "title",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "span",
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              _vm._s(
                                                                                                                                an.machineStatus ==
                                                                                                                                  2 ||
                                                                                                                                  !an.machineStatus
                                                                                                                                  ? "标记为“已校对”"
                                                                                                                                  : "标记为“未校对”"
                                                                                                                              )
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "span",
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "a-icon",
                                                                                                                          {
                                                                                                                            attrs:
                                                                                                                              {
                                                                                                                                type: "check-circle",
                                                                                                                              },
                                                                                                                          }
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "span",
                                                                                                                          {
                                                                                                                            staticStyle:
                                                                                                                              {
                                                                                                                                "margin-left":
                                                                                                                                  "5px",
                                                                                                                              },
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              _vm._s(
                                                                                                                                an.machineStatus ==
                                                                                                                                  2 ||
                                                                                                                                  !an.machineStatus
                                                                                                                                  ? "未校对"
                                                                                                                                  : "已校对"
                                                                                                                              )
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        ),
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  2
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      : _vm._e(),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        : _vm._e(),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                            _vm.selectedTitle == "实时"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "local-content",
                                    staticStyle: { position: "relative" },
                                    style: item.allHeight
                                      ? {
                                          maxHeight:
                                            _vm.bottomHeight -
                                            170 +
                                            "px !important",
                                        }
                                      : "max-height: 400px;",
                                  },
                                  [
                                    _vm.leftAnnotationList.length
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { padding: "12px 0" },
                                          },
                                          [
                                            _c("a-checkbox-group", {
                                              attrs: {
                                                options: [
                                                  {
                                                    label: "翻译环节",
                                                    value: "翻译环节",
                                                  },
                                                  {
                                                    label: "制作环节",
                                                    value: "制作环节",
                                                  },
                                                ],
                                              },
                                              model: {
                                                value: _vm.checkedToStages,
                                                callback: function ($$v) {
                                                  _vm.checkedToStages = $$v
                                                },
                                                expression: "checkedToStages",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.rightAnnotationList.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "open-all-annotation-list",
                                            staticStyle: { top: "57px" },
                                          },
                                          [
                                            _vm.rightAnnotationList.some(
                                              (page) =>
                                                page.annotationList.some(
                                                  (anItem) => !anItem.selected
                                                )
                                            )
                                              ? _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openAnnotationList.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("全展开/收起")]
                                                )
                                              : _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.closeAnnotationList.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("全展开/收起")]
                                                ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.rightAnnotationList.length
                                      ? _c(
                                          "a-collapse",
                                          {
                                            staticStyle: {
                                              position: "relative",
                                              left: "-16px",
                                            },
                                            attrs: { bordered: false },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "expandIcon",
                                                  fn: function (props) {
                                                    return [
                                                      _c("a-icon", {
                                                        attrs: {
                                                          type: "caret-right",
                                                          rotate: props.isActive
                                                            ? 90
                                                            : 0,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.rightAnnotationOpenKey,
                                              callback: function ($$v) {
                                                _vm.rightAnnotationOpenKey = $$v
                                              },
                                              expression:
                                                "rightAnnotationOpenKey",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              _vm.rightAnnotationList,
                                              function (page, pageIdx) {
                                                return [
                                                  page.annotationList &&
                                                  page.annotationList.filter(
                                                    (annoItem) =>
                                                      _vm.checkedToStages.some(
                                                        (toStage) =>
                                                          toStage ==
                                                          annoItem.toStage
                                                      )
                                                  ).length
                                                    ? _c(
                                                        "a-collapse-panel",
                                                        {
                                                          key:
                                                            pageIdx +
                                                            "rightOldLocal",
                                                          style:
                                                            _vm.customStyle,
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "header",
                                                              },
                                                              slot: "header",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    `第${
                                                                      _vm.showOptions
                                                                        .find(
                                                                          (
                                                                            showOption
                                                                          ) =>
                                                                            showOption.list.find(
                                                                              (
                                                                                option
                                                                              ) =>
                                                                                option.title ==
                                                                                "实时"
                                                                            )
                                                                        )
                                                                        .list.find(
                                                                          (
                                                                            option
                                                                          ) =>
                                                                            option.title ==
                                                                            "实时"
                                                                        )
                                                                        .manuscriptList.findIndex(
                                                                          (p) =>
                                                                            p.id ==
                                                                            page.pageId
                                                                        ) + 1
                                                                    }页`
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      `共${page.annotationList.length}处`
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            page.annotationList.filter(
                                                              (annoItem) =>
                                                                _vm.checkedToStages.some(
                                                                  (toStage) =>
                                                                    toStage ==
                                                                    annoItem.toStage
                                                                )
                                                            ),
                                                            function (
                                                              annoItem
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    annoItem.id +
                                                                    "oldLocal",
                                                                  class:
                                                                    _vm.selectId ===
                                                                    annoItem.id
                                                                      ? "local-content-list-show-old local-content-list-show-selected"
                                                                      : "local-content-list-show-old",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.toLocalOld(
                                                                          annoItem,
                                                                          "实时"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-list-num-two",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              annoItem.sequenceNum
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  annoItem.commentData &&
                                                                  annoItem
                                                                    .commentData
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-list-old",
                                                                        },
                                                                        _vm._l(
                                                                          annoItem.commentData,
                                                                          function (
                                                                            i,
                                                                            idx
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "anno" +
                                                                                  idx,
                                                                                staticClass:
                                                                                  "local-content-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        padding:
                                                                                          "5px 0 5px 0",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        annoItem.toStage
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-top",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-left",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: i.avatar,
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-right",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-item-top-right-user",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    i.userName
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                class:
                                                                                                  _vm.selectId ==
                                                                                                  annoItem.id
                                                                                                    ? "action-span-select"
                                                                                                    : "action-span",
                                                                                              }
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                              " +
                                                                                                _vm._s(
                                                                                                  i.createTimeStr
                                                                                                ) +
                                                                                                "\n                            "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-bottom",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          $event.stopPropagation()
                                                                                          return _vm.toLocalOld(
                                                                                            annoItem,
                                                                                            "实时"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    !i.editble
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "show-local-content",
                                                                                            domProps:
                                                                                              {
                                                                                                innerHTML:
                                                                                                  _vm._s(
                                                                                                    i.content
                                                                                                  ),
                                                                                              },
                                                                                            on: {
                                                                                              copy: _vm.textCopy,
                                                                                            },
                                                                                          }
                                                                                        )
                                                                                      : _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "padding-right":
                                                                                                  "5px",
                                                                                                "padding-top":
                                                                                                  "3px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm.changeAll
                                                                                              ? _c(
                                                                                                  "a-textarea",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        placeholder:
                                                                                                          "ALT+回车换行，回车提交",
                                                                                                        "auto-size":
                                                                                                          {
                                                                                                            minRows: 1,
                                                                                                            maxRows: 10,
                                                                                                          },
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          $event.stopPropagation()
                                                                                                        },
                                                                                                      keydown:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          if (
                                                                                                            !$event.type.indexOf(
                                                                                                              "key"
                                                                                                            ) &&
                                                                                                            _vm._k(
                                                                                                              $event.keyCode,
                                                                                                              "enter",
                                                                                                              13,
                                                                                                              $event.key,
                                                                                                              "Enter"
                                                                                                            )
                                                                                                          )
                                                                                                            return null
                                                                                                          return ((
                                                                                                            e
                                                                                                          ) => {
                                                                                                            _vm.localEnter(
                                                                                                              e,
                                                                                                              annoItem,
                                                                                                              i
                                                                                                            )
                                                                                                          }).apply(
                                                                                                            null,
                                                                                                            arguments
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                    model:
                                                                                                      {
                                                                                                        value:
                                                                                                          i.content,
                                                                                                        callback:
                                                                                                          function (
                                                                                                            $$v
                                                                                                          ) {
                                                                                                            _vm.$set(
                                                                                                              i,
                                                                                                              "content",
                                                                                                              $$v
                                                                                                            )
                                                                                                          },
                                                                                                        expression:
                                                                                                          "i.content",
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "15px",
                                              "padding-bottom": "15px",
                                            },
                                          },
                                          [_c("a-empty")],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.selectedTitle == "原稿"
                              ? _c(
                                  "div",
                                  { staticStyle: { position: "relative" } },
                                  [
                                    _vm.leftAnnotationList.length
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "open-all-annotation-list",
                                          },
                                          [
                                            _vm.leftAnnotationList.some(
                                              (page) =>
                                                page.annotationList.some(
                                                  (anItem) => !anItem.selected
                                                )
                                            )
                                              ? _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openAnnotationList.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("全展开/收起")]
                                                )
                                              : _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.closeAnnotationList.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("全展开/收起")]
                                                ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.leftAnnotationList.length
                                      ? _c(
                                          "a-collapse",
                                          {
                                            attrs: { bordered: false },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "expandIcon",
                                                  fn: function (props) {
                                                    return [
                                                      _c("a-icon", {
                                                        attrs: {
                                                          type: "caret-right",
                                                          rotate: props.isActive
                                                            ? 90
                                                            : 0,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.leftAnnotationOpenKey,
                                              callback: function ($$v) {
                                                _vm.leftAnnotationOpenKey = $$v
                                              },
                                              expression:
                                                "leftAnnotationOpenKey",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              _vm.leftAnnotationList,
                                              function (page, pageIdx) {
                                                return [
                                                  page.annotationList &&
                                                  page.annotationList.filter(
                                                    (annoItem) =>
                                                      _vm.checkedToStages.some(
                                                        (toStage) =>
                                                          toStage ==
                                                          annoItem.toStage
                                                      )
                                                  ).length
                                                    ? _c(
                                                        "a-collapse-panel",
                                                        {
                                                          key:
                                                            pageIdx +
                                                            "oldLocal",
                                                          style:
                                                            _vm.customStyle,
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                slot: "header",
                                                              },
                                                              slot: "header",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    `第${
                                                                      _vm.showOptions
                                                                        .find(
                                                                          (
                                                                            showOption
                                                                          ) =>
                                                                            showOption.list.find(
                                                                              (
                                                                                option
                                                                              ) =>
                                                                                option.title ==
                                                                                "实时"
                                                                            )
                                                                        )
                                                                        .list.find(
                                                                          (
                                                                            option
                                                                          ) =>
                                                                            option.title ==
                                                                            "原稿"
                                                                        )
                                                                        .manuscriptList.findIndex(
                                                                          (p) =>
                                                                            p.id ==
                                                                            page.pageId
                                                                        ) + 1
                                                                    }页`
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      `共${page.annotationList.length}处`
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            page.annotationList,
                                                            function (
                                                              annoItem
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    annoItem.id +
                                                                    "oldLocal",
                                                                  class:
                                                                    _vm.selectId ===
                                                                    annoItem.id
                                                                      ? "local-content-list-show-old local-content-list-show-selected"
                                                                      : "local-content-list-show-old",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.toLocalOld(
                                                                          annoItem,
                                                                          "原稿"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "local-content-list-num-two",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              annoItem.sequenceNum
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  annoItem.commentData &&
                                                                  annoItem
                                                                    .commentData
                                                                    .length
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "local-content-list-old",
                                                                        },
                                                                        _vm._l(
                                                                          annoItem.commentData,
                                                                          function (
                                                                            i,
                                                                            idx
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  "anno" +
                                                                                  idx,
                                                                                staticClass:
                                                                                  "local-content-item",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-top",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-left",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "img",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                src: i.avatar,
                                                                                                alt: "",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "local-content-item-top-right",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "local-content-item-top-right-user",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    i.userName
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              {
                                                                                                class:
                                                                                                  _vm.selectId ==
                                                                                                  annoItem.id
                                                                                                    ? "action-span-select"
                                                                                                    : "action-span",
                                                                                              },
                                                                                              [
                                                                                                _vm.changeAll &&
                                                                                                (i.userId ==
                                                                                                  _vm
                                                                                                    .userInfo
                                                                                                    .id ||
                                                                                                  _vm.isAdmin ||
                                                                                                  _vm.checkPermission(
                                                                                                    "fatchStraw:localList:dele"
                                                                                                  ) ||
                                                                                                  (_vm.taskDetail.departName.indexOf(
                                                                                                    "原创部"
                                                                                                  ) >
                                                                                                    -1 &&
                                                                                                    _vm
                                                                                                      .taskDetail
                                                                                                      .canRejectStages))
                                                                                                  ? _c(
                                                                                                      "a-dropdown",
                                                                                                      {
                                                                                                        attrs:
                                                                                                          {
                                                                                                            trigger:
                                                                                                              [
                                                                                                                "click",
                                                                                                              ],
                                                                                                            transitionName:
                                                                                                              "",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        i.userId ==
                                                                                                        _vm
                                                                                                          .userInfo
                                                                                                          .id
                                                                                                          ? _c(
                                                                                                              "a-icon",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    type: "more",
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      $event.stopPropagation()
                                                                                                                    },
                                                                                                                },
                                                                                                              }
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _c(
                                                                                                          "a-menu",
                                                                                                          {
                                                                                                            attrs:
                                                                                                              {
                                                                                                                slot: "overlay",
                                                                                                              },
                                                                                                            slot: "overlay",
                                                                                                          },
                                                                                                          [
                                                                                                            i.userId ==
                                                                                                              _vm
                                                                                                                .userInfo
                                                                                                                .id ||
                                                                                                            _vm.isAdmin ||
                                                                                                            (_vm.taskDetail.departName.indexOf(
                                                                                                              "原创部"
                                                                                                            ) >
                                                                                                              -1 &&
                                                                                                              _vm
                                                                                                                .taskDetail
                                                                                                                .canRejectStages)
                                                                                                              ? _c(
                                                                                                                  "a-menu-item",
                                                                                                                  {
                                                                                                                    key: "0",
                                                                                                                    on: {
                                                                                                                      click:
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.editLocalContent(
                                                                                                                            i
                                                                                                                          )
                                                                                                                        },
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                      编辑\n                                    "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                            i.userId ==
                                                                                                              _vm
                                                                                                                .userInfo
                                                                                                                .id ||
                                                                                                            (_vm.isLocalZhongShen &&
                                                                                                              item.annotationKind ==
                                                                                                                1) ||
                                                                                                            _vm.checkPermission(
                                                                                                              "fatchStraw:localList:dele"
                                                                                                            ) ||
                                                                                                            (_vm.taskDetail.departName.indexOf(
                                                                                                              "原创部"
                                                                                                            ) >
                                                                                                              -1 &&
                                                                                                              _vm
                                                                                                                .taskDetail
                                                                                                                .canRejectStages)
                                                                                                              ? _c(
                                                                                                                  "a-menu-item",
                                                                                                                  {
                                                                                                                    key: "1",
                                                                                                                    on: {
                                                                                                                      click:
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.deletLocal(
                                                                                                                            annoItem
                                                                                                                          )
                                                                                                                        },
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                      删除\n                                    "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  : _vm._e(),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                              " +
                                                                                                _vm._s(
                                                                                                  i.createTimeStr
                                                                                                ) +
                                                                                                "\n                            "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "local-content-item-bottom",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          $event.stopPropagation()
                                                                                          return _vm.toLocalOld(
                                                                                            annoItem,
                                                                                            "原稿"
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    !i.editble
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            domProps:
                                                                                              {
                                                                                                innerHTML:
                                                                                                  _vm._s(
                                                                                                    i.content
                                                                                                  ),
                                                                                              },
                                                                                            on: {
                                                                                              copy: _vm.textCopy,
                                                                                            },
                                                                                          }
                                                                                        )
                                                                                      : _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "padding-right":
                                                                                                  "5px",
                                                                                                "padding-top":
                                                                                                  "3px",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm.changeAll
                                                                                              ? _c(
                                                                                                  "a-textarea",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        placeholder:
                                                                                                          "ALT+回车换行，回车提交",
                                                                                                        "auto-size":
                                                                                                          {
                                                                                                            minRows: 1,
                                                                                                            maxRows: 10,
                                                                                                          },
                                                                                                      },
                                                                                                    on: {
                                                                                                      click:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          $event.stopPropagation()
                                                                                                        },
                                                                                                      keydown:
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          if (
                                                                                                            !$event.type.indexOf(
                                                                                                              "key"
                                                                                                            ) &&
                                                                                                            _vm._k(
                                                                                                              $event.keyCode,
                                                                                                              "enter",
                                                                                                              13,
                                                                                                              $event.key,
                                                                                                              "Enter"
                                                                                                            )
                                                                                                          )
                                                                                                            return null
                                                                                                          return ((
                                                                                                            e
                                                                                                          ) => {
                                                                                                            _vm.localEnter(
                                                                                                              e,
                                                                                                              annoItem,
                                                                                                              i
                                                                                                            )
                                                                                                          }).apply(
                                                                                                            null,
                                                                                                            arguments
                                                                                                          )
                                                                                                        },
                                                                                                    },
                                                                                                    model:
                                                                                                      {
                                                                                                        value:
                                                                                                          i.content,
                                                                                                        callback:
                                                                                                          function (
                                                                                                            $$v
                                                                                                          ) {
                                                                                                            _vm.$set(
                                                                                                              i,
                                                                                                              "content",
                                                                                                              $$v
                                                                                                            )
                                                                                                          },
                                                                                                        expression:
                                                                                                          "i.content",
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "padding-top": "15px",
                                              "padding-bottom": "15px",
                                            },
                                          },
                                          [_c("a-empty")],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      item.id == 1
                        ? _c(
                            "div",
                            { staticClass: "diff-show" },
                            [
                              _vm.diffs.length
                                ? _c(
                                    "div",
                                    {
                                      style: item.allHeight
                                        ? {
                                            maxHeight:
                                              _vm.bottomHeight -
                                              130 +
                                              "px !important",
                                            overflowY: "auto",
                                            position: "relative",
                                          }
                                        : "max-height: 400px;overflow-y: auto;position: relative;",
                                    },
                                    [
                                      _c("div", { staticClass: "open-all" }, [
                                        _vm.activeKey.length <
                                        _vm.diffs.filter(
                                          (diff) => diff.rectangles
                                        ).length
                                          ? _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openAllDiff.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("全展开/收起")]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.closeAllDiff.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("全展开/收起")]
                                            ),
                                      ]),
                                      _vm.diffs.length > 0
                                        ? _c(
                                            "a-collapse",
                                            {
                                              attrs: { bordered: false },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "expandIcon",
                                                    fn: function (props) {
                                                      return [
                                                        _c("a-icon", {
                                                          attrs: {
                                                            type: "caret-right",
                                                            rotate:
                                                              props.isActive
                                                                ? 90
                                                                : 0,
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.activeKey,
                                                callback: function ($$v) {
                                                  _vm.activeKey = $$v
                                                },
                                                expression: "activeKey",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                _vm.diffs,
                                                function (item, index) {
                                                  return [
                                                    item.rectangles != null &&
                                                    item.rectangles.length > 0
                                                      ? _c(
                                                          "a-collapse-panel",
                                                          {
                                                            key:
                                                              "diff-" + index,
                                                            style:
                                                              _vm.customStyle,
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "word-wrap":
                                                                    "break-word",
                                                                  "white-space":
                                                                    "pre-line",
                                                                  "max-width":
                                                                    "200px",
                                                                },
                                                                attrs: {
                                                                  slot: "header",
                                                                },
                                                                slot: "header",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.fileName +
                                                                      ` 共${
                                                                        item.rectangles
                                                                          ? item
                                                                              .rectangles
                                                                              .length
                                                                          : 0
                                                                      }处` ||
                                                                      `共${
                                                                        item.rectangles
                                                                          ? item
                                                                              .rectangles
                                                                              .length
                                                                          : 0
                                                                      }处`
                                                                  ) +
                                                                    "\n                  "
                                                                ),
                                                              ]
                                                            ),
                                                            item.rectangles
                                                              ? _vm._l(
                                                                  item.rectangles,
                                                                  function (
                                                                    file,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "p",
                                                                      {
                                                                        key:
                                                                          "diff-item-" +
                                                                          i,
                                                                        staticStyle:
                                                                          {
                                                                            "padding-left":
                                                                              "24px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-icon",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "margin-right":
                                                                                  "6px",
                                                                                "padding-top":
                                                                                  "4px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                type: !file.isShow
                                                                                  ? "eye"
                                                                                  : "eye-invisible",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.showDiff(
                                                                                    file
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            class:
                                                                              _vm.selectedDiffIdx ==
                                                                              index +
                                                                                "-" +
                                                                                i
                                                                                ? "diff-item diff-item-select"
                                                                                : "diff-item",
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event.target !==
                                                                                    $event.currentTarget
                                                                                  )
                                                                                    return null
                                                                                  return _vm.goToDiff(
                                                                                    item,
                                                                                    file,
                                                                                    index,
                                                                                    i
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "第" +
                                                                                _vm._s(
                                                                                  i +
                                                                                    1
                                                                                ) +
                                                                                "处"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c("a-empty"),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.id == 3
                        ? _c(
                            "div",
                            {
                              staticClass: "text-list-show",
                              style: item.allHeight
                                ? {
                                    maxHeight:
                                      _vm.bottomHeight - 120 + "px !important",
                                    overflowY: "auto",
                                    position: "relative",
                                  }
                                : "overflow-y: auto;position: relative;",
                            },
                            [
                              _c("div", { staticClass: "text-list-top" }, [
                                _c(
                                  "div",
                                  [
                                    _c("a-input-search", {
                                      staticStyle: { width: "200px" },
                                      attrs: { placeholder: "请输入内容" },
                                      on: { search: _vm.textSearch },
                                      model: {
                                        value: _vm.textInputValue,
                                        callback: function ($$v) {
                                          _vm.textInputValue = $$v
                                        },
                                        expression: "textInputValue",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _vm._v(
                                    "共" +
                                      _vm._s(_vm.getTextAreaNum("showText")) +
                                      "个文本，" +
                                      _vm._s(_vm.getTextNum("showText")) +
                                      "个字"
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "text-list-bottom",
                                  style: item.allHeight
                                    ? {
                                        maxHeight:
                                          _vm.bottomHeight -
                                          180 +
                                          "px !important",
                                      }
                                    : "max-height: 400px;",
                                },
                                [
                                  _vm.textList.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "open-all-annotation-list",
                                        },
                                        [
                                          _vm.textDefaultKey.length !=
                                          _vm.textList.length
                                            ? _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.openTextList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("全展开/收起")]
                                              )
                                            : _c(
                                                "a",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.closeTextList.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("全展开/收起")]
                                              ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.textList.length
                                    ? _c(
                                        "a-collapse",
                                        {
                                          attrs: { bordered: false },
                                          on: {
                                            change: _vm.textDefaultKeyChange,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "expandIcon",
                                                fn: function (props) {
                                                  return [
                                                    _c("a-icon", {
                                                      attrs: {
                                                        type: "caret-right",
                                                        rotate: props.isActive
                                                          ? 90
                                                          : 0,
                                                      },
                                                    }),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.textDefaultKey,
                                            callback: function ($$v) {
                                              _vm.textDefaultKey = $$v
                                            },
                                            expression: "textDefaultKey",
                                          },
                                        },
                                        _vm._l(
                                          _vm.textList,
                                          function (page, pageIdx) {
                                            return _c(
                                              "a-collapse-panel",
                                              {
                                                key: page.pageId + "",
                                                style: _vm.customStyle,
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "header" },
                                                    slot: "header",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          `第${page.pageSequenceNum}页`
                                                        )
                                                      ),
                                                    ]),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "20px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            `共${page.textAreaCount}个文本，${page.translationWordCount}个字`
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._l(
                                                  page.textList,
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: item.id,
                                                        class:
                                                          _vm.selectTextId ===
                                                          item.id
                                                            ? "local-content-list-show local-content-list-show-selected"
                                                            : "local-content-list-show",
                                                        attrs: {
                                                          id:
                                                            "textArea-list-item-" +
                                                            item.id,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.toText(
                                                              page,
                                                              item,
                                                              page.pageSequenceNum -
                                                                1
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "local-content-list-num",
                                                            staticStyle: {
                                                              "padding-top":
                                                                "1px",
                                                            },
                                                          },
                                                          [
                                                            _c("div", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.textAreaSequenceNum
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "local-content-list",
                                                          },
                                                          [
                                                            _c("div", [
                                                              item.translationText &&
                                                              !item.imgUrl
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "space-text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item
                                                                            .phoneticList
                                                                            .length
                                                                            ? item.translationText.slice(
                                                                                0,
                                                                                3
                                                                              ) +
                                                                                item.phoneticList
                                                                                  .map(
                                                                                    (
                                                                                      p
                                                                                    ) =>
                                                                                      p.text
                                                                                  )
                                                                                  .join(
                                                                                    ""
                                                                                  )
                                                                            : item.translationText
                                                                        )
                                                                      ),
                                                                      _c(
                                                                        "a-icon",
                                                                        {
                                                                          directives:
                                                                            [
                                                                              {
                                                                                name: "copy",
                                                                                rawName:
                                                                                  "v-copy",
                                                                                value:
                                                                                  item
                                                                                    .phoneticList
                                                                                    .length
                                                                                    ? item.phoneticList
                                                                                        .map(
                                                                                          (
                                                                                            p
                                                                                          ) =>
                                                                                            p.text
                                                                                        )
                                                                                        .join(
                                                                                          ""
                                                                                        )
                                                                                    : item.translationText.slice(
                                                                                        3,
                                                                                        item
                                                                                          .translationText
                                                                                          .length
                                                                                      ),
                                                                                expression:
                                                                                  "\n                            item.phoneticList.length\n                              ? item.phoneticList.map(p => p.text).join('')\n                              : item.translationText.slice(3, item.translationText.length)\n                          ",
                                                                              },
                                                                            ],
                                                                          attrs:
                                                                            {
                                                                              type: "copy",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                              },
                                                                            mouseup:
                                                                              _vm.copyMessage,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : item.imgUrl
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "rgba(0,0,0,0.25)",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            "图片附件"
                                                                          ) +
                                                                          "\n                        "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }